import queryString from 'query-string'

import { api } from 'store/api'

const getRoot = (sectorType, sectorId) => `/inventory-oos/${sectorType}/${sectorId}`
export const getTrending = ({ sectorType, sectorId, activeProductType, vapeCategory }) =>
  api.get(
    `/inventory-oos/${sectorType}/${sectorId}/trending?${queryString.stringify({
      productType: activeProductType,
      vapeCategory
    })}`
  )

export const getBrands = ({ sectorType, sectorId, productType, timeframe, vapeCategory, sortBy, direction }) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/brands?${queryString.stringify({
      productType,
      timeframe,
      vapeCategory,
      sortBy,
      direction
    })}`
  )

export const getMaterials = ({ sectorType, sectorId, productType, timeframe, vapeCategory, sortBy, direction }) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/materials?${queryString.stringify({
      productType,
      timeframe,
      vapeCategory,
      sortBy,
      direction
    })}`
  )

export const getGeography = ({
  sectorType,
  sectorId,
  geography,
  filters,
  productType,
  timeframe,
  vapeCategory,
  offset,
  sortBy,
  direction
}) =>
  api.get(
    `${getRoot(sectorType, sectorId)}/geography?${queryString.stringify({
      geography,
      ...filters,
      productType,
      timeframe,
      vapeCategory,
      offset,
      sortBy,
      direction
    })}`
  )

export const getGeographyFilterOptions = ({ sectorType, sectorId, productType, vapeCategory }) =>
  api.get(`${getRoot(sectorType, sectorId)}/filters?${queryString.stringify({ productType, vapeCategory })}`)

export const getOosInvCaptureKpi = ({ id, sectorType, productType, timeframe, vapeCategory }) =>
  api.get(
    `${getRoot(sectorType, id)}?${queryString.stringify(
      {
        sectorId: id,
        sectorType,
        type: productType,
        timeframe,
        vapeCategory
      },
      { skipNull: true }
    )}`
  )
