import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const closeOpportunitiesActionsVariants = {
  sellIn: 'bg-sellIn text-white',
  priceCheck: 'bg-priceCheck text-white',
  priceCompliance: 'bg-priceCompliance text-white',
  surveys: 'bg-survey text-white',
  extraFunds: 'bg-extraFunds text-white'
}
