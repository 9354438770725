import pick from 'lodash/pick'
import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities } from 'store/actions'
import { registerSync, uploadImagesGetKeys } from 'store/api'
import outbox from 'store/outbox'
import { goFund as goFundSchema, goFundProgram as GoFundProgramSchema } from 'store/schema'

import * as api from './endpoints'

export const addExceptionToGoFundProgram = createAction('Add Exception to Go Fund Program')

const queueOfflineProgram = async (goFundProgram) => {
  const goFundProgramOutbox = (await outbox.getItem('go-fund-programs')) || {}
  await outbox.setItem('go-fund-programs', { ...goFundProgramOutbox, [goFundProgram.id]: goFundProgram })
  return registerSync('submit-pending-go-fund-programs')
}

export const addGoFundProgramEntities = createAction('Add new/updated goFundProgram', (goFundProgram) => (dispatch) => {
  const { entities } = normalize(goFundProgram, GoFundProgramSchema)
  dispatch(mergeEntities(entities))
})

export const submitGoFundProgramUpdate = createAction(
  'Submit goFundProgram update',
  (submittedValues, redirect) => async (dispatch) => {
    let goFundProgramUpdate
    const values = pick(submittedValues, [
      'id',
      'customerId',
      'description',
      'adjustedPayment',
      'adjustmentSubmittedAt',
      'adjustmentComment'
    ])
    try {
      if (window.navigator.onLine) {
        const {
          data: { goFundProgram }
        } = await api.submitUpdatedProgram(values)
        goFundProgramUpdate = goFundProgram
      } else {
        await queueOfflineProgram(values)
        goFundProgramUpdate = values
      }

      dispatch(addGoFundProgramEntities(goFundProgramUpdate))
      redirect()
    } catch (err) {
      if (err.message !== 'Network Error') {
        throw err
      }
      await queueOfflineProgram(values)
      dispatch(addGoFundProgramEntities(goFundProgramUpdate || values))
      redirect()
    }
  }
)

export const submitGoFundProgramCartons = createAction('Submit goFundProgram cartons', (values) => async (dispatch) => {
  try {
    const { newImages } = values
    let uploadedImages
    if (window.navigator.onLine) {
      const imageKeys = await uploadImagesGetKeys(newImages)
      uploadedImages = imageKeys.length ? imageKeys.map(({ key }) => ({ key })) : null

      const toSubmit = { ...values, images: uploadedImages }

      const {
        data: { program }
      } = await api.submitGoFundProgramCartons(toSubmit)
      const { entities } = normalize(program, GoFundProgramSchema)
      dispatch(mergeEntities(entities))
    }
  } catch (err) {
    if (err.message !== 'Network Error') {
      throw err
    }
  }
})

export const submitGoFundProgramPayment = createAction('Submit goFundProgram payment', (values) => async (dispatch) => {
  try {
    const { newImages } = values
    let uploadedImages

    if (newImages.length) {
      const imageKeys = await uploadImagesGetKeys(newImages)
      uploadedImages = imageKeys.length ? imageKeys.map(({ key }) => key) : null
    }

    const {
      data: { goFundProgram, goFund }
    } = await api.submitProgramPayment({ ...values, newImages: uploadedImages })
    const { entities } = goFund ? normalize(goFund, goFundSchema) : normalize(goFundProgram, GoFundProgramSchema)
    dispatch(mergeEntities(entities))
  } catch (err) {
    console.error(err)
    throw err
  }
})

export const submitGoFundProgramPowerHourTarget = createAction(
  'Submit goFundProgram Power hour target',
  (values) => async (dispatch) => {
    try {
      const {
        data: { goFundProgram }
      } = await api.submitProgramTarget(values)
      const { entities } = normalize(goFundProgram, GoFundProgramSchema)
      dispatch(mergeEntities(entities))
    } catch (err) {
      console.error(err)
      throw err
    }
  }
)

export const submitGoFundProgramPowerHourTargetUpdate = createAction(
  'Submit goFundProgram Power hour target update',
  (values) => async (dispatch) => {
    try {
      const {
        data: { goFundProgram }
      } = await api.submitProgramTargetUpdate(values)
      const { entities } = normalize(goFundProgram, GoFundProgramSchema)
      dispatch(mergeEntities(entities))
    } catch (err) {
      console.error(err)
      throw err
    }
  }
)
