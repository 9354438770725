import React, { useContext, useEffect, useMemo } from 'react'
import config from 'config'
import { func, string } from 'prop-types'

import GAnalyticsContext from 'context/GAnalyticsContext'
// import tw from 'twin.macro'
import LangContext from 'context/LangContext'
import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import Dropdown from 'components/Dropdown'
import ProductTypePicker from 'components/ProductTypePicker'
import SegmentControl from 'components/SegmentControl'
import ViewHeader from 'components/ViewHeader'

import { UNIT_OF_MEASURE_OPTIONS, VAPE_UNIT_OF_MEASURE_OPTIONS } from 'utils/constants'

export const unitOfMeasureOptsByProductType = {
  fmc: UNIT_OF_MEASURE_OPTIONS,
  vape: VAPE_UNIT_OF_MEASURE_OPTIONS
}

const SelloutHeader = ({
  header,
  currentProportion,
  setProportion,
  currentChannel,
  setChannel,
  currentMetric,
  setMetric,
  currentVapeCategory,
  setVapeCategory,
  setTimeDisplay,
  currentTimeDisplay,
  unitOfMeasure,
  setUnitOfMeasure
}) => {
  const { translate } = useContext(LangContext)
  const { currentProductType } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)
  const { startTracking, stopTracking } = useContext(GAnalyticsContext)

  useEffect(() => {
    const currentKey = createGAKey('proportion', currentProportion)
    startTracking(currentKey)
    return () => {
      stopTracking(currentKey)
    }
  }, [currentProportion])

  useEffect(() => {
    const currentKey = createGAKey('channel', currentChannel)
    startTracking(currentKey)
    return () => {
      stopTracking(currentKey)
    }
  }, [currentChannel])

  useEffect(() => {
    const currentKey = createGAKey('metric', currentMetric)
    startTracking(currentKey)
    return () => {
      stopTracking(currentKey)
    }
  }, [currentMetric])

  useEffect(() => {
    const currentKey = createGAKey('timedisplay', currentTimeDisplay)
    startTracking(currentKey)
    return () => {
      stopTracking(currentKey)
    }
  }, [currentTimeDisplay])

  useEffect(() => {
    const currentKey = createGAKey('vapecat', currentVapeCategory)
    if (currentProductType === 'vape') {
      startTracking(currentKey)
    }
    return () => {
      if (currentProductType === 'vape') {
        stopTracking(currentKey)
      }
    }
  }, [currentVapeCategory, currentProductType])

  const CHANNEL_OPTS_BY_TYPE = {
    fmc: [
      {
        value: 'pos',
        label: translate('app.acronyms.POS')
      }
    ],
    vape: [
      {
        value: 'pos',
        label: translate('app.acronyms.POS')
      },
      {
        value: 'specialty',
        label: translate('common.specialty')
      }
    ],
    nrt: [
      {
        value: 'pos',
        label: translate('app.acronyms.POS')
      }
    ]
  }

  const isVape = currentProductType === 'vape'

  const metricOpts = useMemo(() => {
    if (isVape && currentChannel === 'specialty') {
      return [
        {
          value: 'cost',
          label: translate('common.value')
        }
      ]
    }
    return [
      {
        value: 'cost',
        label: translate('common.value')
      },
      {
        value: 'volume',
        label: translate('common.volume')
      }
    ]
  }, [currentProductType, currentChannel])

  const shouldShowUnitsOfMeasure =
    currentChannel === 'pos' && currentProportion === 'absolute' && currentMetric === 'volume'

  const uomOption = unitOfMeasureOptsByProductType[currentProductType]

  return (
    <ViewHeader
      breadcrumbs={
        [
          // { text: parentHeader, href: '../' },
          // { text: header, href: '' }
        ]
      }
      header={header}
      filters={[
        !isWideLayout && <ProductTypePicker key="amplify-sell-out-product-type-picker" />,
        <SegmentControl
          key="proportion"
          name="proportion"
          onChange={(e) => setProportion(e.target.value)}
          value={currentProportion}
          options={[
            {
              value: 'absolute',
              label: translate('common.absolute')
            },
            {
              value: 'share',
              label: translate('app.share')
            }
          ]}
        />,
        <SegmentControl
          key="channel"
          name="channel"
          onChange={(e) => setChannel(e.target.value)}
          disabled={!isVape}
          value={currentChannel}
          options={CHANNEL_OPTS_BY_TYPE[currentProductType]}
        />,
        <SegmentControl
          key="metric"
          name="metric"
          onChange={(e) => setMetric(e.target.value)}
          disabled={isVape && currentChannel === 'specialty'}
          value={currentMetric}
          options={metricOpts}
        />,
        <SegmentControl
          key="timeDisplay"
          name="timeDisplay"
          onChange={(e) => setTimeDisplay(e.target.value)}
          value={currentTimeDisplay}
          options={[
            {
              value: 'rolling',
              label: translate('app.rolling')
            },
            {
              value: 'pointInTime',
              label: translate('app.pointInTime')
            }
          ]}
        />,
        !isVape ? null : (
          <Dropdown
            key="vapeCategory"
            name="vapeCategory"
            onChange={(e) => setVapeCategory(e.target.value)}
            disabled={!isVape}
            value={currentProductType !== 'vape' ? 'allConsumables' : currentVapeCategory}
            options={[
              {
                value: 'allConsumables',
                label: translate('app.allConsumables')
              },
              {
                value: 'openConsumables',
                label: translate('app.openConsumables')
              },
              {
                value: 'closedConsumables',
                label: translate('app.closedConsumables')
              },
              {
                value: 'disposables',
                label: translate('app.disposables')
              },
              {
                value: 'devices',
                label: translate('app.devices')
              }
            ]}
          />
        ),
        shouldShowUnitsOfMeasure && config.featureFlags.uom && (
          <Dropdown
            key="amplify-sell-in-unit-of-measure"
            name="unit-of-measure"
            onChange={(e) => setUnitOfMeasure(e.target.value)}
            value={unitOfMeasure}
            options={uomOption}
            shouldUpperCase
          />
        )
      ].filter(Boolean)}
    />
  )
}

SelloutHeader.propTypes = {
  header: string.isRequired,
  setChannel: func.isRequired,
  setMetric: func.isRequired,
  setProportion: func.isRequired,
  setVapeCategory: func.isRequired,
  setTimeDisplay: func.isRequired,
  currentTimeDisplay: string.isRequired,
  currentChannel: string.isRequired,
  currentMetric: string.isRequired,
  currentProportion: string.isRequired,
  currentVapeCategory: string.isRequired,
  unitOfMeasure: string.isRequired,
  setUnitOfMeasure: func.isRequired
}

export default SelloutHeader

function createGAKey(filter, currentValue) {
  return `amp_sellout_${filter}_${currentValue}`
}
