import { useEffect, useMemo, useState } from 'react'

const useIsNewTab = () => {
  const [isClient, setIsClient] = useState(false)

  const isNewTab = useMemo(() => {
    if (!isClient) return false
    if (typeof window === 'undefined') return false

    return window?.opener != null // || window?.history.length == 1
  }, [isClient])

  const hasParent = useMemo(() => {
    if (!isClient) return false
    if (typeof window === 'undefined') return false
    return !!window.parent
  }, [isClient])

  useEffect(() => {
    setIsClient(true)
  }, [])

  return {
    isNewTab,
    hasParent
  }
}

export default useIsNewTab
