import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import config from 'config'
import isEmpty from 'lodash/isEmpty'
import { bool, func, object, string } from 'prop-types'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySelloutGeographyData } from 'store/Sellout/actions'
import { amplifySelloutKAData } from 'store/Sellout/selectors'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import DataVariation from 'components/DataTable/DataVariation'
import EmptyState from 'components/EmptyState'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import { DATAKEY_TYPES } from 'utils/constants'
import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'
import { createDataKey, getErrorMessage, sortDataTableBy } from 'utils/helpers'

const CardToolTip = tw(Tooltip)`h-full flex`

const TIME_COLS = {
  rolling: [
    {
      field: 'lMid',
      headerName: 'L4'
    },
    {
      field: 'vpMid',
      headerName: 'vs. P4'
    }
  ],
  pointInTime: [
    {
      field: 'lShort',
      headerName: 'CTD'
    },
    {
      field: 'vpShort',
      headerName: 'vs. PC'
    }
  ]
}

function getSortCol(timeDisplay) {
  const colsForTimeDisplay = TIME_COLS[timeDisplay]
  if (!colsForTimeDisplay) return 'lMid'
  return colsForTimeDisplay[0].field
}

function generateRows(data, dataFormatter, tooltipFormatter, cols) {
  if (!data) return []

  const mainPeriod = cols[2]?.field
  const variation = cols[3]?.field

  return data.map((d) => {
    return {
      headOffice: d.name,
      awr4: (
        <CardToolTip isNumber hint={tooltipFormatter(d.awr4)}>
          {dataFormatter(d.awr4)}
        </CardToolTip>
      ),
      [mainPeriod]: (
        <CardToolTip isNumber hint={tooltipFormatter(d[mainPeriod])}>
          {dataFormatter(d[mainPeriod])}
        </CardToolTip>
      ),
      [variation]: (
        <CardToolTip isNumber hint={tooltipFormatter(d[variation])}>
          <DataVariation variation={d[variation]} formatter={dataFormatter} />
        </CardToolTip>
      )
    }
  })
}
const AmplifySelloutKACard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  fetchAmplifySelloutGeographyData,
  dataType,
  unitOfMeasure,
  selloutDataLoading
}) => {
  const isMounted = useRef()

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const [sortBy, setSortBy] = useState({ column: getSortCol(currentTimeDisplay), order: 'desc' })
  const setSorted = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
  }

  const [error, setError] = useState()
  if (error) console.error(error)

  const FIRST_COL = {
    field: 'headOffice',
    headerName: translate('app.headOffice')
  }

  const awrCol = [
    {
      field: 'awr4',
      headerName: 'AWR4'
    }
  ]

  const disableAWR = !config.featureFlags.awr || (currentProportion === 'share' && currentTimeDisplay === 'rolling')

  const cols = [FIRST_COL].concat(!disableAWR ? awrCol : []).concat(TIME_COLS[currentTimeDisplay])

  const filters = useMemo(() => {
    if (currentProductType === 'fmc') return { manufacturer: 'ITCAN' }
    if (currentProductType === 'vape')
      return {
        manufacturer: 'Vuse',
        vapeCategory: currentVapeCategory
      }
    if (currentProductType === 'nrt')
      return {
        manufacturer: 'Zonnic'
      }
  }, [currentProductType, currentVapeCategory])

  const dataKey = useMemo(() => {
    return createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_OUT.GEOGRAPHY, {
      sectorType: selectedLevel,
      sectorId: currentSector[selectedLevel]?.id,
      productType: currentProductType,
      channel: currentChannel,
      geography: 'headoffice',
      dataType,
      proportion: currentProportion,
      timeDisplay: currentTimeDisplay,
      unitOfMeasure,
      filters,
      columnSorted: sortBy.column,
      sortDirection: sortBy.order,
      offset: 0,
      limit: 100
    })
  }, [
    currentSector,
    selectedLevel,
    currentProductType,
    currentChannel,
    dataType,
    currentVapeCategory,
    currentProportion,
    currentTimeDisplay,
    unitOfMeasure,
    sortBy.column,
    sortBy.order
  ])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id && selectedLevel !== 'customer') {
      fetchAmplifySelloutGeographyData(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          productType: currentProductType,
          channel: currentChannel,
          geography: 'headoffice',
          proportion: currentProportion,
          timeDisplay: currentTimeDisplay,
          filters,
          dataType,
          unitOfMeasure,
          sortColumn: sortBy.column,
          sortDirection: sortBy.order,
          offset: 0,
          limit: 100
        },
        dataKey
      ).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }
  }, [dataKey])
  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const selloutData = useSelector((state) =>
    amplifySelloutKAData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentChannel,
      currentVapeCategory,
      geography: 'headoffice',
      currentManufacturer: filters.manufacturer,
      dataType,
      unitOfMeasure,
      sortColumn: sortBy.column,
      sortDirection: sortBy.order,
      offset: 0,
      limit: 100
    })
  )

  const getContent = () => {
    if (selectedLevel === 'customer') return <EmptyState title={translate('app.warn.noRelevantData')} />

    if (selloutDataLoading || isLoading) {
      return <WrappedSpinner icon="spinner" />
    }

    if (isEmpty(selloutData)) {
      return <EmptyState title={translate('app.warn.noData')} />
    }

    const dataFormatter =
      currentProportion === 'share'
        ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: '-' })
        : currentMetric === 'cost'
        ? (v) => formatCompactCurrency(v, { nullDisplay: '-' })
        : (v) => formatCompactNumber(v, { nullDisplay: '-' })
    const tooltipFormatter =
      currentProportion === 'share'
        ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: null })
        : currentMetric === 'cost'
        ? (v) => formatCurrency(v, { nullDisplay: null })
        : (v) => formatNumber(v, { nullDisplay: null })

    return (
      <div className="max-h-[490px] overflow-y-auto">
        <DataTable
          onColumnClick={setSorted}
          activeColumn={sortBy}
          unClickableColumns={['headOffice']}
          columns={cols}
          rows={generateRows(selloutData, dataFormatter, tooltipFormatter, cols)}
          fillContainer
        />
      </div>
    )
  }

  return (
    <Card title={translate('app.acronyms.KA')} span={span} displayAmplify={false}>
      {getContent()}
    </Card>
  )
}

AmplifySelloutKACard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  unitOfMeasure: string,
  fetchAmplifySelloutGeographyData: func,
  selloutDataLoading: bool
}

export default connect(null, { fetchAmplifySelloutGeographyData })(AmplifySelloutKACard)
