import React, { useContext } from 'react'
import config from 'config'
import { number, shape } from 'prop-types'

import LangContext from 'context/LangContext'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import Actions from 'components/evaluate/Actions'
import PerformanceReview from 'components/evaluate/PerformanceReview'
import Reminders from 'components/evaluate/Reminders'

const ObjectivesCard = ({ span }) => {
  const { translate } = useContext(LangContext)

  return (
    <Card title={translate('evaluate.objectives')} span={span} displayAmplify={false}>
      {!config.featureFlags.closeOpportunities || !config.featureFlags.closeOpportunitiesObjectives ? (
        <EmptyState title={translate('common.comingSoon')} />
      ) : (
        <>
          {/* Performance */}
          <PerformanceReview />

          {/* Actions */}
          <Actions />

          <hr className="border-slate-200" />

          {/* Reminders */}
          <Reminders />
        </>
      )}
    </Card>
  )
}

ObjectivesCard.propTypes = {
  span: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number
  })
}

export default ObjectivesCard
