import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import config from 'config'
import isEmpty from 'lodash/isEmpty'
// import maxBy from 'lodash/maxBy'
// import minBy from 'lodash/minBy'
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip as RechartTooltip, XAxis, YAxis } from 'recharts'
import theme from 'tailwindcss/colors'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySelloutGeographyData } from 'store/Sellout/actions'
import { selloutL13TrendGraphData } from 'store/Sellout/selectors'

import Card from 'components/card'
import Dropdown from 'components/Dropdown'
import EmptyState from 'components/EmptyState'
import LoadingCard from 'components/LoadingCard'
import { WrappedSpinner } from 'components/Spinner'
import Tooltip from 'components/Tooltip'

import { CHANNEL_FILTERS, DATAKEY_TYPES, PACE_SELLOUT_MANUFACTURER_FILTERS, PLACEHOLDERS } from 'utils/constants'
import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

function generateYAxis({ entries, dataFormat }) {
  if (entries.length && entries.some(({ data }) => data)) {
    return ['auto', 'auto']
  }
  if (dataFormat === 'percent') return [0, 100]
  return [0, 10000]
}

const FMC_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos
const VAPE_POS_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos
const VAPE_SPECIALTY_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty
const NRT_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.nrt.pos

const formatterByDataType = {
  percent: (v) => formatPercent(v),
  cost: formatCurrency,
  qty: formatNumber
  // pack_qty: formatNumber
}

const CustomTooltip = ({ active, payload = [], label, dataFormat }) => {
  if (!active || !payload || !payload.length) return null

  const formatter = formatterByDataType[dataFormat] || formatNumber
  const content = `${formatter(payload[0]?.value)}\nWE: ${label}`

  if (active && payload && payload[0])
    return (
      <div>
        <div
          className="w-auto -translate-x-1/2 space-y-1 rounded bg-black/90 px-3 py-2 text-white"
          style={{ whiteSpace: 'pre' }}
        >
          {content}
        </div>
      </div>
    )
}

CustomTooltip.propTypes = {
  active: bool,
  payload: arrayOf(shape({ value: number })),
  label: string,
  dataFormat: string
}

const Controls = tw.div`w-full flex space-x-3`

const manufacturerByProductType = {
  fmc: FMC_OPTIONS,
  vape: VAPE_POS_OPTIONS,
  nrt: NRT_OPTIONS
}

const yAxisFormatterByDataType = {
  percent: (v) => formatPercent(v, { convertDecimal: false, decimalPlaces: 1 }),
  cost: (v) => formatCompactCurrency(v, { forceDecimal: true, decimalPlaces: 2 }),
  qty: (v) => formatCompactNumber(v, { forceDecimal: true, decimalPlaces: 1 })
}

export const SelloutL13TrendGraphCard = ({
  span,
  amplifyAction,
  title,
  currentTimeDisplay,
  currentChannel,
  currentMetric,
  setChannel,
  currentProportion,
  currentVapeCategory,
  dataType,
  unitOfMeasure,
  selloutDataLoading,
  fetchAmplifySelloutGeographyData
}) => {
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)
  const [currentManufacturer, setCurrentManufacturer] = useState()
  const { translate } = useContext(LangContext)

  const [error, setError] = useState()
  if (error) console.error(error)

  const setDefaultManufacturerOptions = () => {
    const defaultManufacturerOptions = manufacturerByProductType[currentProductType]
    setCurrentManufacturer(defaultManufacturerOptions?.[0].value)
  }

  const getManufacturerOptions = (options) => {
    if (currentProportion !== 'share') return [{ label: 'All', value: 'all' }, ...options]
    else setDefaultManufacturerOptions()
    return options
  }

  useLayoutEffect(() => {
    setChannel('pos')
    setDefaultManufacturerOptions()
  }, [currentProductType])

  const filters = useMemo(() => {
    if (currentProductType === 'vape')
      return {
        manufacturer: currentManufacturer,
        vapeCategory: currentVapeCategory
      }
    return { manufacturer: currentManufacturer }
  }, [currentManufacturer, currentProductType, currentVapeCategory])

  const MANUFACTURERS_DROPDOWN_OPTIONS = useMemo(() => {
    if (currentProductType === 'fmc') return getManufacturerOptions(FMC_OPTIONS)
    if (currentProductType === 'nrt') return getManufacturerOptions(NRT_OPTIONS)
    if (currentChannel === 'specialty') return getManufacturerOptions(VAPE_SPECIALTY_OPTIONS)

    return getManufacturerOptions(VAPE_POS_OPTIONS)
  }, [currentChannel, currentProductType, currentProportion])

  const isMounted = useRef()

  const dataKey = useMemo(() => {
    return createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_OUT.GEOGRAPHY, {
      sectorType: selectedLevel,
      sectorId: currentSector[selectedLevel]?.id,
      productType: currentProductType,
      channel: currentChannel,
      geography: selectedLevel,
      dataType,
      proportion: currentProportion,
      timeDisplay: currentTimeDisplay,
      vapeCategory: currentVapeCategory,
      manufacturer: currentManufacturer,
      filters,
      unitOfMeasure,
      offset: 0,
      limit: 1
    })
  }, [
    currentSector,
    selectedLevel,
    currentProductType,
    currentChannel,
    dataType,
    currentVapeCategory,
    currentManufacturer,
    currentProportion,
    currentTimeDisplay,
    unitOfMeasure
  ])

  useEffect(() => {
    setError()
    if (currentSector[selectedLevel]?.id) {
      fetchAmplifySelloutGeographyData(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          productType: currentProductType,
          channel: currentChannel,
          geography: selectedLevel,
          proportion: currentProportion,
          timeDisplay: currentTimeDisplay,
          vapeCategory: currentVapeCategory,
          filters,
          manufacturer: currentManufacturer,
          dataType,
          unitOfMeasure,
          offset: 0,
          limit: 1
        },
        dataKey
      ).catch((error) => {
        if (isMounted.current) setError(getErrorMessage(error))
      })
    }
  }, [dataKey])

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const selloutData = useSelector((state) =>
    selloutL13TrendGraphData(state, {
      currentSector,
      selectedLevel,
      activeProductType: currentProductType,
      currentChannelFilter: currentChannel,
      currentProportion,
      currentTimeDisplay,
      currentMetric,
      currentChannel,
      vapeCategory: currentVapeCategory,
      manufacturer: currentManufacturer,
      geography: selectedLevel,
      currentManufacturer,
      currentVapeCategory,
      dataType,
      unitOfMeasure,
      offset: 0,
      limit: 1
    })
  )

  const dataFormat = currentProportion === 'share' ? 'percent' : dataType
  const yAxisFormatter = yAxisFormatterByDataType[dataFormat]

  if (error) {
    return <EmptyState title="An error occured" subtitle={error} />
  }

  const cardProps = {
    title,
    span,
    amplifyAction
  }

  const awrTooltipFormatter = formatterByDataType[dataFormat] || formatNumber
  const renderContent = () => {
    if (selloutDataLoading || isLoading) {
      return <WrappedSpinner icon="spinner" />
    }
    if (isEmpty(selloutData.trend)) {
      return <EmptyState title="No data" />
    }
    const { awr } = selloutData

    return (
      <>
        <ResponsiveContainer height="99%" width="99%" className="pt-8">
          <LineChart data={selloutData.trend} margin={{ bottom: 32, left: 0, right: 10, top: 10 }}>
            <CartesianGrid stroke={theme.slate[300]} vertical={false} />

            <XAxis
              style={{
                fontSize: '12px'
              }}
              axisLine={false}
              dataKey="name"
              dy={12}
              stroke={theme.slate[500]}
              tickLine={false}
            />

            <YAxis
              domain={generateYAxis({ entries: selloutData.trend, dataFormat })}
              interval="preserveStartEnd"
              style={{
                fontSize: '10px'
              }}
              axisLine={false}
              dx={-6}
              stroke={theme.slate[500]}
              tickLine={false}
              tickFormatter={yAxisFormatter}
            />

            <RechartTooltip
              content={<CustomTooltip dataFormat={dataFormat} />}
              wrapperStyle={{
                outline: 'none'
              }}
              animationDuration={300}
              allowEscapeViewBox={{ x: true }}
              cursor={false}
              offset={0}
            />

            <Line
              dataKey="data"
              strokeWidth={3}
              stroke="#69AD55"
              dot={{ stroke: '#69AD55', r: 1, strokeWidth: 4 }}
              activeDot={{ stroke: theme.white, strokeWidth: 4, r: 6 }}
              isAnimationActive={false}
              connectNulls
            />
          </LineChart>
        </ResponsiveContainer>

        {config.featureFlags.awr && awr ? (
          <div className="flex pt-1 ">
            <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
              <div className="flex flex-col items-center gap-2">
                <span className="block text-center text-2xs font-medium text-slate-500">{translate('app.awr4')}</span>
                <Tooltip isNumber hint={awrTooltipFormatter(awr?.awr4)}>
                  <span className="block min-w-fit text-center text-xl font-medium text-slate-900">
                    {awr?.awr4 ? yAxisFormatter(awr.awr4) : PLACEHOLDERS.NO_VALUE}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="my-auto flex w-full flex-col items-center justify-center space-y-5">
              <div className="flex flex-col items-center gap-2">
                <span className="block text-center text-2xs font-medium text-slate-500">{translate('app.awr13')}</span>
                <Tooltip isNumber hint={awrTooltipFormatter(awr?.awr13)}>
                  <span className="block min-w-fit text-center text-xl font-medium text-slate-900">
                    {awr?.awr13 ? yAxisFormatter(awr.awr13) : PLACEHOLDERS.NO_VALUE}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        ) : null}
      </>
    )
  }

  return (
    <Card {...cardProps} amplifySize="sm">
      <div className="flex h-[490px] w-full flex-col">
        <Controls>
          {currentProductType === 'vape' && amplifyAction && (
            <Dropdown
              onChange={(e) => setChannel(e.target.value)}
              value={currentChannel}
              options={CHANNEL_FILTERS}
              disabled={selloutDataLoading}
            />
          )}
          <Dropdown
            onChange={(e) => setCurrentManufacturer(e.target.value)}
            value={currentManufacturer}
            options={MANUFACTURERS_DROPDOWN_OPTIONS}
            disabled={selloutDataLoading}
          />
        </Controls>
        {renderContent()}
      </div>
      <LoadingCard dataKey={dataKey} />
    </Card>
  )
}

SelloutL13TrendGraphCard.propTypes = {
  span: object,
  amplifyAction: func,
  title: string,
  currentChannel: string,
  currentTimeDisplay: string,
  currentMetric: string,
  setChannel: func,
  currentProportion: string,
  error: string,
  dataType: string,
  unitOfMeasure: string,
  dataKey: string,
  selloutDataLoading: bool,
  currentVapeCategory: string,
  fetchAmplifySelloutGeographyData: func
}

export default connect(null, { fetchAmplifySelloutGeographyData })(SelloutL13TrendGraphCard)
