import React, { useContext } from 'react'
import moment from 'moment'
import { bool, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Icon from 'components/Icon'

import { primaryColor } from 'styles/colors'

const ProgramStartEnd = ({ small, startDate, endDate }) => {
  const { lang } = useContext(LangContext)
  moment.locale(lang)

  return (
    <div className="flex items-center text-sm">
      <Icon icon="calendar" className="mr-2" small={small} color={primaryColor} />
      <span>{`${moment.utc(startDate).format('MMM D, YYYY')} - ${moment.utc(endDate).format('MMM D, YYYY')}`}</span>
    </div>
  )
}

ProgramStartEnd.propTypes = {
  small: bool,
  startDate: string.isRequired,
  endDate: string
}

export default ProgramStartEnd
