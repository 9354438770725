import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from 'components/Icon'

import { secondaryColor } from 'styles/colors'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3 } from 'styles/typography'

export const Container = styled.div`
  position: relative;
  display: block;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.medium};
  padding: 0 ${spacing.medium};

  ${media.breakpoint`
    padding: 0;
  `};
`

export const Title = styled(H3)`
  margin-bottom: 0;
  flex-grow: 1;
  padding-right: ${spacing.medium};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Content = styled.main`
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
`

export const Action = styled.button`
  color: ${secondaryColor};
`

const ProgramAccordion = ({ children, title }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev)
  }, [])

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Action onClick={toggleExpanded}>
          <div className="flex items-center gap-0.5">
            {expanded ? 'Hide' : 'Show'}
            <div className={expanded ? 'rotate-180' : ''}>
              <Icon icon="down-chevron" />
            </div>
          </div>
        </Action>
      </Header>
      <Content expanded={expanded}>{children}</Content>
    </Container>
  )
}

ProgramAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}

export default ProgramAccordion
