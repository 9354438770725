import React, { useContext } from 'react'

import LangContext from 'context/LangContext'

import FlickeringGrid from 'components/Extrahub/FlickerGrid'

const ScanningOverlay = () => {
  const { translate } = useContext(LangContext)

  return (
    <div className="absolute inset-0 flex bg-slate-900/70">
      <FlickeringGrid
        circleSize={2}
        gridGap={6}
        maxOpacity={0.9}
        flickerChance={0.8}
        color="rgb(56, 189, 248)"
        className="absolute inset-0"
      />
      <div className="absolute inset-0 flex items-center justify-center ">
        <div className="rounded-full bg-sky-950/40 px-5 py-3 text-base font-medium text-white ring-1 ring-inset ring-white/20 drop-shadow-lg backdrop-blur-lg">
          {translate('components.Extrahub.Scan.scanning')}
        </div>
      </div>
    </div>
  )
}

export default ScanningOverlay
