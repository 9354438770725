import { api } from 'store/api'

export const submitNewProgram = (program) => api.post('/go-fund-programs', program)

export const submitUpdatedProgram = (program) => api.put(`/go-fund-programs/${program.id}`, program)

export const submitGoFundProgramCartons = (values) => api.post(`/go-fund-programs/${values.programId}/cartons`, values)

export const submitProgramDeletion = (programId) => api.delete(`/go-fund-programs/${programId}`)

export const submitProgramPayment = (paymentSubmitted) =>
  api.post(`/go-fund-programs/${paymentSubmitted.payment.programId}/payment`, paymentSubmitted)

export const approveReceiptPayment = (goFundProgramId, receiptId, payment) =>
  api.patch(`/go-fund-programs/${goFundProgramId}/receipts/${receiptId}`, payment)

export const rejectReceiptPayment = (goFundProgramId, receiptId) =>
  api.patch(`/go-fund-programs/${goFundProgramId}/receipts/${receiptId}`)

export const submitProgramTarget = (targetSubmitted) =>
  api.post(`/go-fund-programs/${targetSubmitted.goFundProgramId}/target`, targetSubmitted)

export const submitProgramTargetUpdate = (targetUpdated) =>
  api.put(`/go-fund-programs/${targetUpdated.goFundProgramId}/target/${targetUpdated.target.id}`, targetUpdated)

export const uploadScanReceiptImageRecognition = (image, params) =>
  api.post(`/go-fund-programs/${params.goFundProgramId}/scan-receipt?params=${JSON.stringify(params)}`, image, {
    headers: {
      'Content-Type': 'image/jpeg'
    }
  })

export const getExtraFundBanner = () => api.get('/go-funds/banner')
