import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import * as api from 'store/priceCaptureCompliance/endpoints'

import Card from 'components/card'
import DataTable from 'components/DataTable/DataTable'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import WideDataTable, { createColumns } from 'components/DataTable/WideDataTable'
import EmptyState from 'components/EmptyState'
import Pagination from 'components/Pagination'
import { WrappedSpinner } from 'components/Spinner'

import { DEFAULT_TABLE_PAGE_SIZE, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatCompactNumber, formatCurrency, formatPercent } from 'utils/formatters'
import { getGenericGeographyFilters } from 'utils/genericGeographyFilters'
import { getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

export const PRICING_STRATEGY_CONDITION_TYPES = {
  NONE: 'None',
  BENCHMARK: 'Benchmark',
  VS_CEILING: 'Vs. Ceiling',
  LPP: 'LPP'
}

const defaultFilters = {
  region: [],
  district: [],
  territory: [],
  kaSubregion: [],
  headoffice: [],
  banner: [],
  province: [],
  tier: [],
  ownershipType: [],
  outletSubType: [],
  store: [],
  fsa: [],
  city: [],
  brand: [],
  brandVariant: [],
  length: [],
  size: [],
  productDescription: []
}

const GeographyCompliance = ({ span }) => {
  const { translate } = useContext(LangContext)

  const SUB_COLUMNS = [
    {
      title: 'Compl.',
      field: 'compliantCustomers'
    },
    {
      title: 'Non-compl.',
      field: 'notCompliantCustomers'
    },
    {
      title: 'Compl. %',
      field: 'percentCompliant'
    },
    {
      title: 'Price',
      field: 'averagePrice'
    }
  ]

  const { sectorType, sectorId } = useParams()
  const { currentProductType } = useContext(SectorContext)

  const [error, setError] = useState()
  const [geography, setGeography] = useState('territory')
  const [filters, setFilters] = useState(defaultFilters)
  const [page, setPage] = useState(1)

  const [productLevel, setProductLevel] = useState(['brand', 'brand_variant', 'sku'].includes(geography))

  const [sortBy, setSortBy] = useState({
    column: productLevel ? 'geo' : 'name',
    order: productLevel ? SORTING_DIRECTION.DESC : SORTING_DIRECTION.ASC
  })

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  useEffect(() => {
    setSortBy({
      column: productLevel ? 'geo' : 'name',
      order: productLevel ? SORTING_DIRECTION.DESC : SORTING_DIRECTION.ASC
    })
  }, [productLevel])

  // Geography filters
  const { data: geographyFiltersOptions, isLoading: areGeographyFiltersLoading } = useQuery({
    queryKey: ['priceComplianceGeographyFiltersOptions', sectorType, sectorId, currentProductType],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const { data } = await api.getPriceComplianceGeographyFilters(sectorType, sectorId, currentProductType)

        return data
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
  }

  const geographyFilters = useMemo(() => {
    const options = {
      ...geographyFiltersOptions
    }

    const changeEventHandlers = {
      regionHandler: (value) => handleFilterChange({ region: value }),
      districtHandler: (value) => handleFilterChange({ district: value }),
      territoryHandler: (value) => handleFilterChange({ territory: value }),
      kaSubregionHandler: (value) => handleFilterChange({ kaSubregion: value }),
      headofficeHandler: (value) => handleFilterChange({ headoffice: value }),
      bannerHandler: (value) => handleFilterChange({ banner: value }),
      provinceHandler: (value) => handleFilterChange({ province: value }),
      tierHandler: (value) => handleFilterChange({ tier: value }),
      ownershipTypeHandler: (value) => handleFilterChange({ ownershipType: value }),
      outletSubtypeHandler: (value) => handleFilterChange({ outletSubtype: value }),
      storeHandler: (value) => handleFilterChange({ store: value }),
      fsaHandler: (value) => handleFilterChange({ fsa: value }),
      cityHandler: (value) => handleFilterChange({ city: value }),
      brandHandler: (value) => handleFilterChange({ brand: value }),
      brandVariantHandler: (value) => handleFilterChange({ brandVariant: value }),
      lengthHandler: (value) => handleFilterChange({ length: value }),
      sizeHandler: (value) => handleFilterChange({ size: value }),
      productDescriptionHandler: (value) => handleFilterChange({ productDescription: value })
    }

    return getGenericGeographyFilters(filters, options, changeEventHandlers, translate, sectorType, currentProductType)
  }, [geography, areGeographyFiltersLoading, sectorType, currentProductType, translate])

  const appliedFilters = Object.values(filters).filter((value) => value.length !== 0).length

  // NOTE: Resets filters every time a new product type or a new scope is selected
  useEffect(() => {
    setFilters(defaultFilters)
  }, [sectorType, sectorId, currentProductType])

  // Geography data
  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const { data: geographyData, isLoading: isGeographyDataLoading } = useQuery({
    queryKey: [
      'priceComplianceGeographyData',
      sectorType,
      sectorId,
      geography,
      currentProductType,
      filters,
      sortBy,
      offset
    ],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const {
          data: { geographies }
        } = await api.getPriceComplianceGeography(
          sectorType,
          sectorId,
          geography,
          currentProductType,
          filters,
          sortBy.column,
          sortBy.order,
          offset,
          DEFAULT_TABLE_PAGE_SIZE
        )

        return geographies
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  // Rows
  const parsedGeographyData = useMemo(() => {
    if (!geographyData) return []

    return geographyData.map((complianceGeo) => {
      if (geography === SECTOR_LEVELS.REGION) {
        return {
          linkTo: `/${SECTOR_LEVELS.REGION}/${complianceGeo.id}/pace/amplify/price-compliance`,
          ...complianceGeo
        }
      }
      if (geography === SECTOR_LEVELS.DISTRICT) {
        return {
          linkTo: `/${SECTOR_LEVELS.DISTRICT}/${complianceGeo.id}/pace/amplify/price-compliance`,
          ...complianceGeo
        }
      }
      if (geography === SECTOR_LEVELS.TERRITORY) {
        return {
          linkTo: `/${SECTOR_LEVELS.TERRITORY}/${complianceGeo.id}/pace/amplify/price-compliance`,
          ...complianceGeo
        }
      }
      if (geography === SECTOR_LEVELS.CUSTOMER) {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${complianceGeo.id}/pace/amplify/price-compliance`,
          ...complianceGeo
        }
      }

      if (sectorType === SECTOR_LEVELS.CUSTOMER && geography === 'sku') {
        return {
          linkTo: `/${SECTOR_LEVELS.CUSTOMER}/${sectorId}/actions/pricing/${complianceGeo.id}`,
          ...complianceGeo
        }
      }

      return {
        linkTo: null,
        ...complianceGeo
      }
    })
  }, [geography, filters, offset, sectorId, sectorType, currentProductType, geographyData, sortBy])

  const displayPrice = useMemo(() => {
    return sectorType === SECTOR_LEVELS.CUSTOMER && geography === 'sku'
  })

  const strategies = useMemo(() => {
    const strats = []

    for (const data of parsedGeographyData) {
      for (const [id, strat] of Object.entries(data.strategies)) {
        const stratAlreadyExists = strats.some((s) => s.id === id)
        if (!stratAlreadyExists) strats.push({ id, name: strat.conditionName })
      }
    }

    return orderBy(strats, 'id', 'desc')
  }, [parsedGeographyData])

  const rows = useMemo(() => {
    if (['brand', 'brand_variant', 'sku'].includes(geography)) {
      if (geography === 'sku' && sectorType === 'customer') {
        const productRows = []
        parsedGeographyData.map((geo) => {
          return Object.values(geo.strategies).forEach((strat) => {
            const {
              averagePrice,
              averageGap,
              conditionName,
              percentCompliant,
              conditionType,
              notCompliantCustomers,
              benchmarkName
            } = strat
            const displayName = `${geo.name} - ${geo.id}`
            productRows.push({
              id: geo.id,
              geo: <GeoTableLineHeader name={displayName} linkTo={geo.linkTo} />,
              percentCompliant: formatPercent(percentCompliant) || '',
              averagePrice: formatCurrency(averagePrice) || '',
              averageGap: conditionType === 'NONE' ? '-' : formatCurrency(averageGap) || '',
              benchmarkName: ['NONE', 'VS_CEILING'].includes(conditionType) ? '-' : benchmarkName || '-',
              benchmarkPrice: conditionType === 'NONE' ? '-' : formatCurrency(averagePrice - averageGap) || '',
              conditionName,
              captureStatus: notCompliantCustomers <= 0 ? 'Compliant' : 'Non Compliant',
              conditionType: PRICING_STRATEGY_CONDITION_TYPES[conditionType]
            })
          })
        })
        return productRows
      }
      const productRows = []
      parsedGeographyData.map((geo) => {
        return Object.values(geo.strategies).forEach((strat) => {
          const {
            averagePrice,
            compliantCustomers,
            conditionName,
            notCompliantCustomers,
            percentCompliant,
            conditionType
          } = strat
          const displayName = geography === 'sku' ? `${geo.name} - ${geo.id}` : geo.name
          productRows.push({
            id: geo.id,
            geo: <GeoTableLineHeader name={displayName} linkTo={geo.linkTo} />,
            compliantCustomers,
            notCompliantCustomers,
            percentCompliant: formatPercent(percentCompliant) || '',
            averagePrice: formatCurrency(averagePrice) || '',
            conditionName,
            conditionType: PRICING_STRATEGY_CONDITION_TYPES[conditionType]
          })
        })
      })
      return productRows
    }
    return parsedGeographyData.reduce((acc, data) => {
      const value = {
        geo:
          geography === SECTOR_LEVELS.CUSTOMER ? (
            <StoreInfoTooltip
              displayName={data.name}
              customerName={data.name}
              erp={data.id}
              address={data.address}
              ownershipType={data.ownershipType}
              linkTo={data.linkTo}
            />
          ) : (
            <GeoTableLineHeader name={data.name} linkTo={data.linkTo} />
          )
      }

      strategies.forEach((strat) => {
        const linkedStrategy = data.strategies?.[strat.id] || {}
        value[camelCase(`${strat.id}_compliantCustomers`)] =
          formatCompactNumber(linkedStrategy?.compliantCustomers, { shouldStripIfInteger: true }) || ''
        value[camelCase(`${strat.id}_notCompliantCustomers`)] =
          formatCompactNumber(linkedStrategy?.notCompliantCustomers, { shouldStripIfInteger: true }) || ''
        value[camelCase(`${strat.id}_percentCompliant`)] = formatPercent(linkedStrategy?.percentCompliant) || ''
        if (displayPrice)
          value[camelCase(`${strat.id}_averagePrice`)] = formatCurrency(linkedStrategy?.averagePrice) || ''
      })
      acc.push(value)
      return acc
    }, [])
  }, [parsedGeographyData, strategies])

  // Pagination
  const hasReachedLastPage = isEmpty(rows) || rows.length < DEFAULT_TABLE_PAGE_SIZE

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }

  const nextPage = () => {
    if (hasReachedLastPage) return
    setPage(page + 1)
  }

  useEffect(() => {
    setPage(1)
  }, [sectorId, sectorType, currentProductType, geography, sortBy])

  // Data table
  const getContent = () => {
    if (isGeographyDataLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (!rows || !rows.length) return <EmptyState title="Nothing to display" />

    let subColumns = SUB_COLUMNS
    if (!displayPrice) {
      subColumns = subColumns.filter((sub) => sub.field !== 'averagePrice')
    }

    if (['brand', 'brand_variant', 'sku'].includes(geography)) {
      // here is where the customer product level is rendered

      if (geography === 'sku' && sectorType === 'customer') {
        const cols = createColumns([
          {
            title: translate(displayOptTableLabels(geography)),
            field: 'geo'
          },
          {
            title: translate('app.strategyName'),
            field: 'conditionName'
          },
          {
            title: translate('app.strategyType'),
            field: 'conditionType'
          },
          {
            title: translate('app.price'),
            field: 'averagePrice'
          },
          {
            title: translate('app.benchmarkPrice'),
            field: 'benchmarkPrice'
          },
          {
            title: translate('app.benchmarkProduct'),
            field: 'benchmarkName'
          },
          {
            title: translate('common.status'),
            field: 'captureStatus'
          },
          {
            title: translate('sellIn.gap'),
            field: 'averageGap'
          }
        ])
        return (
          <DataTable
            columns={cols.map(({ title, ...col }) => ({ headerName: title, ...col }))}
            rows={rows}
            fillContainer
            stickyFirstColumn
            stickyHeaders
            activeColumn={sortBy}
            onColumnClick={handleSort}
          />
        )
      }
      const cols = createColumns([
        {
          title: translate(displayOptTableLabels(geography)),
          field: 'geo'
        },
        {
          title: 'Strategy Name',
          field: 'conditionName'
        },
        {
          title: 'Strategy Type',
          field: 'conditionType'
        },
        ...subColumns
      ])
      return (
        <DataTable
          columns={cols.map(({ title, ...col }) => ({ headerName: title, ...col }))}
          rows={rows}
          fillContainer
          activeColumn={sortBy}
          onColumnClick={handleSort}
        />
      )
    }

    const cols = createColumns([
      {
        title: translate(displayOptTableLabels(geography)),
        field: 'geo'
      },
      ...strategies.map((strat) => ({ title: strat.name, field: strat.name, subColumns }))
    ])

    return (
      <WideDataTable
        columns={cols}
        rows={rows}
        fillContainer
        alignCenter
        alternatingColumns={displayPrice ? 4 : 3}
        activeSort={cols.length === 2}
        activeColumn={sortBy}
        onColumnClick={handleSort}
      />
    )
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="compliance-geography-toggle"
          includeSku
          includeBrandVariant
          includeBrand
          includeOwnership
          includeFsa
          includeCity
          geography={geography}
          setGeography={(newGeography) => {
            setPage(1)
            setGeography(newGeography)
            setProductLevel(['brand', 'brand_variant', 'sku'].includes(newGeography))
          }}
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          isDisabled={areGeographyFiltersLoading}
          isLoading={areGeographyFiltersLoading}
          filters={geographyFilters}
          appliedFilters={appliedFilters}
          onFiltersChange={(newFilters) => {
            // Merges with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFilters,
              ...newFilters
            }
            setFilters(updatedFilters)
            setPage(1)
          }}
        />
      ]}
      actions={
        !isGeographyDataLoading && (!hasReachedLastPage || page > 1)
          ? [
              <Pagination
                key="price-compliance-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disableNextButton={hasReachedLastPage}
              />
            ]
          : null
      }
    >
      {getContent()}
    </Card>
  )
}

GeographyCompliance.propTypes = {
  span: object
}

export default GeographyCompliance
