import React, { Fragment, useContext, useState } from 'react'
import camelCase from 'lodash/camelCase'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { rgba } from 'polished'
import { bool, object, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import { borderColor, greyDark, greyLight, offWhite, red, secondaryColor, white } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { smallFontSize, tinyFontSize } from 'styles/typography'

import duMaurier from 'static/images/brands/du-maurier.png'
import epenConsumable from 'static/images/brands/epen-consumable.png'
import epenDevice from 'static/images/brands/epen-device.png'
import epodConsumable from 'static/images/brands/epod-consumable.png'
import epodDevice from 'static/images/brands/epod-device.png'
import goDisposable from 'static/images/brands/go-disposable.png'
import goEdition5000Disposable from 'static/images/brands/go-edition-5000-disposable.png'
import goXlDisposable from 'static/images/brands/go-xl-disposable.png'
import johnPlayer from 'static/images/brands/john-player.png'
import johnPlayerPlus from 'static/images/brands/john-player-plus.png'
import marlboro from 'static/images/brands/marlboro.png'
import matinee from 'static/images/brands/matinee.png'
import pallMall from 'static/images/brands/pall-mall.png'
import players from 'static/images/brands/players.png'
import viceroy from 'static/images/brands/viceroy.png'
import vogue from 'static/images/brands/vogue.png'
import vuse from 'static/images/brands/vuse.png'

// KEY_ACCT_BRANDS
const brandImages = {
  duMaurier,
  epenConsumable,
  epenDevice,
  epodConsumable,
  epodDevice,
  goDisposable,
  goEdition5000Disposable,
  goXlDisposable,
  johnPlayerPlus,
  johnPlayer,
  marlboro,
  matinee,
  pallMall,
  players,
  viceroy,
  vogue,
  vuse
}

const Table = styled.table`
  width: 100%;

  td,
  th {
    border: 1px solid ${borderColor};
    padding: 8px;
    font-size: 12px;
    position: relative;
    background-color: ${white};
  }

  th {
    color: ${greyDark};
    text-align: left;
    background-color: ${rgba(offWhite, 0.5)};
  }
`

const Logo = styled.img`
  width: 28px;
  margin-right: ${spacing.small};
`

const Name = styled.strong`
  color: ${greyDark};
`

const Tag = styled.span`
  display: inline-block;
  background-color: #ffcccc;
  color: ${red};
  position: absolute;
  top: -${spacing.tiny};
  right: -${spacing.tiny};
  padding: 0 ${spacing.tiny};
  border-radius: ${borderRadius};
`
const Footer = styled.footer`
  padding-top: ${spacing.medium};
  display: flex;
  justify-content: center;
`

const FooterItem = styled.small`
  font-size: ${tinyFontSize};
  color: ${greyLight};

  & + &:before {
    content: '|';
    margin-left: ${spacing.tiny};
    margin-right: ${spacing.tiny};
  }
`

const ShowMore = styled.button`
  color: ${secondaryColor};
  margin-top: ${spacing.small};
  margin-left: ${spacing.medium};
  font-size: ${smallFontSize};

  ${media.breakpoint`
    margin-left: 0;
  `};
`

const BrandOffers = ({ brand, offersByPackSize, isVuse }) => {
  const brandLogo = brandImages[camelCase(brand)]

  return (
    <>
      <tr>
        <td colSpan="5">
          {brandLogo && <Logo src={brandLogo} />}
          <Name>{brand}</Name>
        </td>
      </tr>
      {Object.entries(offersByPackSize).map(([packSize, offers], idx) => (
        <Fragment key={idx}>
          {!isVuse && (
            <tr>
              <td width="40" rowSpan={offers.length + 1}>
                {packSize}
              </td>
            </tr>
          )}
          {offers.map(({ deal, isNew, offer }, idx) => {
            return (
              <tr key={idx}>
                <td colSpan={isVuse ? '2' : '1'}>
                  {deal}
                  {isNew && <Tag>New</Tag>}
                </td>
                <td>{offer}</td>
              </tr>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}

BrandOffers.propTypes = {
  brand: string,
  offersByPackSize: object,
  isVuse: bool
}

const KeyAccountOffers = ({ fmcOffers, vuseOffers }) => {
  const { translate, lang } = useContext(LangContext)
  const [showAllOffers, setShowAllOffers] = useState(false)
  const hasFmcOffers = !isEmpty(fmcOffers)
  const hasVuseOffers = !isEmpty(vuseOffers)
  const [firstBrand, firstOffer] = Object.entries(fmcOffers).concat(Object.entries(vuseOffers))[0]
  const conversionDate = !isEmpty(firstOffer) && Object.values(firstOffer)[0][0]?.conversionDate
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th colSpan="2">Product</th>
            <th>Offer</th>
          </tr>
        </thead>
        {showAllOffers ? (
          <tbody>
            {hasFmcOffers &&
              Object.entries(fmcOffers).map(([brand, brandOffers], i) => (
                <BrandOffers brand={brand} offersByPackSize={brandOffers} key={i} />
              ))}
            {hasVuseOffers &&
              Object.entries(vuseOffers).map(([brand, brandOffers], i) => (
                <BrandOffers brand={brand} offersByPackSize={brandOffers} key={i} isVuse />
              ))}
          </tbody>
        ) : (
          <tbody>
            <BrandOffers brand={firstBrand} offersByPackSize={firstOffer} isVuse={!hasFmcOffers} />
          </tbody>
        )}
      </Table>
      {conversionDate && (
        <Footer>
          <FooterItem>
            {translate('close.consumerOfferLatestUpdate', { date: moment(conversionDate).locale(lang).format('ll') })}
          </FooterItem>
        </Footer>
      )}
      <ShowMore type="button" onClick={() => setShowAllOffers(!showAllOffers)}>
        {showAllOffers ? 'Hide offers' : 'Show more'}
      </ShowMore>
    </>
  )
}

KeyAccountOffers.propTypes = {
  fmcOffers: object,
  vuseOffers: object
}

export default KeyAccountOffers
