import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { bool, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { closeSoundbitesForCustomer } from 'store/customers/selectors'

import GlobalAlert from 'components/GlobalAlert'
import { WrappedSpinner } from 'components/Spinner'

import { formatCompactNumber, formatGap } from 'utils/formatters'

import SectionCard from './SectionCard'

const PerformanceSoundbites = ({ dataKeyIsLoading, error }) => {
  const { translate } = useContext(LangContext)
  const { sectorId: customerId } = useParams()

  const closeSoundbites = useSelector((state) => closeSoundbitesForCustomer(state, { customerId }))

  if (error) return <GlobalAlert>{error}</GlobalAlert>

  if (dataKeyIsLoading) return <WrappedSpinner icon="spinner" />

  return (
    <div className="grid max-w-full gap-5 md:grid-cols-2">
      <SectionCard
        title={translate('close.performanceSoundbites.sellinTitle')}
        tip={translate(closeSoundbites?.sellin?.nextOrderDates.length > 0 && 'close.performanceSoundbites.sellinTip', {
          dates: closeSoundbites?.sellin?.nextOrderDates
        })}
        data={{
          type: 'sellin',
          fmc: {
            summary:
              closeSoundbites?.sellin?.fmc?.gap > 0
                ? translate('close.performanceSoundbites.sellinSummary', {
                    gap: closeSoundbites?.sellin?.fmc?.gap
                  })
                : translate('close.performanceSoundbites.sellinTargetReachedSummary'),
            products: closeSoundbites?.sellin?.fmc?.bottom5
          },
          vape: {
            summary:
              closeSoundbites?.sellin?.vape?.gap > 0
                ? translate('close.performanceSoundbites.sellinSummary', {
                    gap: closeSoundbites?.sellin?.vape?.gap
                  })
                : translate('close.performanceSoundbites.sellinTargetReachedSummary'),
            products: closeSoundbites?.sellin?.vape?.bottom5
          }
        }}
        amplifyLink="/pace/amplify/sell-in"
      />
      <SectionCard
        title={translate('close.performanceSoundbites.distroTitle')}
        tip={translate('close.performanceSoundbites.distroTip')}
        data={{
          type: 'distro',
          fmc: {
            summary: translate(
              closeSoundbites?.distro?.fmc?.gap === 0
                ? 'close.performanceSoundbites.distroSummaryNoProducts'
                : 'close.performanceSoundbites.distroSummary',
              {
                gap: closeSoundbites?.distro?.fmc?.gap
              }
            ),
            products: closeSoundbites?.distro?.fmc?.bottom5
          },
          vape: {
            summary: translate(
              closeSoundbites?.distro?.vape?.gap === 0
                ? 'close.performanceSoundbites.distroSummaryNoProducts'
                : 'close.performanceSoundbites.distroSummary',
              {
                gap: closeSoundbites?.distro?.vape?.gap
              }
            ),
            products: closeSoundbites?.distro?.vape?.bottom5
          }
        }}
        amplifyLink="/pace/amplify/distro"
      />

      <SectionCard
        title={translate('close.performanceSoundbites.priceCaptureTitle')}
        tip={translate('close.performanceSoundbites.priceCaptureTip')}
        data={{
          type: 'priceCapture',
          fmc: {
            products: closeSoundbites?.priceCapture?.fmc?.bottom5
          },
          vape: {
            products: closeSoundbites?.priceCapture?.vape?.bottom5
          }
        }}
        amplifyLink="/pace/amplify/price-capture"
      />

      <SectionCard
        title={translate('close.performanceSoundbites.selloutTitle')}
        data={{
          type: 'sellout',
          fmc: {
            summary:
              closeSoundbites?.sellout?.fmc?.delta > 0
                ? translate('close.performanceSoundbites.selloutSummary', {
                    dataType: 'volume',
                    delta: `${closeSoundbites?.sellout?.fmc?.delta < 0 ? 'dropped' : 'increased'} by ${formatGap(
                      closeSoundbites?.sellout?.fmc?.delta,
                      (val) => formatCompactNumber(val)
                    )} pp`,
                    date: closeSoundbites?.sellout?.fmc?.date,
                    share: closeSoundbites?.sellout?.fmc?.marketshare
                  })
                : translate('close.performanceSoundbites.noDeltaSelloutSummary'),
            products: {
              top3: closeSoundbites?.sellout?.fmc?.top3,
              bottom3: closeSoundbites?.sellout?.fmc?.bottom3
            }
          },
          vape: {
            summary:
              closeSoundbites?.sellout?.vape?.delta > 0
                ? translate('close.performanceSoundbites.selloutSummary', {
                    dataType: 'value',
                    delta: `${closeSoundbites?.sellout?.vape?.delta < 0 ? 'dropped' : 'increased'} by ${formatGap(
                      closeSoundbites?.sellout?.vape?.delta,
                      (val) => formatCompactNumber(val)
                    )} pp`,
                    date: closeSoundbites?.sellout?.vape?.date,
                    share: closeSoundbites?.sellout?.vape?.marketshare
                  })
                : translate('close.performanceSoundbites.noDeltaSelloutSummary'),
            products: {
              top3: closeSoundbites?.sellout?.vape?.top3,
              bottom3: closeSoundbites?.sellout?.vape?.bottom3
            }
          }
        }}
        amplifyLink="/pace/amplify/sell-out"
      />
    </div>
  )
}

PerformanceSoundbites.propTypes = {
  dataKeyIsLoading: bool,
  error: string
}

export default PerformanceSoundbites
