import queryString from 'query-string'

import { api } from 'store/api'

export const getCustomer = (customerId) => api.get(`/customers/${customerId}`)

export const getCustomerStoreContact = (customerId, contactId) =>
  api.get(`/store-contacts/customers/${customerId}/contacts/${contactId}`)

export const getCustomerOrders = (customerId) => api.get(`/customers/${customerId}/orders`)

export const updateCustomerHours = ({ customerId, hours }) => api.post(`/customers/${customerId}/hours`, { hours })

export const getCustomerPrimaryTerritory = (customerId) => api.get(`/customers/${customerId}/primary-territory`)

export const submitCustomerScopeUpdate = ({ customerId, id, programExists }) =>
  api.put(`/customers/${customerId}`, { id, programExists })

export const submitProjectResponse = (values) => api.post(`/customer-projects`, values)

export const getCustomerSuggestions = ({ match }) =>
  api.get(`/customers/suggest?${queryString.stringify({ match, fullCustomer: true }, { arrayFormat: 'comma' })}`)

export const createCustomerMessage = (details) => api.post('/messages', details)
export const updateCustomerMessage = (details) => api.put(`/messages/${details.id}`, details)
export const fetchMyMessages = () => api.get('/messages')

export const fetchCustomerGoFunds = (customerId) => api.get(`/customers/${customerId}/go-funds`)

export const listCustomerGoFunds = (customerId) =>
  api.get(`/go-fund-programs/customers/${customerId}/image-recognition`)

export const getCustomerGoFunds = (customerId, programId) =>
  api.get(`/go-fund-programs/${programId}/customers/${customerId}/image-recognition`)

export const getCustomerSellInPrograms = (customerId) => api.get(`/sell-in-programs/customer/${customerId}`)

export const getCustomerConsumerOffer = (customerId) => api.get(`/customers/${customerId}/consumer-offer`)

export const getCustomerSla = (customerId) => api.get(`/customers/${customerId}/customer-sla`)

export const getCustomerProjects = (customerId) => api.get(`/customers/${customerId}/project-links`)

export const getCustomerAssortment = (customerId) => api.get(`/customers/${customerId}/assortment`)

export const suggestCustomer = (query) =>
  api.get(`/customers/suggest-customer?${queryString.stringify({ query }, { skipNull: true })}`)

export const getCustomerReminders = (customerId) => api.get(`/customers/${customerId}/reminders`)

export const createCustomerReminder = ({ customerId, reminder }) =>
  api.post(`/customers/${customerId}/reminder`, { reminder })

export const updateCustomerReminders = ({ customerId, reminders }) =>
  api.put(`/customers/${customerId}/reminder`, { reminders })

export const getCustomerProductDrivers = (customerId) => api.get(`/customers/${customerId}/productDrivers`)
