import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS, PLANNING_SELECTOR_ACTIONS } from 'store/actions'

import { validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchGeographyFilterOptions = createAction(
  'Fetch geography filter options',
  ({ dataKey, sectorType, sectorId, productType, vapeCategory }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data: filters } = await api.getGeographyFilterOptions({
          sectorType,
          sectorId,
          productType,
          vapeCategory
        })
        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos?.filters
        dispatch(
          action({
            id: sectorId,
            productType,
            vapeCategory,
            filters
          })
        )
      })
)

export const fetchTrendingInventory = createAction(
  'Fetch trending inventory',
  ({ sectorType, sectorId, dataKey, activeProductType, vapeCategory }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { trending }
        } = await api.getTrending({ sectorType, sectorId, activeProductType, vapeCategory })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos.trending
        dispatch(
          action({
            id: sectorId,
            trending,
            productType: activeProductType,
            vapeCategory
          })
        )
      })
)

export const fetchBrandsInventory = createAction(
  'Fetch brands inventory',
  ({ dataKey, sectorType, sectorId, productType, timeframe, vapeCategory, sortBy, direction }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { brands }
        } = await api.getBrands({ sectorType, sectorId, productType, timeframe, vapeCategory, sortBy, direction })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos.brand
        dispatch(
          action({
            id: sectorId,
            productType,
            timeframe,
            brands,
            vapeCategory,
            sortBy,
            direction
          })
        )
      })
)

export const fetchMaterialsInventory = createAction(
  'Fetch materials inventory',
  ({ dataKey, sectorType, sectorId, productType, timeframe, vapeCategory, sortBy, direction }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { materials }
        } = await api.getMaterials({ sectorType, sectorId, productType, timeframe, vapeCategory, sortBy, direction })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos.materials

        dispatch(
          action({
            id: sectorId,
            productType,
            timeframe,
            materials,
            vapeCategory,
            sortBy,
            direction
          })
        )
      })
)

export const fetchGeographyInventory = createAction(
  'Fetch region inventory',
  ({
      dataKey,
      sectorType,
      sectorId,
      geography,
      filters,
      productType,
      timeframe,
      vapeCategory,
      offset,
      sortBy,
      direction
    }) =>
    async (dispatch, getState) => {
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const {
          data: { geographies }
        } = await api.getGeography({
          sectorType,
          sectorId,
          geography,
          filters,
          productType,
          timeframe,
          vapeCategory,
          offset,
          sortBy,
          direction
        })

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].inventoryOos.geography
        dispatch(
          action({
            id: sectorId,
            geographies,
            geography,
            filters,
            productType,
            timeframe,
            vapeCategory,
            offset,
            sortBy,
            direction
          })
        )
      })
    }
)

export const fetchOosInvCaptureKpis = createAction(
  'Get Oos And Inv Capture KPIs values',
  (props, dataKey) => async (dispatch, getState) =>
    validateStoreDataKey(getState(), dispatch, dataKey, async () => {
      const { data } = await api.getOosInvCaptureKpi(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorType].healthCheck
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )
    })
)
