import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import config from 'config'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { bool, func, object, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import * as api from 'store/Sellout/endpoints'

import Card from 'components/card'
import DataGraph from 'components/DataTable/DataGraph'
import DataVariation from 'components/DataTable/DataVariation'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Dropdown from 'components/Dropdown'
import Pagination from 'components/Pagination'
import Tooltip from 'components/Tooltip'

import {
  CHANNEL_VALUES,
  DEFAULT_TABLE_PAGE_SIZE,
  PACE_SELLOUT_MANUFACTURER_FILTERS,
  PRODUCT_TYPE_VALUES,
  SECTOR_LEVELS,
  SORTING_DIRECTION
} from 'utils/constants'
import {
  formatCompactCurrency,
  formatCompactNumber,
  formatCurrency,
  formatNumber,
  formatPercent
} from 'utils/formatters'
import { getGenericGeographyFilters } from 'utils/genericGeographyFilters'
import { getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import StoreInfoTooltip from '../StoreInfoTooltip'

import AmplifySelloutTableRowHeaderToggle, { displayOptTableLabels } from './AmplifySelloutTableRowHeaderToggle'

const TIME_COLS = {
  rolling: [
    {
      field: 'lShort',
      headerName: 'LW '
    },
    {
      field: 'vpShort',
      headerName: 'vs. PW'
    },
    {
      field: 'lMid',
      headerName: 'L4'
    },
    {
      field: 'vpMid',
      headerName: 'vs. P4'
    },
    {
      field: 'lLong',
      headerName: 'L13'
    },
    {
      field: 'vpLong',
      headerName: 'vs. P13'
    }
  ],
  pointInTime: [
    {
      field: 'lShort',
      headerName: 'CTD'
    },
    {
      field: 'vpShort',
      headerName: 'vs. PC'
    },
    {
      field: 'lMid',
      headerName: 'QTD'
    },
    {
      field: 'vpMid',
      headerName: 'vs. PQ'
    },
    {
      field: 'lLong',
      headerName: 'YTD'
    },
    {
      field: 'vpLong',
      headerName: 'vs. PY'
    }
  ]
}

const FMC_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.fmc.pos
const VAPE_POS_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.pos
const VAPE_SPECIALTY_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.vape.specialty
const NRT_OPTIONS = PACE_SELLOUT_MANUFACTURER_FILTERS.nrt.pos

const ManufacturerDropdown = ({ currentProductType, currentChannel, onChange, value, currentProportion }) => {
  const MANUFACTURERS_DROPDOWN_OPTIONS = useMemo(() => {
    if (currentProductType === PRODUCT_TYPE_VALUES.FMC) return FMC_OPTIONS
    if (currentProductType === PRODUCT_TYPE_VALUES.NRT) return NRT_OPTIONS
    if (currentChannel === CHANNEL_VALUES.SPECIALTY) return VAPE_SPECIALTY_OPTIONS

    return VAPE_POS_OPTIONS
  }, [currentChannel, currentProductType])

  const allOptions = [{ label: 'All', value: 'all' }, ...MANUFACTURERS_DROPDOWN_OPTIONS]

  return <Dropdown onChange={onChange} value={value} options={allOptions} />
}

ManufacturerDropdown.propTypes = {
  currentProductType: string,
  currentChannel: string,
  onChange: func,
  value: string,
  currentProportion: string
}

const defaultGeographyBySectorLevel = {
  [SECTOR_LEVELS.CUSTOMER]: 'brand',
  [SECTOR_LEVELS.TERRITORY]: 'headoffice',
  [SECTOR_LEVELS.DISTRICT]: 'territory',
  [SECTOR_LEVELS.REGION]: 'province',
  [SECTOR_LEVELS.NATIONAL]: 'region'
}

const manufacturerByProductType = {
  fmc: 'ITCAN',
  vape: 'Vuse',
  nrt: 'Zonnic'
}

// NOTE: Used to format fetched geography data
const formatTrendlineData = (geographyL13Totals) => {
  const { l13w1, l13w2, l13w3, l13w4, l13w5, l13w6, l13w7, l13w8, l13w9, l13w10, l13w11, pw, lw } = geographyL13Totals
  return [
    { d: +l13w1 },
    { d: +l13w2 },
    { d: +l13w3 },
    { d: +l13w4 },
    { d: +l13w5 },
    { d: +l13w6 },
    { d: +l13w7 },
    { d: +l13w8 },
    { d: +l13w9 },
    { d: +l13w10 },
    { d: +l13w11 },
    { d: +pw },
    { d: +lw }
  ]
}

// NOTE: Used to format fetched geography data
const endpointEnd = 'pace/amplify/sell-out'
const rowHeaderColumnMapping = {
  customer: {
    key: 'customerId',
    label: (c) => `${c.customer || c.name} (${c.customerId || c.id})`,
    linkTo: (c) => `/${SECTOR_LEVELS.CUSTOMER}/${c.customerId}/${endpointEnd}`
  },
  headoffice: { key: 'headOffice', label: (d) => d.headOffice },
  territory: {
    key: 'territoryName',
    label: (d) => d.territoryName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.TERRITORY}/${d.territoryId || d.id}/${endpointEnd}`
  },
  district: {
    key: 'districtName',
    label: (d) => d.districtName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.DISTRICT}/${d.districtId || d.id}/${endpointEnd}`
  },
  region: {
    key: 'regionName',
    label: (d) => d.regionName || d.name,
    linkTo: (d) => `/${SECTOR_LEVELS.REGION}/${d.regionId || d.id}/${endpointEnd}`
  },
  fsa: { key: 'fsa', label: (d) => d.fsa },
  city: { key: 'city', label: (d) => d.city },
  subregion: { key: 'KA Subregion', label: (d) => d.subregion },
  province: { key: 'province', label: (d) => d.province },
  brand: { key: 'brand', label: (d) => d.brand },
  variantLabel: { key: 'variantLabel', label: (d) => d.variantLabel },
  sku: { key: 'productId', label: (d) => d.productName }
}

const defaultFilters = {
  region: [],
  district: [],
  territory: [],
  kaSubregion: [],
  headoffice: [],
  banner: [],
  province: [],
  tier: [],
  ownershipType: [],
  outletSubType: [],
  store: [],
  fsa: [],
  city: [],
  brand: [],
  brandVariant: [],
  length: [],
  size: [],
  productDescription: []
}

const AmplifySelloutTableCard = ({
  span,
  currentProportion,
  currentTimeDisplay,
  currentMetric,
  currentChannel,
  currentVapeCategory,
  dataType,
  unitOfMeasure,
  selloutDataLoading
}) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel, currentProductType } = useContext(SectorContext)

  const [manufacturer, setManufacturer] = useState(manufacturerByProductType[currentProductType])
  const [geography, setGeography] = useState(defaultGeographyBySectorLevel[selectedLevel])
  const [page, setPage] = useState(1)
  const [error, setError] = useState()

  const [filters, setFilters] = useState(defaultFilters)

  const defaultSortColumn =
    selectedLevel !== SECTOR_LEVELS.CUSTOMER ? 'contribution' : currentTimeDisplay === 'rolling' ? 'lLong' : 'lShort'

  const [sortBy, setSortBy] = useState({ column: defaultSortColumn, order: SORTING_DIRECTION.DESC })

  const defaultSortAndPageData = {
    limit: DEFAULT_TABLE_PAGE_SIZE,
    sortColumn: sortBy.column,
    sortDirection: sortBy.order
  }

  const generateCols = ({ rowHeader, currentTimeDisplay, selectedLevel }) => {
    const baseCols = [
      {
        field: 'rowHeader',
        headerName: displayOptTableLabels[rowHeader]
      }
    ]

    const baseColsForSectorLevel =
      selectedLevel === SECTOR_LEVELS.CUSTOMER
        ? baseCols
        : baseCols.concat({
            field: 'contribution',
            headerName: 'Contribution'
          })

    const colsForVapeChannelCategory = showDisposableUnitsCtd
      ? [
          {
            field: 'disposableUnitsVTarget',
            headerName: '(vs target)'
          }
        ]
      : []

    const disableAWR = !config.featureFlags.awr || (currentProportion === 'share' && currentTimeDisplay === 'rolling')

    const awrCols = !disableAWR
      ? [
          {
            field: 'awr4',
            headerName: 'AWR4'
          },
          {
            field: 'awr13',
            headerName: 'AWR13'
          }
        ]
      : []

    const trendCols = [{ field: 'l13Trend', headerName: translate('app.trendedView'), isLarge: true }]

    return baseColsForSectorLevel
      .concat(colsForVapeChannelCategory)
      .concat(TIME_COLS[currentTimeDisplay])
      .concat(awrCols)
      .concat(trendCols)
  }

  const makeRow = (
    { sectorLevel, geography, showDisposableUnitsCtd, dataFormatter, tooltipFormatter },
    {
      id,
      geo,
      customer,
      address,
      ownershipType,
      contribution,
      lShort,
      vpShort,
      lMid,
      vpMid,
      lLong,
      vpLong,
      l13Trendline,
      ccTarget,
      ccTargetActuals,
      linkTo,
      awr4,
      awr13
    }
  ) => {
    const displayName = geography === 'sku' ? `${geo} - ${id}` : geography === SECTOR_LEVELS.CUSTOMER ? customer : geo

    const row = {
      rowHeader:
        geography === SECTOR_LEVELS.CUSTOMER ? (
          <StoreInfoTooltip
            displayName={displayName}
            customerName={displayName}
            erp={id}
            address={JSON.parse(address)}
            ownershipType={ownershipType}
            linkTo={linkTo}
          />
        ) : (
          <GeoTableLineHeader name={displayName} linkTo={linkTo} />
        ),
      lShort: (
        <Tooltip isNumber hint={tooltipFormatter(lShort)}>
          {isNull(lShort) ? '-' : dataFormatter(lShort)}
        </Tooltip>
      ),
      vpShort: (
        <Tooltip isNumber={!isNull(vpShort)} hint={tooltipFormatter(vpShort)}>
          {isNull(vpShort) ? '-' : <DataVariation variation={vpShort} formatter={dataFormatter} />}
        </Tooltip>
      ),
      lMid: (
        <Tooltip isNumber={!isNull(lMid)} hint={tooltipFormatter(lMid)}>
          {isNull(lMid) ? '-' : dataFormatter(lMid)}
        </Tooltip>
      ),
      vpMid: (
        <Tooltip isNumber={!isNull(vpMid)} hint={tooltipFormatter(vpMid)}>
          {isNull(vpMid) ? '-' : <DataVariation variation={vpMid} formatter={dataFormatter} />}
        </Tooltip>
      ),
      lLong: (
        <Tooltip isNumber={!isNull(lLong)} hint={tooltipFormatter(lLong)}>
          {isNull(lLong) ? '-' : dataFormatter(lLong)}
        </Tooltip>
      ),
      vpLong: (
        <Tooltip isNumber={!isNull(vpLong)} hint={tooltipFormatter(vpLong)}>
          {isNull(vpLong) ? '-' : <DataVariation variation={vpLong} formatter={dataFormatter} />}
        </Tooltip>
      ),
      awr4: (
        <Tooltip isNumber={!isNull(awr4)} hint={tooltipFormatter(awr4)}>
          {isNull(awr4) ? '-' : dataFormatter(awr4)}
        </Tooltip>
      ),
      awr13: (
        <Tooltip isNumber={!isNull(awr13)} hint={tooltipFormatter(awr13)}>
          {isNull(awr13) ? '-' : dataFormatter(awr13)}
        </Tooltip>
      ),
      l13Trend: <DataGraph color="#53CCF8" data={l13Trendline} dataFormatter={dataFormatter} />
    }

    if (sectorLevel !== SECTOR_LEVELS.CUSTOMER) {
      row.contribution = formatPercent(contribution, { convertDecimal: true })
    }

    if (showDisposableUnitsCtd) {
      row.disposableUnitsVTarget =
        !ccTarget || geography === 'sku' ? (
          '-'
        ) : (
          <Tooltip isNumber hint={formatNumber(ccTargetActuals - ccTarget)}>
            <DataVariation variation={ccTargetActuals - ccTarget} format={formatCompactNumber} />
          </Tooltip>
        )
    }

    return row
  }

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  useLayoutEffect(() => {
    if (currentProportion === 'share' && manufacturer === 'all')
      setManufacturer(manufacturerByProductType[currentProductType])
  }, [currentProportion])

  useLayoutEffect(() => {
    setManufacturer(manufacturerByProductType[currentProductType])
  }, [currentProductType, currentChannel])

  useEffect(() => {
    setPage(1)
  }, [currentSector?.id, selectedLevel, currentProductType, geography, manufacturer, currentVapeCategory, sortBy])

  const tableFilters = useMemo(() => {
    if (currentProductType === PRODUCT_TYPE_VALUES.VAPE) return { manufacturer, vapeCategory: currentVapeCategory }
    if (currentProductType === PRODUCT_TYPE_VALUES.FMC) return { manufacturer }
    if (currentProductType === PRODUCT_TYPE_VALUES.NRT) return { manufacturer }
  }, [currentProductType, currentVapeCategory, manufacturer])

  // Geography filters options
  const { data: geographyFiltersOptions, isLoading: areGeographyFiltersLoading } = useQuery({
    queryKey: [
      'selloutGeographyFilterOptions',
      selectedLevel,
      currentSector[selectedLevel]?.id,
      currentProductType,
      currentChannel,
      manufacturer,
      currentVapeCategory
    ],
    queryFn: async () => {
      setError(null)
      if (selectedLevel && currentSector[selectedLevel]?.id) {
        const { data } = await api.fetchAmplifySelloutFiltersOptions(
          selectedLevel,
          currentSector[selectedLevel]?.id,
          currentProductType,
          currentChannel,
          manufacturer,
          currentVapeCategory
        )

        return data
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
  }

  const geographyFilters = useMemo(() => {
    const options = {
      ...geographyFiltersOptions
    }

    const changeEventHandlers = {
      regionHandler: (value) => handleFilterChange({ region: value }),
      districtHandler: (value) => handleFilterChange({ district: value }),
      territoryHandler: (value) => handleFilterChange({ territory: value }),
      kaSubregionHandler: (value) => handleFilterChange({ kaSubregion: value }),
      headofficeHandler: (value) => handleFilterChange({ headoffice: value }),
      bannerHandler: (value) => handleFilterChange({ banner: value }),
      provinceHandler: (value) => handleFilterChange({ province: value }),
      tierHandler: (value) => handleFilterChange({ tier: value }),
      ownershipTypeHandler: (value) => handleFilterChange({ ownershipType: value }),
      outletSubtypeHandler: (value) => handleFilterChange({ outletSubtype: value }),
      storeHandler: (value) => handleFilterChange({ store: value }),
      fsaHandler: (value) => handleFilterChange({ fsa: value }),
      cityHandler: (value) => handleFilterChange({ city: value }),
      brandHandler: (value) => handleFilterChange({ brand: value }),
      brandVariantHandler: (value) => handleFilterChange({ brandVariant: value }),
      lengthHandler: (value) => handleFilterChange({ length: value }),
      sizeHandler: (value) => handleFilterChange({ size: value }),
      productDescriptionHandler: (value) => handleFilterChange({ productDescription: value })
    }

    return getGenericGeographyFilters(
      filters,
      options,
      changeEventHandlers,
      translate,
      selectedLevel,
      currentProductType,
      currentChannel
    )
  }, [geography, areGeographyFiltersLoading, selectedLevel, currentProductType, currentChannel, translate])

  const appliedFilters = Object.values(filters).filter((value) => value.length !== 0).length

  // NOTE: Resets filters every time a new product type or a new scope is selected
  useEffect(() => {
    setFilters(defaultFilters)
  }, [selectedLevel, currentSector[selectedLevel]?.id, currentProductType])

  // Geography data
  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const { data: geographyData, isLoading: isGeographyDataLoading } = useQuery({
    queryKey: [
      'selloutGeographyData',
      selectedLevel,
      currentSector[selectedLevel]?.id,
      currentProductType,
      currentChannel,
      geography,
      dataType,
      currentProportion,
      currentTimeDisplay,
      unitOfMeasure,
      offset,
      tableFilters,
      filters,
      defaultSortAndPageData
    ],
    queryFn: async () => {
      setError(null)
      if (selectedLevel && currentSector[selectedLevel]?.id) {
        const {
          data: { result }
        } = await api.fetchAmplifySelloutGeographyData({
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          productType: currentProductType,
          channel: currentChannel,
          geography,
          dataType,
          proportion: currentProportion,
          timeDisplay: currentTimeDisplay,
          unitOfMeasure,
          offset,
          ...tableFilters,
          ...filters,
          ...defaultSortAndPageData
        })

        return result
      }

      return []
    },
    select: (data) => {
      const rowHeaderColumn = rowHeaderColumnMapping[geography]
      const rows = data.map((r) => {
        return {
          id: r[rowHeaderColumn.key],
          geo: rowHeaderColumn.label(r),
          linkTo: rowHeaderColumn.linkTo && rowHeaderColumn.linkTo(r),
          l13Trendline: formatTrendlineData(r),
          ...r
        }
      })

      return rows
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const showDisposableUnitsCtd = useMemo(() => {
    return (
      ['allConsumables', 'disposables', 'closedConsumables', 'devices'].includes(currentVapeCategory) &&
      currentChannel === CHANNEL_VALUES.POS &&
      currentProductType === PRODUCT_TYPE_VALUES.VAPE
    )
  }, [currentVapeCategory, currentChannel, currentProductType])

  const columns = generateCols({ rowHeader: geography, currentTimeDisplay, selectedLevel, showDisposableUnitsCtd })

  const dataFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: '-' })
      : currentMetric === 'cost'
      ? (v) => formatCompactCurrency(v, { nullDisplay: '-' })
      : (v) => formatCompactNumber(v, { nullDisplay: '-' })

  const tooltipFormatter =
    currentProportion === 'share'
      ? (v) => formatPercent(v, { convertDecimal: true, nullDisplay: null })
      : currentMetric === 'cost'
      ? (v) => formatCurrency(v, { nullDisplay: null })
      : (v) => formatNumber(v, { nullDisplay: null })

  const rows =
    geographyData?.map((v) => {
      return makeRow(
        { sectorLevel: selectedLevel, geography, showDisposableUnitsCtd, dataFormatter, tooltipFormatter },
        v
      )
    }) || []

  // Pagination
  const hasReachedLastPage = isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (hasReachedLastPage) return
    setPage(page + 1)
  }

  const cardProps = {
    title: `${displayOptTableLabels[geography]} Performance`,
    span,
    displayAmplify: false
  }

  return (
    <Card
      {...cardProps}
      headerActions={[
        <ManufacturerDropdown
          key="amplify-sell-out-table-manufacturer-opts"
          onChange={(e) => setManufacturer(e.target.value)}
          value={manufacturer}
          currentProductType={currentProductType}
          selectedLevel={selectedLevel}
          currentChannel={currentChannel}
          currentProportion={currentProportion}
        />,
        <AmplifySelloutTableRowHeaderToggle
          key="amplify-sell-out-geography"
          geography={geography}
          setGeography={setGeography}
          currentChannel={currentChannel}
        />,
        <GeographyFilterBuilder
          key="sellout-geography-filter-builder"
          isDisabled={areGeographyFiltersLoading}
          isLoading={areGeographyFiltersLoading}
          filters={geographyFilters}
          appliedFilters={appliedFilters}
          onFiltersChange={(newFilters) => {
            // Merges with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFilters,
              ...newFilters
            }
            setFilters(updatedFilters)
            setPage(1)
          }}
        />
      ]}
      actions={
        !isGeographyDataLoading && (!hasReachedLastPage || page > 1)
          ? [
              <Pagination
                key="sell-out-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disableNextButton={hasReachedLastPage}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={selloutDataLoading || isGeographyDataLoading}
        error={error}
        rows={rows}
        columns={columns}
        onColumnClick={handleSort}
        activeColumn={sortBy}
        unClickableColumns={['l13Trend']}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

AmplifySelloutTableCard.propTypes = {
  span: object,
  currentProportion: string,
  currentTimeDisplay: string,
  currentMetric: string,
  currentChannel: string,
  currentVapeCategory: string,
  dataType: string,
  unitOfMeasure: string,
  fetchAmplifySelloutGeographyData: func,
  selloutDataLoading: bool
}

export default AmplifySelloutTableCard
