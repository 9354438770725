import React, { useContext, useEffect, useMemo, useState } from 'react'
import { array, bool, func, number, object } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'
import Dropdown from 'components/Dropdown'
import FilteredDropdown from 'components/FilteredDropdown'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'
import { WrappedSpinner } from 'components/Spinner'

import { GEOGRAPHY_FILTER_CATEGORIES, getHighestHierarchies } from 'utils/genericGeographyFilters'
import { generateUuid } from 'utils/helpers'

const FilterRow = ({ currentFilter, filters, activeFilters, onFilterChange, onFilterRemove }) => {
  const { translate } = useContext(LangContext)

  // NOTE: Stores all selected values for a multi select filter to send them all at once when dropdown closes (only used for multi select dropdown)
  const [multiSelectValue, setMultiSelectValue] = useState(currentFilter.value)

  // Creates options array with disabled state for used filters
  const filtersOptions = useMemo(() => {
    const usedFiltersMap = activeFilters.reduce((acc, activeFilter) => {
      if (!activeFilter.id) return acc

      return {
        ...acc,
        [activeFilter.id]: true
      }
    }, {})

    // Gets highest customer and product hierarchies in active filters
    const highestActiveHierarchies = getHighestHierarchies(activeFilters)

    return filters.map((filter) => ({
      label: filter.label,
      value: filter.id,
      // Only disabled if it's used or hierarchy is smaller than current highest hierarchy of filter hierarchy type
      disabled: usedFiltersMap[filter.id] || filter.hierarchy < highestActiveHierarchies[filter.hierarchyType]
    }))
  }, [filters, activeFilters])

  // Gets options and category of selected filter
  const { selectedFilterOptions, selectedFilterCategory } = useMemo(() => {
    const filter = filters.find((filter) => filter.id === currentFilter.id)

    return {
      selectedFilterOptions: filter?.options || [],
      selectedFilterCategory: filter?.category || GEOGRAPHY_FILTER_CATEGORIES.SINGLE
    }
  }, [filters, currentFilter.id])

  const handleFilterChange = (e) => {
    // Finds selected filter options
    const { category, id, hierarchy, hierarchyType } = filters.find((filter) => filter.id === e.target.value)
    const value = category === GEOGRAPHY_FILTER_CATEGORIES.MULTI ? [] : 'all'
    onFilterChange({
      id,
      hierarchyType,
      hierarchy,
      value
    })
  }

  const isFilterIgnored = useMemo(() => {
    // Gets highest customer and product hierarchies in active filters
    const highestActiveHierarchies = getHighestHierarchies(activeFilters)
    const isIgnored = currentFilter.hierarchy < highestActiveHierarchies[currentFilter.hierarchyType]

    return isIgnored
  }, [activeFilters])

  return (
    <div className="flex w-full items-center gap-2">
      <div className="flex w-full min-w-0 items-center gap-2 max-md:flex-col max-md:items-start max-md:border-r max-md:border-slate-200 max-md:pr-3">
        <div className="shrink-0">
          <Dropdown
            placeholder={translate('common.selectFilter')}
            options={filtersOptions}
            value={currentFilter.id || ''}
            onChange={handleFilterChange}
            disabled={isFilterIgnored}
          />
        </div>
        <div className="w-full min-w-0">
          {selectedFilterCategory === GEOGRAPHY_FILTER_CATEGORIES.MULTI ? (
            <FilteredDropdown
              options={selectedFilterOptions}
              value={multiSelectValue}
              allowMultiSelect
              onChange={(value) => setMultiSelectValue(value)}
              // NOTE: Sends all selected values when dropdown closes to allow keeping the dropdown open while selecting multiple values
              onClose={() => onFilterChange({ ...currentFilter, value: multiSelectValue })}
              disabled={isFilterIgnored}
            />
          ) : (
            <FilteredDropdown
              options={selectedFilterOptions}
              value={currentFilter.value}
              onChange={(value) => onFilterChange({ ...currentFilter, value })}
              disabled={isFilterIgnored}
            />
          )}
        </div>
      </div>
      <div className="shrink-0">
        <IconButton ghost onClick={onFilterRemove} icon="trash" compact />
      </div>
    </div>
  )
}

FilterRow.propTypes = {
  currentFilter: object,
  filters: array.isRequired,
  onFilterChange: func.isRequired,
  onFilterRemove: func.isRequired,
  activeFilters: array.isRequired
}

const GeographyFilterBuilder = ({
  isLoading = false,
  isDisabled = false,
  filters,
  appliedFilters,
  onFiltersChange
}) => {
  const { translate } = useContext(LangContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState([])
  const [previousFilters, setPreviousFilters] = useState([])

  const activeFiltersCount = activeFilters.length
  const hasActiveFilters = activeFiltersCount > 0

  const clearAllFilters = () => {
    setActiveFilters([])
  }

  // NOTE: When selected scope or product type changes in parent component, the applied filter will reset and it will reset the applied filter here as well
  useEffect(() => {
    if (appliedFilters === 0) {
      clearAllFilters()
    }
  }, [appliedFilters])

  const areSomeFiltersIgnored = useMemo(() => {
    // Gets highest customer and product hierarchies in active filters
    const highestActiveHierarchies = getHighestHierarchies(activeFilters)
    const areSomeIgnored = activeFilters.some(
      (activeFilter) => activeFilter.hierarchy < highestActiveHierarchies[activeFilter.hierarchyType]
    )

    return areSomeIgnored
  }, [activeFilters])

  const addFilter = () => {
    setActiveFilters([...activeFilters, {}])
  }

  const removeFilter = (filterRowIndex) => {
    setActiveFilters(activeFilters.filter((_, index) => index !== filterRowIndex))
  }

  const updateFilter = (updatedFilter, filterRowIndex) => {
    const clonedActiveFilters = [...activeFilters]
    clonedActiveFilters[filterRowIndex] = updatedFilter

    setActiveFilters(clonedActiveFilters)
  }

  const handleApplyFilters = () => {
    if (activeFilters.length === 0) {
      // If no active filters, set all filters to initial value ('all' or [])
      const defaultFilters = filters.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.id]: filter.category === GEOGRAPHY_FILTER_CATEGORIES.MULTI ? [] : 'all'
        }),
        {}
      )

      onFiltersChange(defaultFilters)
    } else {
      // Gets highest customer and product hierarchies in active filters
      const highestActiveHierarchies = getHighestHierarchies(activeFilters)
      // Applies only filters of the highest hierarchies
      const filteredActiveFilters = activeFilters.filter(
        (activeFilter) => activeFilter?.hierarchy === highestActiveHierarchies[activeFilter.hierarchyType]
      )

      const newFilters = filteredActiveFilters.reduce(
        (acc, filteredActiveFilter) => ({
          ...acc,
          [filteredActiveFilter.id]: filteredActiveFilter.value
        }),
        {}
      )

      setActiveFilters(filteredActiveFilters)
      onFiltersChange(newFilters)
    }

    setModalOpen(false)
  }

  const handleCancel = () => {
    // Restores the previous state of filters
    setActiveFilters(previousFilters)
    setModalOpen(false)
  }

  const subtitle = areSomeFiltersIgnored ? <GlobalAlert>{translate('app.geo.ignoredFiltersWarning')}</GlobalAlert> : ''

  return (
    <>
      <Modal
        title={translate('common.filters')}
        subtitle={subtitle}
        open={modalOpen}
        onOpenChange={(isOpen) => {
          if (isOpen) {
            // Stores the current state of filters when opening
            setPreviousFilters([...activeFilters])
            setModalOpen(true)
          } else {
            handleCancel()
          }
        }}
        footer={
          <div className="flex items-center gap-2">
            <Button secondary onClick={handleCancel}>
              {translate('common.cancel')}
            </Button>
            <Button primary onClick={handleApplyFilters}>
              {translate('common.apply')}
            </Button>
          </div>
        }
        size="large"
      >
        <div className="flex h-full flex-col rounded-md ring-1 ring-slate-900/10">
          <div className="overflow-y-auto">
            <div className="m-3 space-y-3 max-md:space-y-4">
              {activeFilters.length === 0 ? (
                <div className="flex h-9 flex-col items-center justify-center gap-2 rounded-md bg-slate-50 p-3 text-center text-sm text-slate-700">
                  {translate('common.noFiltersApplied')}
                </div>
              ) : (
                activeFilters.map((activeFilter, index) => (
                  <FilterRow
                    key={generateUuid()}
                    currentFilter={activeFilter}
                    filters={filters}
                    activeFilters={activeFilters}
                    onFilterChange={(updatedFilter) => updateFilter(updatedFilter, index)}
                    onFilterRemove={() => removeFilter(index)}
                  />
                ))
              )}
            </div>
          </div>
          <hr className="mx-3 border-slate-200" />
          <div className="m-3">
            {/* Desktop filter buttons */}
            <div className="flex justify-between max-md:hidden">
              <Button secondary onClick={addFilter} icon="plus-small" disabled={activeFiltersCount >= filters.length}>
                {translate('common.addFilters')}
              </Button>
              <Button secondary onClick={clearAllFilters} icon="trash" disabled={activeFiltersCount === 0}>
                {translate('common.clearFilters')}
              </Button>
            </div>
            {/* Mobile filter buttons */}
            <div className="flex flex-col items-center justify-between gap-2 md:hidden">
              <Button
                secondary
                full
                onClick={addFilter}
                icon="plus-small"
                disabled={activeFiltersCount >= filters.length}
              >
                {translate('common.addFilters')}
              </Button>
              <Button secondary full onClick={clearAllFilters} icon="trash" disabled={activeFiltersCount === 0}>
                {translate('common.clearFilters')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <button
        disabled={isDisabled}
        onClick={() => {
          setPreviousFilters([...activeFilters])
          setModalOpen(true)
        }}
        className={`
          ${hasActiveFilters ? 'font-medium text-brand-800' : 'text-slate-800'} 
          ${isDisabled ? 'cursor-not-allowed opacity-50' : ''} 
          relative flex h-9 items-center gap-2 rounded-md bg-white px-4 shadow-md ring-1 ring-slate-900/10 transition hover:bg-slate-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500 active:bg-slate-500/5
        `}
      >
        {isLoading && <WrappedSpinner icon="spinner" />}

        {!isLoading && hasActiveFilters && (
          <div className="-ml-0.5 flex h-5 w-5 shrink-0 items-center justify-center rounded-full bg-brand-100 text-2xs text-brand">
            {activeFiltersCount}
          </div>
        )}

        {!isLoading && !hasActiveFilters && (
          <Icon className="-ml-0.5 flex h-5 w-5 shrink-0" icon="filter-list" compact />
        )}
        <span className="flex items-center whitespace-nowrap">{translate('common.filters')}</span>
      </button>
    </>
  )
}

GeographyFilterBuilder.propTypes = {
  isLoading: bool,
  isDisabled: bool,
  filters: array.isRequired,
  appliedFilters: number.isRequired,
  onFiltersChange: func.isRequired
}

export default GeographyFilterBuilder
