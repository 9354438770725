import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import * as api from 'store/inventory/endpoints'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataGraph from 'components/DataTable/DataGraph'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Pagination from 'components/Pagination'

import { DEFAULT_TABLE_PAGE_SIZE, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatCompactNumber, formatNumber } from 'utils/formatters'
import { getGenericGeographyFilters } from 'utils/genericGeographyFilters'
import { getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

import InventoryCaptureContext from './InventoryCaptureContext'

const GeographyTable = ({ span }) => {
  const { translate } = useContext(LangContext)

  const [error, setError] = useState(null)

  const [geography, setGeography] = useState('region')

  const defaultSortColumn = 'oos'
  const [sortBy, setSortBy] = useState({ column: defaultSortColumn, order: SORTING_DIRECTION.DESC })
  const [page, setPage] = useState(1)

  const defaultFilters = {
    region: [],
    district: [],
    territory: [],
    kaSubregion: [],
    headoffice: [],
    banner: [],
    province: [],
    tier: [],
    ownershipType: [],
    outletSubType: [],
    store: [],
    fsa: [],
    city: [],
    brand: [],
    brandVariant: [],
    length: [],
    size: [],
    productDescription: []
  }

  const [filters, setFilters] = useState(defaultFilters)

  const COLS = [
    {
      field: 'oos',
      headerName: translate('app.OOS')
    },
    {
      field: 'missedPacks',
      headerName: translate('app.missedPacks')
    },
    {
      field: 'inventoryCapture',
      headerName: translate('app.InvCapture')
    }
  ]

  const { currentProductType: productType } = useContext(SectorContext)
  const { timeframe, vapeCategory } = useContext(InventoryCaptureContext)
  const { sectorId, sectorType } = useParams()

  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  // Geography filters
  const { data: geographyFiltersOptions, isLoading: areGeographyFiltersLoading } = useQuery({
    queryKey: ['inventoryOosGeographyFiltersOptions', sectorType, sectorId, productType, vapeCategory, vapeCategory],
    queryFn: async () => {
      const { data } = await api.getGeographyFilterOptions({
        sectorType,
        sectorId,
        productType,
        vapeCategory
      })

      return data
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
  }

  const geographyFilters = useMemo(() => {
    const options = {
      ...geographyFiltersOptions
    }

    const changeEventHandlers = {
      regionHandler: (value) => handleFilterChange({ region: value }),
      districtHandler: (value) => handleFilterChange({ district: value }),
      territoryHandler: (value) => handleFilterChange({ territory: value }),
      kaSubregionHandler: (value) => handleFilterChange({ kaSubregion: value }),
      headofficeHandler: (value) => handleFilterChange({ headoffice: value }),
      bannerHandler: (value) => handleFilterChange({ banner: value }),
      provinceHandler: (value) => handleFilterChange({ province: value }),
      tierHandler: (value) => handleFilterChange({ tier: value }),
      ownershipTypeHandler: (value) => handleFilterChange({ ownershipType: value }),
      outletSubtypeHandler: (value) => handleFilterChange({ outletSubtype: value }),
      storeHandler: (value) => handleFilterChange({ store: value }),
      fsaHandler: (value) => handleFilterChange({ fsa: value }),
      cityHandler: (value) => handleFilterChange({ city: value }),
      brandHandler: (value) => handleFilterChange({ brand: value }),
      brandVariantHandler: (value) => handleFilterChange({ brandVariant: value }),
      lengthHandler: (value) => handleFilterChange({ length: value }),
      sizeHandler: (value) => handleFilterChange({ size: value }),
      productDescriptionHandler: (value) => handleFilterChange({ productDescription: value })
    }

    return getGenericGeographyFilters(filters, options, changeEventHandlers, translate, sectorType, productType)
  }, [geography, areGeographyFiltersLoading, sectorType, productType, translate])

  const appliedFilters = Object.values(filters).filter((value) => value.length !== 0).length

  // NOTE: Resets filters every time a new geography or a new scope is selected
  useEffect(() => {
    setFilters(defaultFilters)
  }, [sectorType, sectorId, productType, vapeCategory])

  // Geography data
  const { data: geographyData, isLoading: isGeographyDataLoading } = useQuery({
    queryKey: [
      'inventoryOosGeographyData',
      sectorType,
      sectorId,
      geography,
      filters,
      productType,
      timeframe,
      vapeCategory,
      offset,
      sortBy
    ],
    queryFn: async () => {
      const {
        data: { geographies }
      } = await api.getGeography({
        sectorType,
        sectorId,
        geography,
        filters,
        productType,
        timeframe,
        vapeCategory,
        offset,
        sortBy: sortBy.column,
        direction: sortBy.order
      })

      return geographies
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  // Columns
  const columns = useMemo(() => {
    const updatedColumns = COLS.map((col) => ({ ...col, subHeader: timeframe === '1' ? 'vs PW' : 'vs P4' }))
    return [
      {
        field: 'name',
        headerName: translate(displayOptTableLabels(geography))
      },
      ...updatedColumns,
      {
        field: 'trend',
        headerName: translate('app.oosTrend'),
        isLarge: true
      }
    ]
  }, [geography, timeframe])

  // Rows
  const rows = useMemo(() => {
    if (!geographyData) return []

    return geographyData.map((row) => ({
      name:
        geography === SECTOR_LEVELS.CUSTOMER ? (
          <StoreInfoTooltip
            displayName={row.name}
            customerName={row.name}
            erp={row.id}
            address={row.address}
            ownershipType={row.ownership_type}
            linkTo={row.linkTo}
          />
        ) : (
          <GeoTableLineHeader name={row.name} linkTo={row.linkTo} />
        ),
      oos: (
        <DataCompare
          last={formatNumber(row.oos * 100)}
          variation={row.oosDiff * 100}
          reverseVariation
          variationBefore
          isPercent
        />
      ),
      missedPacks: (
        <DataCompare
          last={formatCompactNumber(row.missedPacks, { shouldStripIfInteger: true })}
          variation={row.missedPacksDiff}
          variationBefore
          reverseVariation
        />
      ),
      inventoryCapture: (
        <DataCompare
          last={formatNumber(row.inventoryCapture * 100)}
          variation={row.inventoryCaptureDiff * 100}
          isPercent
          variationBefore
        />
      ),
      trend: (
        <DataGraph
          color="#53CCF8"
          data={row.trendedOos.map((i) => ({ d: i.data * 100 }))}
          dataFormatter={formatNumber}
        />
      )
    }))
  }, [geographyData, geography, timeframe])

  useEffect(() => {
    setPage(1)
  }, [sectorId, sectorType, productType, geography, vapeCategory, sortBy])

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const hasReachedLastPage = rows?.length < DEFAULT_TABLE_PAGE_SIZE

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (hasReachedLastPage) return
    setPage(page + 1)
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="geography-toggle"
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          includeFsa
          includeCity
          geography={geography}
          setGeography={setGeography}
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          isDisabled={areGeographyFiltersLoading}
          isLoading={areGeographyFiltersLoading}
          filters={geographyFilters}
          appliedFilters={appliedFilters}
          onFiltersChange={(newFilters) => {
            // Merges with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFilters,
              ...newFilters
            }
            setFilters(updatedFilters)
            setPage(1)
          }}
        />
      ]}
      actions={
        !isGeographyDataLoading && (!hasReachedLastPage || page > 1)
          ? [
              <Pagination
                key="invcap-oos-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disableNextButton={hasReachedLastPage}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyDataLoading}
        error={error}
        rows={rows}
        columns={columns}
        activeColumn={sortBy}
        onColumnClick={handleSort}
        unClickableColumns={['trend']}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object
}

export default GeographyTable
