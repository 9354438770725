import React, { useContext, useEffect, useState } from 'react'
import sumBy from 'lodash/sumBy'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'

const SuccessContent = ({ result, program }) => {
  const { translate } = useContext(LangContext)

  const qtyAdded = sumBy(result?.aiResult.validProducts, 'quantity')
  const priceAdded = qtyAdded * program.perCartonCost

  const qty = program.ctnsBooked
  const target = program.targetVolume
  const [progress, setProgress] = useState(0)
  const progressPercentage = (qty / target) * 100

  useEffect(() => {
    const timer = setTimeout(() => setProgress(progressPercentage), 500)
    return () => clearTimeout(timer)
  }, [])

  const feedbackMessage = `${qtyAdded} ${translate('components.Extrahub.Scan.successAdded')}. ${translate(
    'components.Extrahub.Scan.storeRewarded'
  )} ${priceAdded.toLocaleString('en-US', {
    style: 'currency',
    currency: 'CAD'
  })}`

  return (
    <div className="flex w-full flex-col gap-5 rounded-md  bg-sky-100 p-4 text-sm ring-1">
      <div className="flex flex-col gap-2 ">
        <h3 className="text-base font-medium text-sky-900">{translate('common.success')}</h3>
        {result?.uploadResult?.status && (
          <p className="text-sm text-sky-800">
            {translate(`components.Extrahub.Result.ErrorMessage.${result.uploadResult.status}`)}
          </p>
        )}
        <p className="text-sm text-sky-800">{feedbackMessage}</p>
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <span className="text-2xs uppercase leading-none text-slate-500">{translate('common.progress')}</span>
          <span className="whitespace-nowrap text-2xs font-medium text-sky-800">
            {qty || 0}/{target} {translate('common.quantity')}
          </span>
        </div>

        <div className="flex h-5 w-full items-center">
          <div
            className="relative h-3 w-full overflow-clip rounded-full bg-sky-200"
            style={{
              transform: 'translateZ(0)'
            }}
          >
            <div
              className="ease-[cubic-bezier(0.65, 0, 0.35, 1)] h-full w-full bg-sky-500 transition-all duration-700"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
SuccessContent.propTypes = {
  result: object,
  program: object.isRequired
}

export default SuccessContent
