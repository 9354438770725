import React, { useContext } from 'react'
import { string } from 'prop-types'

import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import Dropdown from 'components/Dropdown'
import ProductTypePicker from 'components/ProductTypePicker'
import SegmentControl from 'components/SegmentControl'
import ViewHeader from 'components/ViewHeader'

import { TIME_FRAME_OPTIONS, VAPE_PRODUCT_CATEGORY } from 'utils/constants'

import InventoryCaptureContext from './InventoryCaptureContext'

const InventoryCaptureOosHeader = ({ parentHeader, header }) => {
  const { timeframe, setTimeframe, vapeCategory, setVapeCategory } = useContext(InventoryCaptureContext)
  const { currentProductType } = useContext(SectorContext)

  const { isWideLayout } = useContext(MediaContext)

  const filters = [
    <SegmentControl
      key="inventory-time-frame-picker"
      name="time-frame"
      onChange={(e) => setTimeframe(e.target.value)}
      value={timeframe}
      options={TIME_FRAME_OPTIONS}
    />,
    !isWideLayout && <ProductTypePicker key="amplify-inventory-product-type-picker" />,
    currentProductType === 'vape' && (
      <Dropdown
        key="amplify-sell-in-vape-category-picker"
        name="vape-category"
        onChange={(e) => setVapeCategory(e.target.value)}
        value={vapeCategory}
        options={VAPE_PRODUCT_CATEGORY}
        shouldUpperCase
      />
    )
  ]

  return <ViewHeader parentHeader={parentHeader} header={header} filters={filters} />
}

InventoryCaptureOosHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default InventoryCaptureOosHeader
