import React, { useContext } from 'react'
import config from 'config'
import { string } from 'prop-types'

import MediaContext from 'context/MediaContext'
import SectorContext from 'context/SectorContext'

import Dropdown from 'components/Dropdown'
import ProductTypePicker from 'components/ProductTypePicker'
import SegmentControl from 'components/SegmentControl'
import ViewHeader from 'components/ViewHeader'

import { DISTRO_TIME_OPTIONS, DISTRO_VAPE_CHANNEL, VAPE_PRODUCT_CATEGORY } from 'utils/constants'

import DistroContext from './DistroContext'

const DistroHeader = ({ parentHeader, header }) => {
  const { vapeCategory, setVapeCategory, vapeChannel, setVapeChannel, period, setPeriod } = useContext(DistroContext)
  const { currentProductType } = useContext(SectorContext)
  const { isWideLayout } = useContext(MediaContext)
  const filters = [
    !isWideLayout && <ProductTypePicker key="amplify-distro-product-type-picker" />,
    currentProductType === 'vape' && (
      <Dropdown
        key="amplify-distro-vape-category-picker"
        name="vape-category"
        onChange={(e) => setVapeCategory(e.target.value)}
        value={vapeCategory}
        options={VAPE_PRODUCT_CATEGORY}
        shouldUpperCase
      />
    ),
    currentProductType === 'vape' && (
      <Dropdown
        key="amplify-distro-vape-channel-picker"
        name="vape-channel"
        onChange={(e) => setVapeChannel(e.target.value)}
        value={vapeChannel}
        options={DISTRO_VAPE_CHANNEL}
        shouldUpperCase
      />
    ),
    config.featureFlags.distroL52 && (
      <SegmentControl
        key="amplify-distro-period-picker"
        small
        onChange={(e) => setPeriod(e.target.value)}
        value={period}
        options={DISTRO_TIME_OPTIONS}
      />
    )
  ]
  return <ViewHeader parentHeader={parentHeader} header={header} filters={filters} />
}

DistroHeader.propTypes = {
  parentHeader: string.isRequired,
  header: string.isRequired
}

export default DistroHeader
