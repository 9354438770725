import { createAction } from 'redux-actions'

import { addCustomerCloseSoundbites } from 'store/customers/actions'

import { ERRORS, validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchCloseSoundbites = createAction(
  'Fetch customer close soundbites',
  ({ customerId, dataKey }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        if (!customerId) throw ERRORS.missingCustomerId

        const { data: closeSoundbites } = await api.fetchCloseSoundbites({ sectorId: customerId })

        dispatch(addCustomerCloseSoundbites({ customerId, closeSoundbites }))
      })
)
