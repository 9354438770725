import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import ExtrahubContext from 'context/ExtrahubContext'
import LangContext from 'context/LangContext'

import { listCustomerGoFunds } from 'store/customers/endpoints'

import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import ListReceipts from 'components/Extrahub/ListReceipts'
import PaymentHistory from 'components/Extrahub/PaymentHistory'
import ReceiptModal from 'components/Extrahub/ReceiptModal'
import RetailerExtraHubCard from 'components/Extrahub/RetailerExtraHubCard'
import Section from 'components/Section'
import Spinner from 'components/Spinner'

const ImageRecognitionDetail = () => {
  const { customerId, contactId } = useContext(ExtrahubContext)

  const { programId } = useParams()
  const { translate } = useContext(LangContext)

  const [open, setOpen] = useState(false)
  const [receipt, setReceipt] = useState()

  function openModal(receipt) {
    setReceipt(receipt)
    setOpen(true)
  }

  const {
    isLoading,
    error,
    data: program
  } = useQuery({
    queryKey: ['imageRecognition', customerId],
    queryFn: async () => {
      const { data } = await listCustomerGoFunds(customerId)
      return data || []
    },
    select: (data) => data.find((p) => p.id === Number(programId))
  })

  if (isLoading) {
    return (
      <Container padded>
        <div className="mt-4 flex h-full w-full items-center justify-center">
          <Spinner icon="spinner" />
        </div>
      </Container>
    )
  }

  if (error) return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />

  if (!program) {
    return <EmptyState title="Nothing yet" subtitle="No programs have been added yet." />
  }

  return (
    <Section>
      <RetailerExtraHubCard program={program} contactId={contactId} detail>
        <ReceiptModal open={open} receipt={receipt} close={() => setOpen(false)} />
        <ListReceipts receipts={program.images} openModal={(receipt) => openModal(receipt)} />
        <PaymentHistory payments={program.payments} />
        <hr className="pb-2" />
      </RetailerExtraHubCard>
    </Section>
  )
}

export default ImageRecognitionDetail
