import localForage from 'localforage'
import { persistor } from 'main'
import { normalize } from 'normalizr'
import { createAction } from 'redux-actions'

import { mergeEntities, setEntities } from 'store/actions'
import { api as axiosBase, clearToken, registerSync, setRetailerId, setToken } from 'store/api'
import { fetchMessages } from 'store/messages/actions'
import outbox from 'store/outbox'
import { customerFetchSchema, employee as employeeSchema, masterFetchSchema } from 'store/schema'

import { addCallsToCustomer } from '../customers/actions'

import * as api from './endpoints'

export const offlineError = { response: { data: { error: 'offlineError' } } }

export const logout = createAction('Logout')
export const setLoadingFalse = createAction('Loading done - error thrown')
export const setLoadingTrue = createAction('Loading...')
export const setLastOfflineError = createAction('Last offline error')
export const updateRecentSearches = createAction('Update recent searches')
export const setCurrentTerritoryId = createAction('Set current territory id')
export const updateUserSettings = createAction('Update user settings')
export const addSyncError = createAction('Add Sync error to alerts')
export const removeSyncError = createAction('Remove sync error from alerts')
export const updateUserReadAlerts = createAction('Update user read alerts')
export const addMileageToEmployee = createAction('Add mileage to Employee')
export const removeMileageToEmployee = createAction('Remove Mileage To Employee')
export const setExtraDashboard = createAction('Set extra dashboard')

const invalidRetailerError = { response: { data: { error: 'offlineError' } } }

export const fetchRetailer = createAction('Fetch retailer', (push, target) => async (dispatch) => {
  try {
    const { data } = await api.verifyRetailer()
    const { entities } = normalize(data, customerFetchSchema)
    dispatch(mergeEntities(entities))

    if (target) {
      push && push(`/retailer/${data.customer.id}/${target}`)
    }
  } catch (err) {
    dispatch(setLoadingFalse())
    if (err.message === 'Network Error') throw offlineError
    throw err
  }
})

export const verifyRetailer = createAction(
  'Request retailer verification',
  (retailerId, push, target) => async (dispatch) => {
    console.log('redirectTo persistor.pause')
    persistor.pause()
    if (!window.navigator.onLine) {
      dispatch(setLoadingFalse())
      throw offlineError
    }
    if (!retailerId) {
      throw invalidRetailerError
    }
    try {
      await setRetailerId(retailerId)
      const { data } = await api.verifyRetailer()
      const { entities } = normalize(data, customerFetchSchema)
      dispatch(mergeEntities(entities))
    } catch (err) {
      dispatch(setLoadingFalse())
      if (err.message === 'Network Error') throw offlineError
      throw err
    }
    await dispatch(fetchRetailer(push, target))
  }
)

export const storeUser = createAction(
  'Store fetched user',
  ({ products = {}, scopes = {}, pricings = {}, alerts = {}, surveys = {}, calls = {}, customers = {}, ...entities }) =>
    async (dispatch) => {
      const shouldMergeEntities =
        entities.territories && Object.values(entities.territories).some(({ customers }) => !customers)
      if (shouldMergeEntities) {
        await dispatch(mergeEntities(Object.assign({}, { pricings }, entities)))
      } else {
        await dispatch(setEntities(Object.assign({}, { pricings }, entities)))
      }
      dispatch(mergeEntities({ products, scopes, surveys, calls, customers }))
      dispatch(addCallsToCustomer({ calls }))
      dispatch(setEntities({ alerts }))
      if (entities.user.user.trackingAllowed) dispatch(enableTracking())
      if (['telesalesRepresentative', 'customerService'].includes(entities.user.user.groupCode)) {
        dispatch(fetchMessages())
      }
    }
)

export const fetchUser = createAction('Fetch user', (params) => async (dispatch) => {
  const { lastFetch, push, token } = params || {}
  try {
    if (!token && !localStorage.getItem('token')) {
      return dispatch(requestLogout())
    }
    await setToken(token || localStorage.getItem('token'))
    const { data } = await api.fetchUser(lastFetch)
    const { entities } = normalize(data, masterFetchSchema)
    dispatch(storeUser(entities))
  } catch (err) {
    if (err.response.status === 401) {
      dispatch(requestLogout())
      return push && push('/auth/login', { error: err.response.data.message })
    }
  }
})

export const fetchUserCalendar = createAction('Fetch user calendar', (params) => async (dispatch) => {
  const { lastFetch, push, token } = params || {}
  try {
    if (!token && !localStorage.getItem('token')) {
      return dispatch(requestLogout())
    }
    await setToken(token || localStorage.getItem('token'))
    const { data } = await api.fetchEmployeeCalendar(lastFetch)
    const { entities } = normalize(data.employee, employeeSchema)
    dispatch(mergeEntities(entities))
  } catch (err) {
    if (err.response.status === 401) {
      dispatch(requestLogout())
      return push && push('/auth/login', { error: err.response.data.message })
    }
  }
})

export const requestLogin = createAction('Request login', (credentials) => async (dispatch) => {
  if (!window.navigator.onLine) {
    dispatch(setLoadingFalse())
    throw offlineError
  }
  try {
    const { data } = await api.login(credentials)
    await dispatch(fetchUser({ token: data.token }))
  } catch (err) {
    dispatch(setLoadingFalse())
    if (err.message === 'Network Error') throw offlineError
    throw err
  }
})

export const signup = createAction('Request signup', (user) => async (dispatch) => {
  if (!window.navigator.onLine) {
    dispatch(setLoadingFalse())
    throw offlineError
  }
  try {
    await api.signup({
      ...user,
      id: +user.id
    })
  } catch (err) {
    dispatch(setLoadingFalse())
    if (err.message === 'Network Error') throw offlineError
    throw err
  }
})

export const verifySignup = createAction('Request signup verification', (registrationToken) => async (dispatch) => {
  if (!window.navigator.onLine) {
    dispatch(setLoadingFalse())
    throw offlineError
  }
  try {
    const { data } = await api.verifySignup(registrationToken)
    await dispatch(fetchUser({ token: data.token }))
  } catch (err) {
    dispatch(setLoadingFalse())
    if (err.message === 'Network Error') throw offlineError
    throw err
  }
})

export const requestLogout = createAction('Request logout', (opts) => async (dispatch) => {
  localStorage.removeItem('ongoingCall')
  localStorage.removeItem('token')
  dispatch(logout(opts))
  dispatch(
    setEntities({
      alerts: {},
      calls: {},
      callTasks: {},
      customers: {},
      cycleCampaigns: {},
      districts: {},
      dataFetches: {},
      employeeOots: {},
      goFundAllocations: {},
      goFundPrograms: {},
      goFunds: {},
      insights: {},
      intel: {},
      orders: {},
      pricings: {},
      products: {},
      projects: {},
      regions: {},
      scopes: {},
      sector: {},
      sellInPrograms: {},
      surveys: {},
      territories: {},
      user: {}
    })
  )
  if (window.navigator.onLine && axiosBase.defaults.headers.common.Authorization) {
    api.logout().catch(() => null)
  }
  await clearToken()
  await localForage.clear()
  await outbox.clear()
})

export const submitNewPassword = createAction('Submit new password', (credentials, push) => async (dispatch) => {
  if (!window.navigator.onLine) {
    dispatch(setLoadingFalse())
    throw offlineError
  }
  try {
    const { data } = await api.resetPassword(credentials)
    await dispatch(fetchUser({ token: data.token }))
    push('/')
  } catch (err) {
    dispatch(setLoadingFalse())
    if (err.message === 'Network Error') throw offlineError
    throw err
  }
})

const passwordResetReqested = createAction('Password reset requested')

export const submitPasswordResetRequest = createAction(
  'Request password reset token',
  ({ email }, push) =>
    async (dispatch) => {
      if (!window.navigator.onLine) {
        queuePasswordResetRequest(email)
        return push(`/auth/password-reset-requested`, { state: { email, requestQueued: true } })
      }
      try {
        await api.requestResetToken(email)
        dispatch(passwordResetReqested)
        push(`/auth/password-reset-requested`, { state: { email } })
      } catch (err) {
        if (err.message === 'Network Error') {
          queuePasswordResetRequest(email)
          push(`/auth/password-reset-requested`, { state: { email, requestQueued: true } })
        } else {
          throw err
        }
      }
    }
)

const submitUserSettingsUpdate = createAction('Request user settings update', (update) => async (dispatch) => {
  dispatch(updateUserSettings(update))
  try {
    if (window.navigator.onLine) {
      await api.updateUserSettings(update)
    } else {
      await queueEmployeeUpdate(update)
    }
  } catch (err) {
    if (err.message !== 'Network Error') {
      throw err
    }
    await queueEmployeeUpdate(update)
  }
})

export const disableTracking = createAction('Disable tracking permissions')
export const setTracker = createAction('Store tracker')
export const setCoords = createAction('Store coordinates')
export const setLocationLoading = createAction('Set location loading')

export const enableTracking = createAction('Enable tracking permissions', () => (dispatch) => {
  dispatch(setLocationLoading(true))
  const tracker = window.navigator.geolocation.watchPosition(
    ({ coords: { latitude, longitude, accuracy }, timestamp }) => {
      dispatch(
        setCoords({
          coords: {
            latitude,
            longitude
          },
          timestamp,
          accuracy
        })
      )
    },
    () => {
      dispatch(setLocationLoading(false))
    },
    { enableHighAccuracy: true, maximumAge: 0 }
  )
  dispatch(setTracker(tracker || null))
})

export const refreshTracker = createAction('Refresh tracker', () => (dispatch) => {
  dispatch(disableTracking())
  dispatch(enableTracking())
})

export const updatePosition = createAction('Update user coordinates', () => (dispatch) => {
  dispatch(setLocationLoading(true))
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude, accuracy }, timestamp }) => {
        dispatch(
          setCoords({
            coords: {
              latitude,
              longitude
            },
            accuracy,
            timestamp
          })
        )
        resolve({
          coords: {
            latitude,
            longitude
          },
          accuracy,
          timestamp
        })
      },
      (err) => {
        dispatch(setLocationLoading(false))
        reject(err)
        console.log(err)
      },
      { enableHighAccuracy: true, maximumAge: 60000 }
    )
  })
})

export const setRedirectTo = createAction('Set redirect to')

export const setTrackingPermission = createAction('Set tracking permission', (isPermitted) => async (dispatch) => {
  isPermitted ? dispatch(enableTracking()) : dispatch(disableTracking())
  dispatch(submitUserSettingsUpdate({ trackingAllowed: isPermitted }))
})

export const setEmployeeOngoingCall = createAction('Set employee ongoingCall', (ongoingCall) => async (dispatch) => {
  dispatch(submitUserSettingsUpdate({ ongoingCall }))
})

export const setLanguage = createAction('Set preferred language', (preferredLanguage) => async (dispatch) => {
  dispatch(submitUserSettingsUpdate({ preferredLanguage }))
})

export const setAddress = createAction(
  'Set user address',
  ({ address, latitude, longitude, updateSchedule }) =>
    async (dispatch) => {
      dispatch(submitUserSettingsUpdate({ address, latitude, longitude, updateSchedule }))
    }
)

export const setWorkHours = createAction(
  'Set user work hours',
  ({ updateSchedule, ...workHours }) =>
    async (dispatch) => {
      dispatch(submitUserSettingsUpdate({ workHours, updateSchedule }))
    }
)

export const setNotificationPermission = createAction(
  'Set notification permission',
  (isPermitted) => async (dispatch) => {
    dispatch(submitUserSettingsUpdate({ notificationsAllowed: isPermitted }))
  }
)

async function queueEmployeeUpdate(update) {
  const employeeUpdate = (await outbox.getItem('employee-update')) || {}
  await outbox.setItem('employee-update', { ...employeeUpdate, ...update })
  return registerSync('submit-pending-employee-update')
}

async function queuePasswordResetRequest(email) {
  await outbox.setItem('password-reset-email', email)
  return registerSync('submit-pending-password-reset-request')
}

export const addNewImages = createAction('Add new image to upload')
export const removeImage = createAction('Remove image')

export const saveDelegation = createAction('Save delegation', (startDate, endDate, delegations) => async (dispatch) => {
  const { data } = await api.saveDelegation(startDate, endDate, delegations)
  dispatch(updateUserSettings(data.employee))
})

export const createExtraDashboardToken = createAction(
  'Create Extra Dashboard Token',
  (customerId, lang) => async (dispatch) => {
    const { data } = await api.createExtraDashboardToken(customerId, lang)
    dispatch(setExtraDashboard({ token: data.token }))
  }
)
