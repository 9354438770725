import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import tw from 'twin.macro'

import ExtrahubContext from 'context/ExtrahubContext'
import LangContext from 'context/LangContext'

import { listCustomerGoFunds } from 'store/customers/endpoints'
import { getExtraFundBanner } from 'store/goFundPrograms/endpoints'

import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import RetailerExtraHubCard from 'components/Extrahub/RetailerExtraHubCard'
import Section from 'components/Section'
import Spinner from 'components/Spinner'

const Image = tw.img`rounded-md mt-5`

const ImageRecognitionList = () => {
  const { customerId, contactId } = useContext(ExtrahubContext)
  const { translate } = useContext(LangContext)

  const {
    isLoading,
    error,
    data: programs
  } = useQuery({
    queryKey: ['imageRecognition', customerId],
    queryFn: async () => {
      const { data } = await listCustomerGoFunds(customerId)
      return data || []
    }
  })

  const { isLoading: imageIsLoading, data: currentBannerLink } = useQuery({
    queryKey: ['imageRecognitionBanner', customerId],
    queryFn: async () => {
      const { data } = await getExtraFundBanner()
      return data || []
    }
  })

  if (isLoading) {
    return (
      <Container padded>
        <div className="mt-4 flex h-full w-full items-center justify-center">
          <Spinner icon="spinner" />
        </div>
      </Container>
    )
  }

  if (error) return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />

  return (
    <Section>
      {imageIsLoading ? (
        <div className="flex h-full items-center justify-center pt-5">
          <Spinner icon="spinner" />
        </div>
      ) : currentBannerLink ? (
        <Image src={currentBannerLink} />
      ) : null}

      {programs.length === 0 ? (
        <EmptyState title={translate('app.warn.noPrograms')} subtitle={translate('app.warn.noProgramsSubtitle')} />
      ) : (
        programs.map((program) => <RetailerExtraHubCard key={program.id} program={program} contactId={contactId} />)
      )}
    </Section>
  )
}

export default ImageRecognitionList
