import React, { useContext } from 'react'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'

import Container from 'components/Container'
import GlobalAlert from 'components/GlobalAlert'

import { H3 } from 'styles/typography'

const ErrorContent = ({ error, result }) => {
  const { translate } = useContext(LangContext)

  const errorMessage = error?.response?.data?.message
    ? translate('components.Extrahub.Scan.ErrorContent.invalidImage')
    : translate('components.Extrahub.Scan.ErrorContent.error')

  return (
    <Container padded>
      <GlobalAlert error>
        <H3 className="text-base font-medium text-red-900">
          {translate('components.Extrahub.Scan.ErrorContent.title')}
        </H3>
        <p className="py-2 text-sm text-red-800">{errorMessage}.</p>
        {result?.uploadResult?.status && (
          <p className="text-sm text-red-800">
            {translate(`components.Extrahub.Result.ErrorMessage.${result.uploadResult.status}`)}
          </p>
        )}
      </GlobalAlert>
    </Container>
  )
}

ErrorContent.propTypes = {
  error: object,
  result: object
}

export default ErrorContent
