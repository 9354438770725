import React, { useContext } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { bool, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Icon from 'components/Icon'

import { cn } from 'utils/styling'

const Detail = ({ sentiment, message, details }) => {
  const icon = sentiment ? 'checkmark' : 'close'

  return (
    <div className="flex gap-2 font-medium">
      <div
        className={cn(
          'flex size-6 items-center justify-center rounded-full',
          sentiment ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        )}
      >
        <Icon icon={icon} small />
      </div>
      <div className="flex flex-col gap-1">
        <p className={cn('mt-0.5', sentiment ? 'text-green-900' : 'text-red-800')}>{message}</p>
        {details && (
          <div className="mt-1 w-fit rounded-md bg-slate-50 px-3 py-1.5 text-2xs text-slate-700 ring-1 ring-slate-900/10">
            {details}
          </div>
        )}
      </div>
    </div>
  )
}

Detail.propTypes = {
  sentiment: bool,
  message: string.isRequired,
  details: string
}

const ResultDetails = ({ data, hideValidProducts }) => {
  const { translate } = useContext(LangContext)

  const { aiResult } = data

  return (
    <Accordion.Root collapsible>
      <Accordion.Item value="scan-details">
        <Accordion.Header>
          <Accordion.Trigger className="group flex w-full cursor-pointer items-center justify-between">
            <h3 className="text-xs uppercase leading-none text-slate-500 transition-colors group-hover:text-slate-900 ">
              {translate('components.Extrahub.Result.details')}
            </h3>
            <div className="flex items-center justify-center text-slate-500 transition-all duration-300 group-hover:text-slate-900 group-data-[state=open]:-rotate-180">
              <Icon icon="down-chevron" compact />
            </div>
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content
          asChild
          className="data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown overflow-hidden"
        >
          <div className="space-y-8 py-4">
            <Detail
              sentiment={Boolean(aiResult.invoiceId)}
              message={
                aiResult.invoiceId
                  ? translate('components.Extrahub.Result.Invoice.isInvoice')
                  : translate('components.Extrahub.Result.Invoice.noInvoice')
              }
            />
            {!hideValidProducts && (
              <Detail
                sentiment={Boolean(aiResult.validations?.productMatchesBrand)}
                message={
                  aiResult.validations?.productMatchesBrand
                    ? translate('components.Extrahub.Result.Invoice.foundCampaign')
                    : translate('components.Extrahub.Result.Invoice.didntFoundCampaign')
                }
                details={`${translate('common.scanned')} ${aiResult.validProducts
                  .map((vp) => `${vp.quantity} x ${vp.productName}`)
                  .join(', ')}`}
              />
            )}
            <Detail
              sentiment={Boolean(aiResult.validations?.dateIsValid)}
              message={
                aiResult.validations?.dateIsValid
                  ? translate('components.Extrahub.Result.Invoice.dateValide')
                  : translate('components.Extrahub.Result.Invoice.dateNotValide')
              }
              details={`${translate('common.date')}: ${aiResult.date}`}
            />
            <Detail
              sentiment={Boolean(aiResult.validations?.storeIsValid)}
              message={
                aiResult.validations?.storeIsValid
                  ? translate('components.Extrahub.Result.Invoice.currentStore')
                  : translate('components.Extrahub.Result.Invoice.notCurrentStore')
              }
              details={`${translate('common.address')}: ${aiResult.address}`}
            />
            <Detail
              sentiment={Boolean(aiResult.validations?.invoiceIsUnique)}
              message={
                aiResult.validations?.invoiceIsUnique
                  ? translate('components.Extrahub.Result.Invoice.newInvoice')
                  : translate('components.Extrahub.Result.Invoice.oldInvoice')
              }
              details={`${translate('common.invoice')} #${aiResult.invoiceId}`}
            />
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}

ResultDetails.propTypes = {
  data: object,
  hideValidProducts: bool
}

export default ResultDetails
