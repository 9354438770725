import React, { Fragment, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import { rgba, stripUnit } from 'polished'
import { array, number, object } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import Icon from 'components/Icon'
import Pill from 'components/Pill'

import { MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN, SECTOR_LEVELS, transactionTypes } from 'utils/constants'
import { isCartonBasedActivity, pricify } from 'utils/helpers'

import { borderColor, goBlue, green, greyDark, offWhite, red, white, yellow } from 'styles/colors'
import { borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { H3, smallFontSize, tinyFontSize } from 'styles/typography'

const Container = styled.div`
  position: relative;
  display: block;
  background-color: ${white};
  border-top: 1px solid ${borderColor};
  border-bottom: 1px solid ${borderColor};
  overflow: hidden;

  & + & {
    margin-top: ${spacing.small};

    ${media.breakpoint`
      margin-top: ${spacing.medium};
    `};
  }

  ${media.breakpoint`
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};
  `};
`

const Header = styled.header`
  cursor: ${(props) => (props.isExpandable ? 'pointer' : 'auto')};
  padding: ${spacing.medium};
`

const Content = styled.main`
  background-color: ${rgba(offWhite, 0.5)};
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  border-top: 1px solid ${borderColor};
`

const Section = styled.div`
  padding: ${spacing.medium};

  & + & {
    border-top: 1px solid ${borderColor};
  }
`

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
`

const Title = styled(H3)`
  flex: 1;
  padding-right: ${spacing.small};
  margin-bottom: 0;
`

const SectionTitle = styled.strong`
  display: block;
  font-size: ${smallFontSize};
  color: ${goBlue};
  margin-bottom: ${spacing.small};
`

const Row = styled.div`
  display: flex;
`

const Cell = styled.div`
  position: static;
  font-size: ${tinyFontSize};

  & + & {
    margin-left: ${spacing.large};
  }
`

const Number = styled.strong`
  display: block;
  color: ${greyDark};
`

const Chevron = styled(({ expanded, ...rest }) => Icon(rest))`
  flex-shrink: 0;
  transform: ${(props) => (props.expanded ? 'rotate(180deg)' : 'none')};
`

const PaymentDueWrap = styled.div`
  display: flex;
  align-items: center;
  margin: ${spacing.small} 0;
  font-size: ${tinyFontSize};
`

const Warning = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${spacing.small};
`

function CompletionStatus({ ctnsBooked, targetVolume }) {
  return ctnsBooked >= targetVolume ? (
    <div>
      <Pill color={green}>COMPLETE</Pill>
    </div>
  ) : (
    <div>
      <Pill color={yellow}>INCOMPLETE</Pill>
    </div>
  )
}

CompletionStatus.propTypes = {
  ctnsBooked: number,
  targetVolume: number
}

const Accordion = ({ goFund, programs }) => {
  const { translate } = useContext(LangContext)

  const [expanded, setExpanded] = useState(false)
  const { fetchSector } = useContext(SectorContext)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  const activityIsCartonBased = isCartonBasedActivity(goFund)

  const shouldDisplayStatus = goFund.activity === MINI_VOLUME_GROWTH_PROGRAM_WITH_TARGET_SELL_IN

  const sumPendingApprovals = sumBy(programs, 'pendingApprovals')

  return (
    <Container expanded={expanded}>
      <Header isExpandable={programs && Boolean(programs.length)} onClick={toggleExpanded}>
        <TitleWrap>
          <Title>
            <span>{goFund.fundName}</span>
          </Title>
          {programs && Boolean(programs.length) && <Chevron icon="down-chevron" accent expanded={expanded} />}
        </TitleWrap>
        <div className="flex flex-row gap-2">
          <>
            <Row>
              <Cell>
                <Number>{moment.utc(goFund.endDate).format('MM/DD/YYYY')}</Number>
                <span>{translate('components.GoFundsAccordion.endDate')}</span>
              </Cell>
              <Cell>
                <Number>{pricify(goFund.allocatedBudget)}</Number>
                <span>{translate('components.GoFundsAccordion.allocated')}</span>
              </Cell>
              <Cell>
                <Number>{pricify(goFund.spent)}</Number>
                <span>{translate('components.GoFundsAccordion.spent')}</span>
              </Cell>
            </Row>
            {goFund.reserved && goFund.isExpired && (
              <PaymentDueWrap style={{ color: red }}>
                <Warning icon="warning" small />
                <span>{translate('components.GoFundsAccordion.budgetExpiredMissingPayments')}</span>
              </PaymentDueWrap>
            )}
          </>
          {!!sumPendingApprovals && (
            <div className="flex grow items-center justify-center">
              <div className="w-fit rounded-md bg-slate-50 px-3 py-1.5 text-2xs text-slate-700 ring-1 ring-slate-900/10">
                {translate('components.GoFundsAccordion.approvalsPending')} ({sumPendingApprovals})
              </div>
            </div>
          )}
        </div>
      </Header>
      {programs?.length && (
        <Content expanded={expanded}>
          {programs.map((p) => {
            return (
              <Section key={p.customerId}>
                <Link
                  onClick={() => {
                    fetchSector({ id: p.customerId, type: SECTOR_LEVELS.CUSTOMER })
                  }}
                >
                  <SectionTitle>
                    {p.customer} <small>({p.customerId})</small>
                  </SectionTitle>
                </Link>
                <div className="flex flex-row gap-2">
                  <>
                    <Row>
                      <Cell>
                        <Number>{p.brandFocus}</Number>
                        <span>{translate('components.GoFundsAccordion.brandFocus')}</span>
                      </Cell>
                      <Cell>
                        <Number>{pricify(p.maxPayout)}</Number>
                        <span>{translate('components.GoFundsAccordion.maxPayout')}</span>
                      </Cell>
                      {activityIsCartonBased && (
                        <Cell>
                          <Number>{pricify(p.cartonPayout)}</Number>
                          <span>{translate('components.GoFundsAccordion.cartonPayout')}</span>
                        </Cell>
                      )}
                      {p.finalCost || p.officialPaymentAmount ? (
                        <Fragment>
                          <Cell>
                            <Number>{pricify(p.officialPaymentAmount || p.finalCost)}</Number>
                            <span>{translate('components.GoFundsAccordion.spent')}</span>
                          </Cell>
                          <Cell>
                            <Number>
                              {p.officialPaymentDate
                                ? moment(p.officialPaymentDate.split('T')[0]).format('MM/DD/YYYY')
                                : p.paymentExtractedAt
                                ? moment(p.paymentExtractedAt).format('MM/DD/YYYY')
                                : '-'}
                            </Number>
                            <span>{translate('components.GoFundsAccordion.payDate')}</span>
                          </Cell>
                        </Fragment>
                      ) : (
                        <Cell>
                          <Number>{pricify(p.totalPayments)}</Number>
                          <span>{translate('components.GoFundsAccordion.spent')}</span>
                        </Cell>
                      )}
                      {p.transactionType && (
                        <Cell>
                          <Number>{transactionTypes[p.transactionType]}</Number>
                          <span>{translate('components.GoFundsAccordion.payType')}</span>
                        </Cell>
                      )}
                      {shouldDisplayStatus && (
                        <Cell>
                          <CompletionStatus ctnsBooked={p.ctnsBooked} targetVolume={p.targetVolume} />
                          <span>{translate('components.GoFundsAccordion.status')}</span>
                        </Cell>
                      )}
                    </Row>
                    {!p.finalCost && goFund.isExpired && (
                      <PaymentDueWrap style={{ color: red }}>
                        <Warning icon="warning" small />
                        <span>{translate('components.GoFundsAccordion.paymentNotAvailableDueToBudgetExpiry')}</span>
                      </PaymentDueWrap>
                    )}
                  </>
                  {!!p.pendingApprovals && (
                    <div className="flex grow items-center justify-center">
                      <div className="w-fit rounded-md bg-slate-50 px-3 py-1.5 text-2xs text-slate-700 ring-1 ring-slate-900/10">
                        {p.pendingApprovals} {translate('components.GoFundsAccordion.approvals')}
                      </div>
                    </div>
                  )}
                </div>
              </Section>
            )
          })}
        </Content>
      )}
    </Container>
  )
}

Accordion.propTypes = {
  goFund: object.isRequired,
  programs: array
}

export default Accordion
