import isEmpty from 'lodash/isEmpty'
import snakeCase from 'lodash/snakeCase'

import { addEntities, mergeEntities, setEntities } from 'store/actions'
import { addToSectorReducer } from 'store/cycleCampaigns/reducer'
import * as extraHubReducer from 'store/extraHub/reducer'
import * as inventoryReducer from 'store/inventory/reducer'
import * as priceCaptureCompliance from 'store/priceCaptureCompliance/reducers'
import * as selloutReducer from 'store/Sellout/reducer'

import {
  addAmplifyExtraHubActivityValuesToDistrict,
  addAmplifyExtraHubEngagementValuesToDistrict,
  addAmplifyExtraHubFiltersValuesToDistrict,
  addAmplifyExtraHubGeographyValuesToDistrict,
  addAmplifyExtraHubTrendingValuesToDistrict,
  addAmplifyInventoryOosBrandsValuesToDistrict,
  addAmplifyInventoryOosFiltersValuesToDistrict,
  addAmplifyInventoryOosGeographyValuesToDistrict,
  addAmplifyInventoryOosMaterialValuesToDistrict,
  addAmplifyInventoryOosTrendingValuesToDistrict,
  addAmplifyPriceCaptureComplianceBrandCompletionValuesToDistrict,
  addAmplifyPriceCaptureComplianceFiltersValuesToDistrict,
  addAmplifyPriceCaptureComplianceFootprintValuesToDistrict,
  addAmplifyPriceCaptureComplianceGeographyValuesToDistrict,
  addAmplifyPriceComplianceEdlpComplianceValuesToDistrict,
  addAmplifyPriceComplianceGeographyComplianceValuesToDistrict,
  addAmplifyPriceComplianceGeographyFiltersValuesToDistrict,
  addAmplifyPriceComplianceStrategyComplianceValuesToDistrict,
  addAmplifySelloutFiltersValuesToDistrict,
  addAmplifySelloutGeographyValuesToDistrict,
  addAmplifySelloutValuesToDistrict,
  addCycleCampaignsToDistrict,
  addDistroBrandDataToDistrict,
  addDistroGeographyDataToDistrict,
  addDistroMaterialDataToDistrict,
  addDistroSummaryDataToDistrict,
  addDistroTrendedDataToDistrict,
  addPlanningCycleCampaignValuesToDistrict,
  addPlanningEngagementValuesToDistrict,
  addPlanningHealthCheckValuesToDistrict,
  addPlanningPriceCheckValuesToDistrict,
  addPlanningSellinValuesToDistrict,
  addPlanningSelloutValuesToDistrict,
  addSellinGeographyToDistrict,
  addSellinOrderCompletionToDistrict,
  addSellinRunRateToDistrict
} from './actions'

const ACTION_HANDLERS = {
  [addEntities]: (state, { payload }) => {
    if (!payload.districts) return state

    return Object.entries(payload.districts).reduce((acc, [id, district]) => {
      return Object.assign(acc, {
        [id]: district
      })
    }, Object.assign({}, state))
  },
  [setEntities]: (state, action) => action.payload.districts || state,
  [mergeEntities]: (state, { payload }) => {
    if (!payload.districts) return state
    return Object.entries(payload.districts).reduce((acc, [id, district]) => {
      return Object.assign(acc, {
        [id]: {
          ...(acc[id] || {}),
          ...district
        }
      })
    }, Object.assign({}, state))
  },
  [addSellinRunRateToDistrict]: (
    state,
    { payload: { id, activeProductType, vapeCategory, unitOfMeasure, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-run-rate-${activeProductType}-${vapeCategory}-${unitOfMeasure}`]: result
      }
    })
  },
  [addSellinGeographyToDistrict]: (
    state,
    {
      payload: {
        sectorId: id,
        currentProductType,
        geography,
        period,
        range,
        vapeCategory,
        unitOfMeasure,
        offset,
        result,
        sortBy,
        sortDirection
      }
    }
  ) => {
    const key = `sellin-geography-${currentProductType}-${geography}-${period}-${vapeCategory}-${unitOfMeasure}-${sortBy}-${sortDirection}-${range}-${offset}`
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [key]: result
      }
    })
  },
  [addSellinOrderCompletionToDistrict]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-order-completion`]: result
      }
    })
  },
  [addDistroTrendedDataToDistrict]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, period, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-trended-${activeProductType}-${vapeCategory}-${vapeChannel}-${period}`]: result
      }
    })
  },
  [addDistroBrandDataToDistrict]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result, sortBy, direction, period } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-brand-${activeProductType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}-${period}`]: result
      }
    })
  },
  [addDistroGeographyDataToDistrict]: (
    state,
    {
      payload: {
        sectorId: id,
        productType,
        geography,
        vapeCategory,
        vapeChannel,
        offset,
        filters,
        result,
        sortBy,
        direction,
        period
      }
    }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-geography-${productType}-${geography}-${vapeCategory}-${vapeChannel}-offset-${offset}-${
          filters && !isEmpty(filters)
            ? Object.entries(filters)
                .map(([key, value]) => `${key}-${snakeCase(value)}`)
                .join('-')
            : 'no-filters'
        }-${sortBy}-${direction}-${period}`]: result
      }
    })
  },
  [addDistroMaterialDataToDistrict]: (
    state,
    { payload: { id, activeProductType, vapeCategory, vapeChannel, result, sortBy, direction, period } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-material-${activeProductType}-${vapeCategory}-${vapeChannel}-${sortBy}-${direction}-${period}`]: result
      }
    })
  },
  [addDistroSummaryDataToDistrict]: (
    state,
    { payload: { sectorId: id, productType, vapeCategory, vapeChannel, period, result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`distro-summary-${productType}-${vapeCategory}-${vapeChannel}-${period}`]: result
      }
    })
  },
  [addPlanningSellinValuesToDistrict]: (state, { payload: { id, result, type, vapeCategory, unitOfMeasure } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellin-${type}-${vapeCategory}-${unitOfMeasure}`]: result
      }
    })
  },
  [addPlanningSelloutValuesToDistrict]: (state, { payload: { id, activeProductType, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`sellout-${activeProductType}`]: result
      }
    })
  },
  [addAmplifySelloutValuesToDistrict]: selloutReducer.mergeMain,
  [addAmplifySelloutFiltersValuesToDistrict]: selloutReducer.mergeFilters,
  [addAmplifySelloutGeographyValuesToDistrict]: selloutReducer.mergeGeography,
  [addPlanningHealthCheckValuesToDistrict]: (
    state,
    { payload: { id, productType, result, timeframe, vapeCategory, vapeChannel, period } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`healthcheck-${productType}-${vapeCategory}-timeframe-${timeframe}-${vapeChannel}-${period}`]: result
      }
    })
  },
  [addPlanningPriceCheckValuesToDistrict]: (state, { payload: { id, activeProductType, data } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`pricecheck-${activeProductType}`]: data
      }
    })
  },
  [addPlanningEngagementValuesToDistrict]: (state, { payload: { id, result } }) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        engagement: result
      }
    })
  },

  [addPlanningCycleCampaignValuesToDistrict]: (
    state,
    { payload: { sectorId: id, campaignId, cycleCampaign: result } }
  ) => {
    return Object.assign({}, state, {
      [id]: {
        ...state[id],
        [`planning-cycle-planning-campaign-${campaignId}`]: result
      }
    })
  },
  [addAmplifyInventoryOosTrendingValuesToDistrict]: inventoryReducer.trendingReducer,
  [addAmplifyInventoryOosBrandsValuesToDistrict]: inventoryReducer.brandReducer,
  [addAmplifyInventoryOosMaterialValuesToDistrict]: inventoryReducer.materialReducer,
  [addAmplifyInventoryOosGeographyValuesToDistrict]: inventoryReducer.geographyReducer,
  [addAmplifyInventoryOosFiltersValuesToDistrict]: inventoryReducer.filtersReducer,
  [addAmplifyExtraHubTrendingValuesToDistrict]: extraHubReducer.trendingReducer,
  [addAmplifyExtraHubEngagementValuesToDistrict]: extraHubReducer.engagementReducer,
  [addAmplifyExtraHubFiltersValuesToDistrict]: extraHubReducer.filtersReducer,
  [addAmplifyExtraHubGeographyValuesToDistrict]: extraHubReducer.geographyReducer,
  [addAmplifyExtraHubActivityValuesToDistrict]: extraHubReducer.activityReducer,
  [addAmplifyPriceCaptureComplianceFootprintValuesToDistrict]: priceCaptureCompliance.storeFootprint,
  [addAmplifyPriceCaptureComplianceBrandCompletionValuesToDistrict]: priceCaptureCompliance.brandCompletion,
  [addAmplifyPriceCaptureComplianceFiltersValuesToDistrict]: priceCaptureCompliance.filters,
  [addAmplifyPriceCaptureComplianceGeographyValuesToDistrict]: priceCaptureCompliance.geography,
  [addAmplifyPriceComplianceStrategyComplianceValuesToDistrict]: priceCaptureCompliance.strategyCompliance,
  [addAmplifyPriceComplianceEdlpComplianceValuesToDistrict]: priceCaptureCompliance.edlpCompliance,
  [addAmplifyPriceComplianceGeographyComplianceValuesToDistrict]: priceCaptureCompliance.priceComplianceGeography,
  [addAmplifyPriceComplianceGeographyFiltersValuesToDistrict]: priceCaptureCompliance.priceComplianceGeographyFilters,
  [addCycleCampaignsToDistrict]: addToSectorReducer
}

const initialState = {}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
