import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import * as Accordion from '@radix-ui/react-accordion'
import { arrayOf, func, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomer } from 'store/customers/actions'
import {
  closePerformanceSessionInitialActions,
  customerPrograms,
  incompleteCustomerSellInPrograms,
  incompleteCustomerSurveys
} from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { getNonComplianceForCustomer } from 'store/priceCaptureCompliance/selectors'
import { pendingCustomerPricing } from 'store/pricings/selectors'

import ObjectiveBadge from 'components/close/ObjectiveBadge'
import EmptyState from 'components/EmptyState'
import Icon from 'components/Icon'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const ActionAccordion = ({ category, categoryColor, summary, items }) => {
  return (
    <Accordion.Item value={category}>
      <Accordion.Trigger className="group flex w-full items-center justify-between gap-2">
        <div className="flex gap-1 max-md:flex-col md:items-center">
          <div className="flex shrink-0 justify-start md:w-36">
            <ObjectiveBadge variant={categoryColor}>{category}</ObjectiveBadge>
          </div>
          <span className="text-left font-medium text-slate-900">{summary}</span>
        </div>
        <div className="relative size-5 shrink-0">
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform group-data-[state=open]:rotate-180">
            <Icon icon="down-chevron" compact />
          </div>
        </div>
      </Accordion.Trigger>
      <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
        <ul className="max-h-48 list-disc overflow-y-auto pl-5 text-xs text-slate-900 marker:text-slate-700">
          {items.map((item) =>
            item.removed ? (
              <li key={item.key} style={{ textDecoration: 'line-through' }}>
                {item.name}
              </li>
            ) : (
              <Link key={item.key} to={item.link}>
                <li style={{ textDecoration: 'none' }}>{item.name}</li>
              </Link>
            )
          )}
        </ul>
      </Accordion.Content>
    </Accordion.Item>
  )
}

ActionAccordion.propTypes = {
  category: string.isRequired,
  categoryColor: string.isRequired,
  summary: string.isRequired,
  items: arrayOf(object).isRequired
}

const Actions = ({ getCustomer }) => {
  const { translate } = useContext(LangContext)
  const { sectorId: customerId } = useParams()

  const [error, setError] = useState()

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })

  const dataKeyIsLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const activeSellInPrograms = useSelector((state) => incompleteCustomerSellInPrograms(state, { customerId }))
  const pendingPriceCaptures = useSelector((state) => pendingCustomerPricing(state, { customerId }))
  const nonComplianceForCustomer = useSelector((state) => getNonComplianceForCustomer(state))
  const activeSurveys = useSelector((state) => incompleteCustomerSurveys(state, { customerId }))
  const activeGoFundPograms = useSelector((state) => customerPrograms(state, { customerId })).current

  const sessionInitialActions = useSelector((state) => closePerformanceSessionInitialActions(state, { customerId }))

  const getDifference = (current, initial) => {
    const removed = initial.filter((item) => !current.includes(item))
    return { removed }
  }

  const sellInDifferences = getDifference(activeSellInPrograms, sessionInitialActions.sellin)
  const priceCapturesDifferences = getDifference(pendingPriceCaptures, sessionInitialActions.priceCheck)
  const priceComplianceDifferences = getDifference(nonComplianceForCustomer, sessionInitialActions.priceCompliance)
  const surveysDifferences = getDifference(activeSurveys, sessionInitialActions.surveys)
  const goFundDifferences = getDifference(activeGoFundPograms, sessionInitialActions.extraFunds)

  useEffect(() => {
    if (customerId) {
      setError()
      getCustomer(customerId, dataKey).catch((e) => setError(e))
    }
  }, [customerId])

  const ACTIONS = [
    {
      category: translate('app.sellInPrograms'),
      categoryColor: 'sellIn',
      summary:
        activeSellInPrograms.length > 0
          ? translate('close.objectives.actions.sellIn', { amount: activeSellInPrograms.length })
          : translate('close.objectives.actions.caughtUp'),
      items: sessionInitialActions.sellin.map((program) => ({
        name: program.name,
        link: `/actions/sell-in/${program.id}`,
        key: program.id,
        removed: sellInDifferences.removed.includes(program)
      }))
    },
    {
      category: translate('app.priceCapture'),
      categoryColor: 'priceCheck',
      summary:
        pendingPriceCaptures.length > 0
          ? translate('close.objectives.actions.priceCapture', { amount: pendingPriceCaptures.length })
          : translate('close.objectives.actions.caughtUp'),
      items: sessionInitialActions.priceCheck.map((product) => ({
        name: `Price check needed for ${product.shortBrand}`,
        link: `/actions/pricing/${product.upc}`,
        key: product.upc,
        removed: priceCapturesDifferences.removed.includes(product)
      }))
    },
    {
      category: translate('app.priceCompliance'),
      categoryColor: 'priceCheck',
      summary:
        nonComplianceForCustomer.length > 0
          ? translate('close.objectives.actions.priceCompliance', { amount: nonComplianceForCustomer.length })
          : translate('close.objectives.actions.caughtUp'),
      items: sessionInitialActions.priceCompliance.map((product) => ({
        name: product.conditionName || product.benchmarkName || '-',
        link: '/pace/amplify/price-compliance',
        key: product.benchmarkName,
        removed: priceComplianceDifferences.removed.includes(product)
      }))
    },
    {
      category: translate('app.surveys'),
      categoryColor: 'survey',
      summary:
        activeSurveys.length > 0
          ? translate('close.objectives.actions.surveys', { amount: activeSurveys.length })
          : translate('close.objectives.actions.caughtUp'),
      items: sessionInitialActions.surveys.map((program) => ({
        name: program.survey.name,
        link: `/actions/surveys/${program.survey.id}`,
        key: program.survey.id,
        removed: surveysDifferences.removed.includes(program)
      }))
    },
    {
      category: translate('app.extraFunds'),
      categoryColor: 'extraFunds',
      summary:
        activeGoFundPograms.length > 0
          ? translate('close.objectives.actions.extraFunds', { amount: activeGoFundPograms.length })
          : translate('close.objectives.actions.caughtUp'),
      items: sessionInitialActions.extraFunds.map((program) => ({
        name: program.goFundBudget,
        link: '/actions/funds',
        key: program.id,
        removed: goFundDifferences.removed.includes(program)
      }))
    }
  ]

  const emptyState =
    sessionInitialActions.sellin.length === 0 &&
    sessionInitialActions.priceCheck.length === 0 &&
    sessionInitialActions.priceCompliance.length === 0 &&
    sessionInitialActions.surveys.length === 0 &&
    sessionInitialActions.extraFunds.length === 0

  if (error) return <EmptyState title={getErrorMessage(error)} />

  if (dataKeyIsLoading) return <WrappedSpinner icon="spinner" />

  if (emptyState) return <EmptyState title={translate('close.objectives.actions.noActions')} />

  return (
    <Accordion.Root type="single" collapsible className="flex flex-col gap-4 md:gap-2">
      {ACTIONS.map((action) => {
        if (action.items.length === 0) return null

        return <ActionAccordion key={action.category} {...action} />
      })}
    </Accordion.Root>
  )
}

Actions.propTypes = {
  getCustomer: func
}

export default connect(null, {
  getCustomer
})(Actions)
