import React, { useContext, useEffect, useState } from 'react'
import orderBy from 'lodash/orderBy'
import { stripUnit } from 'polished'
import { array, bool, func } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'
import PriceCaptureContext from 'context/PriceCaptureContext'

import Icon from 'components/Icon'
import Status from 'components/Status'

import { SORTING_DIRECTION } from 'utils/constants'
import { sortDataTableBy } from 'utils/helpers'
import { cn } from 'utils/styling'

import { borderColor, greyDark, offWhite } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { fontSemibold, smallFontSize } from 'styles/typography'

const Table = styled.table`
  position: relative;
  text-align: left;
  width: 100%;
  margin-top: ${spacing.small};

  th {
    color: ${greyDark};
    font-weight: ${fontSemibold};
    font-size: ${smallFontSize};
    padding-top: 0;
    top: 104px;
    background-color: ${offWhite};
    cursor: pointer;
  }

  th,
  td {
    padding: ${stripUnit(spacing.small) * 1.5 + 'px'};
    border-bottom: 1px solid ${borderColor};

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`

const PriceCaptureTableRow = styled.tr`
  position: static;

  td {
    vertical-align: baseline;
  }
`

const SORTABLE_COLUMNS = {
  SHORTBRAND: 'shortBrand',
  PRODUCT: 'productName',
  STATUS: 'status'
}

const PriceCaptureTable = ({ pricings, renderRow, isRetailer }) => {
  const { translate } = useContext(LangContext)
  const { sortData, setSortData } = useContext(PriceCaptureContext)
  const [sortablePricings, setSortablePricings] = useState(pricings)

  useEffect(() => {
    setSortablePricings(
      orderBy(pricings, [(pricing) => (pricing[sortData.column] || '').toLowerCase()], [sortData.order])
    )
  }, [pricings])

  if (!sortData) return null

  const renderChevron = () => (
    <Icon
      icon="down-chevron"
      compact
      className={cn('w-full shrink-0', sortData.order === SORTING_DIRECTION.ASC && 'rotate-180')}
    />
  )

  const sortBy = (column) => {
    const newSortData = sortDataTableBy(column, sortData)
    setSortablePricings(
      orderBy(sortablePricings, [(pricing) => (pricing[newSortData.column] || '').toLowerCase()], [newSortData.order])
    )
    setSortData(newSortData)
  }

  return (
    <Table>
      <thead>
        <tr>
          <th onClick={() => sortBy(SORTABLE_COLUMNS.PRODUCT)}>
            {translate('common.product')}
            {sortData.column === SORTABLE_COLUMNS.PRODUCT && renderChevron()}
          </th>
          <th onClick={() => sortBy(SORTABLE_COLUMNS.SHORTBRAND)}>
            {translate('app.strategyName')}
            {sortData.column === SORTABLE_COLUMNS.SHORTBRAND && renderChevron()}
          </th>
          {isRetailer && (
            <>
              <th onClick={() => sortBy(SORTABLE_COLUMNS.STATUS)}>
                <Status status="noStatus" label={translate('common.priceBeforeTax')} />
                {sortData.column === SORTABLE_COLUMNS.STATUS && renderChevron()}
              </th>
              <th>{translate('common.date')}</th>
            </>
          )}
          {!isRetailer && (
            <>
              <th onClick={() => sortBy(SORTABLE_COLUMNS.STATUS)}>
                {translate('app.TMPriceBeforeTax')}
                {sortData.column === SORTABLE_COLUMNS.STATUS && renderChevron()}
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {sortablePricings.map((pricing, index) => {
          return <PriceCaptureTableRow key={index}>{renderRow(pricing)}</PriceCaptureTableRow>
        })}
      </tbody>
    </Table>
  )
}
PriceCaptureTable.propTypes = {
  pricings: array.isRequired,
  renderRow: func.isRequired,
  isRetailer: bool
}

export default PriceCaptureTable
