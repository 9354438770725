import { createAction } from 'redux-actions'

import { AMPLIFY_SELECTOR_ACTIONS, PLANNING_SELECTOR_ACTIONS } from 'store/actions'
import { setDataFetch } from 'store/dataFetches/actions'

import { DATA_UPDATE_STATUS } from 'utils/constants'
import { dataIsStillValid, ERRORS, runStressTest, validateStoreDataKey } from 'utils/helpers'

import * as api from './endpoints'

export const fetchPlanningSellout = createAction(
  'Get Planning Sell-out Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      const { data } = await api.fetchPlanningSellout(props)
      const addResultToScope = PLANNING_SELECTOR_ACTIONS[props.sectorLevel].sellout
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )

      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))

      // STRESS TEST - TO REMOVE
      runStressTest(() => api.fetchPlanningSellout(props))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchAmplifySellout = createAction(
  'Get Amplify Sell-out Values',
  (props, dataKey) => async (dispatch, getState) => {
    const dataFetchesState = getState().dataFetches

    if (dataIsStillValid(dataFetchesState, dataKey)) return
    try {
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.LOADING }))
      if (!window.navigator.onLine) throw ERRORS.offline
      const { data } = await api.fetchAmplifySellout(props)
      const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout.main
      dispatch(
        addResultToScope({
          ...data,
          ...props
        })
      )

      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.OVER }))

      // STRESS TEST - TO REMOVE
      runStressTest(() => api.fetchAmplifySellout(props))
    } catch (e) {
      console.error(e)
      dispatch(setDataFetch({ dataKey, status: DATA_UPDATE_STATUS.ERROR, error: e }))
      throw e
    }
  }
)

export const fetchAmplifySelloutGeographyData = createAction(
  'Get Amplify Sell-out Geography Values',
  ({ filters, ...props }, dataKey) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        if (!window.navigator.onLine) throw ERRORS.offline
        const { data } = await api.fetchAmplifySelloutGeographyData({ ...filters, ...props })
        const addResultToScope = AMPLIFY_SELECTOR_ACTIONS[props.sectorLevel].sellout.geography

        dispatch(
          addResultToScope({
            ...data,
            ...props,
            filters
          })
        )
      })
)

export const fetchAmplifySelloutFiltersOptions = createAction(
  'Fetch Sell Out Filters Options',
  ({ dataKey, sectorType, sectorId, productType, channel }) =>
    async (dispatch, getState) =>
      validateStoreDataKey(getState(), dispatch, dataKey, async () => {
        const { data: filters } = await api.fetchAmplifySelloutFiltersOptions(
          sectorType,
          sectorId,
          productType,
          channel
        )

        const action = AMPLIFY_SELECTOR_ACTIONS[sectorType].sellout?.filters
        dispatch(
          action({
            channel,
            productType,
            id: sectorId,
            filters
          })
        )
      })
)
