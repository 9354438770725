import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { func, node, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { fetchCloseSoundbites } from 'store/close/actions'
import { activateClosePerformanceSession } from 'store/customers/actions'
import {
  closePerformanceSessionActivated,
  closePerformanceSessionStartedAt,
  customerPrograms,
  incompleteCustomerSellInPrograms,
  incompleteCustomerSurveys
} from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { getNonComplianceForCustomer } from 'store/priceCaptureCompliance/selectors'
import { pendingCustomerPricing } from 'store/pricings/selectors'

import Button from 'components/button/Button'
import FabButton from 'components/button/FabButton'
import ErrorMessage from 'components/ErrorMessage'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import Actions from './Actions'
import PerformanceSoundbites from './PerformanceSoundbites'
import Reminders from './Reminders'

const Section = ({ title, icon, children }) => {
  return (
    <div className="flex max-w-full flex-col gap-3">
      <div className="flex items-center justify-center gap-1.5 rounded-md bg-brand-50 p-2 text-slate-500">
        <Icon icon={icon} compact />
        <h3 className="text-sm font-medium uppercase ">{title}</h3>
      </div>
      <div className="flex flex-col gap-3 text-sm">{children}</div>
    </div>
  )
}

Section.propTypes = {
  title: string.isRequired,
  icon: string.isRequired,
  children: node.isRequired
}

const OpportunitiesModal = ({ activateClosePerformanceSession, fetchCloseSoundbites }) => {
  const { translate } = useContext(LangContext)
  const { sectorId: customerId } = useParams()

  const [sessionError, setSessionError] = useState()
  const [performanceSoundbitesError, setPerformanceSoundbitesError] = useState()
  const [isOpportunitiesModalOpen, setIsOpportunitiesModalOpen] = useState(false)

  const dataKey = createDataKey(DATAKEY_TYPES.CLOSE_SOUNDBITES, {
    customerId
  })

  const activeSellInPrograms = useSelector((state) => incompleteCustomerSellInPrograms(state, { customerId }))
  const pendingPriceCaptures = useSelector((state) => pendingCustomerPricing(state, { customerId }))
  const nonComplianceForCustomer = useSelector((state) => getNonComplianceForCustomer(state))
  const activeSurveys = useSelector((state) => incompleteCustomerSurveys(state, { customerId }))
  const activeGoFundPograms = useSelector((state) => customerPrograms(state, { customerId })).current
  const dataKeyIsLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  const isCloseOpportunitiesPage = location.pathname.includes('/pace/close/close-opportunities')

  const closePerformanceSessionIsActive = useSelector((state) =>
    closePerformanceSessionActivated(state, { customerId })
  )

  const closePerformanceSessionStartTime = useSelector((state) =>
    closePerformanceSessionStartedAt(state, { customerId })
  )

  const hasValidSession =
    closePerformanceSessionIsActive && moment(closePerformanceSessionStartTime).isAfter(moment().startOf('day'))

  useEffect(() => {
    if (!hasValidSession && closePerformanceSessionIsActive) {
      activateClosePerformanceSession({
        customerId,
        closePerformanceSessionisActive: false,
        sessionInitialActions: null
      }).catch((error) => setSessionError(getErrorMessage(error)))
    }
  }, [hasValidSession, closePerformanceSessionIsActive])

  useEffect(() => {
    if (!hasValidSession && isCloseOpportunitiesPage) {
      const sessionInitialActions = {
        sellin: activeSellInPrograms,
        priceCheck: pendingPriceCaptures,
        priceCompliance: nonComplianceForCustomer,
        surveys: activeSurveys,
        extraFunds: activeGoFundPograms
      }

      fetchCloseSoundbites({ customerId, dataKey }).catch((error) =>
        setPerformanceSoundbitesError(getErrorMessage(error))
      )

      activateClosePerformanceSession({
        customerId,
        closePerformanceSessionisActive: true,
        sessionInitialActions
      }).catch((error) => sessionError(getErrorMessage(error)))

      setIsOpportunitiesModalOpen(true)
    }
  }, [hasValidSession, isCloseOpportunitiesPage])

  if (!hasValidSession) return

  return (
    <Modal
      size="xLarge"
      trigger={<FabButton icon="list" />}
      footer={
        <div className="flex w-full justify-end">
          <Button primary icon="checkmark" onClick={() => setIsOpportunitiesModalOpen(false)}>
            {translate('common.okay')}
          </Button>
        </div>
      }
      title={translate('close.objectives.title')}
      open={isOpportunitiesModalOpen}
      onOpenChange={setIsOpportunitiesModalOpen}
    >
      <div className="grid max-w-full gap-x-5 gap-y-8 md:grid-cols-2">
        <ErrorMessage>{sessionError}</ErrorMessage>
        <div className="min-w-0 max-w-full md:col-span-2">
          <Section title="Performance Soundbites" icon="chart">
            <PerformanceSoundbites error={performanceSoundbitesError} dataKeyIsLoading={dataKeyIsLoading} />
          </Section>
        </div>
        <Section title={translate('common.actions')} icon="actions">
          <Actions />
        </Section>
        <Section title={translate('common.reminders')} icon="notification">
          <Reminders />
        </Section>
      </div>
    </Modal>
  )
}

OpportunitiesModal.propTypes = {
  activateClosePerformanceSession: func,
  fetchCloseSoundbites: func
}

export default connect(null, { activateClosePerformanceSession, fetchCloseSoundbites })(OpportunitiesModal)
