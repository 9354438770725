import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Accordion from '@radix-ui/react-accordion'
import { Field, useFormikContext } from 'formik'
import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'
import { array, node, number, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { customerProductDrivers } from 'store/customers/selectors'

import CloseRecommendation from 'components/close/CloseRecommendation'
import Icon from 'components/Icon'
import { Modal } from 'components/Modal'
import NumberInput from 'components/NumberInput'

import { formatCompactCurrency } from 'utils/formatters'
import { normalizeString } from 'utils/helpers'
import { cn } from 'utils/styling'

const DriversModal = ({ brandName, drivers }) => {
  const { translate } = useContext(LangContext)

  const [driversModalOpen, setDriversModalOpen] = useState(false)

  return (
    <Modal
      trigger={
        <div
          className="flex size-6 items-center justify-center rounded-full text-slate-500 transition-colors duration-200 hover:bg-slate-900/10 hover:text-slate-900"
          onClick={(e) => {
            e.stopPropagation()
            setDriversModalOpen((prevState) => ({
              ...prevState,
              [brandName]: true
            }))
          }}
        >
          <Icon icon="info-outline" small />
        </div>
      }
      title={translate('close.recommendations.drivers')}
      subtitle={translate('close.recommendations.driversDescription')}
      open={driversModalOpen[brandName]}
      onOpenChange={(isOpen) => {
        setDriversModalOpen((prevState) => ({
          ...prevState,
          [brandName]: isOpen
        }))
      }}
    >
      <div className="flex flex-col gap-3">
        {!drivers.length ? (
          <p className="rounded-md bg-slate-50 p-3 py-6 text-center text-sm text-slate-500">
            {translate('close.recommendations.noDriversFound')}
          </p>
        ) : (
          drivers.map((driver) => (
            <CloseRecommendation key={driver.id} type={driver.type} recommendation={driver.message} />
          ))
        )}
      </div>
    </Modal>
  )
}

DriversModal.propTypes = {
  brandName: string.isRequired,
  drivers: array.isRequired
}

const CheckoutTable = ({ productData, recoQtyPerType }) => {
  const { translate } = useContext(LangContext)
  const { sectorId: customerId } = useParams()

  const { productBrandDrivers } = useSelector((state) => customerProductDrivers(state, { customerId }))

  const [open, setOpen] = useState('FMC')
  const { setFieldValue, values } = useFormikContext()

  const productsInType = productData.map((pd) => pd.data).flat()
  const totalQtyPerType = productsInType.reduce((sum, product) => sum + values.entries[product.id], 0)
  const totalValuePerType = productsInType.reduce(
    (sum, product) => sum + values.entries[product.id] * product.basePriceInDollars,
    0
  )

  const totalTop80ProductForType = sumBy(productData, 'totalTop80ProductsInBrand')
  const totalAwr4ForType = sumBy(productData, 'totalAwr4ForBrand')

  const handleAccessibleKeyDown = (e, name) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      setOpen(name)
    }
  }

  const groupedDrivers = groupBy(productBrandDrivers, (driver) => normalizeString(driver.brand))

  return (
    <Accordion.Root type="single" collapsible onValueChange={setOpen} value={open} asChild>
      <table className="min-w-full table-fixed">
        <thead className="text-left">
          <tr className="h-6 bg-slate-50 text-2xs uppercase text-slate-500 *:whitespace-nowrap *:pr-4 *:font-medium">
            <th></th>
            <th>{translate('common.product')}</th>
            <th>Top 80</th>
            <th>AWR4</th>
            <th>{translate('common.recoQty')}</th>
            <th>{translate('common.qty')}</th>
            <th className="text-right">{translate('common.value')}</th>
          </tr>
        </thead>
        <tbody>
          {productData.map((brand) => {
            const recoQtyPerBrand = brand.data.reduce((total, product) => total + (product.recommendedQty || 0), 0)
            const totalQtyPerBrand = brand.data.reduce((sum, product) => sum + values.entries[product.id], 0)
            const totalValuePerBrand = brand.data.reduce(
              (sum, product) => sum + values.entries[product.id] * product.basePriceInDollars,
              0
            )

            const driversForBrand = groupedDrivers[normalizeString(brand.name)] || []

            return (
              <Accordion.Item key={brand.name} value={brand.name} asChild>
                <>
                  <Accordion.Trigger className="group" onClick={() => setOpen(brand.name)} asChild>
                    <tr
                      tabIndex="0"
                      onClick={() => setOpen(brand.name)}
                      onKeyDown={(e) => handleAccessibleKeyDown(e, brand.name)}
                      className="h-10 cursor-pointer border-t font-medium text-slate-900 transition-colors duration-200 *:pr-4 hover:bg-slate-50"
                    >
                      <td className="size-6 rounded-tl-md pl-2 pr-3">
                        <div className="size-6 -rotate-90 text-slate-500 transition-transform group-data-[state=open]:rotate-0">
                          <Icon icon="down-chevron" />
                        </div>
                      </td>
                      <td className="flex h-10 min-w-56 items-center gap-1">
                        <span>{brand.name}</span>
                        {driversForBrand.length > 0 && (
                          <DriversModal brandName={brand.name} drivers={driversForBrand} />
                        )}
                      </td>
                      <td className="text-slate-300">{brand.totalTop80ProductsInBrand}</td>
                      <td className="text-slate-300">{brand.totalAwr4ForBrand.toFixed(2)}</td>
                      <td className={cn('', recoQtyPerBrand === 0 ? 'text-slate-500' : 'text-slate-900')}>
                        {recoQtyPerBrand}
                      </td>
                      <td className={cn('min-w-20', totalQtyPerBrand === 0 ? 'text-slate-300' : 'text-slate-900')}>
                        {totalQtyPerBrand === 0 ? '-' : totalQtyPerBrand}
                      </td>
                      <td
                        className={cn(
                          'min-w-20 text-right',
                          totalValuePerBrand === 0 ? 'text-slate-300' : 'text-slate-900'
                        )}
                      >
                        {formatCompactCurrency(totalValuePerBrand)}
                      </td>
                    </tr>
                  </Accordion.Trigger>
                  <Accordion.Content asChild className="group">
                    <>
                      <tr className="h-3 border-t" />
                      {brand.data.map((product, productIndex) => {
                        const rows = brand.data.length
                        const isLastRow = productIndex + 1 === rows

                        const outOfStock = product.outOfStock

                        return (
                          <tr key={productIndex} id="sub-row" className={cn('h-8 *:pr-4', isLastRow && 'pb-4')}>
                            <td></td>
                            <td>
                              <p className={cn('whitespace-nowrap text-slate-700', outOfStock && 'opacity-60')}>
                                {product.englishName}
                              </p>

                              {outOfStock && (
                                <p className=" text-xs text-red-500 !opacity-100">
                                  {translate('components.accordion.SAQAccordion.OSSMessage')}
                                </p>
                              )}
                            </td>

                            <td className={cn(product.top80 ? 'text-slate-700' : 'text-slate-300')}>
                              {product.top80 ? <Icon icon="checkmark-small" compact /> : '-'}
                            </td>
                            <td className="text-slate-700">{product.awr4.toFixed(2)}</td>
                            <td className="flex h-8 items-center gap-0.5 text-slate-700">
                              <p>{product.recommendedQty}</p>
                              <div className="mb-0.5 size-5 ">
                                <div
                                  className={cn(
                                    'text-accents-green',
                                    product.recommendedQty !== product.qty && 'hidden'
                                  )}
                                >
                                  <Icon icon="checkmark-small" compact />
                                </div>
                              </div>
                            </td>
                            <td className="!px-0">
                              <div className="max-w-fit">
                                <Field
                                  name={`entries.${product.id}`}
                                  onChange={(val) => setFieldValue(`entries.${product.id}`, val)}
                                  component={NumberInput}
                                  value={values.entries[product.id]}
                                  disabled={outOfStock}
                                />
                              </div>
                            </td>
                            <td
                              className={cn(
                                'min-w-20 text-right',
                                values.entries[product.id] === 0 ? 'text-slate-300' : 'text-slate-900'
                              )}
                            >
                              {formatCompactCurrency(values.entries[product.id] * product.basePriceInDollars)}
                            </td>
                          </tr>
                        )
                      })}
                      <tr className="h-3" />
                    </>
                  </Accordion.Content>
                </>
              </Accordion.Item>
            )
          })}
        </tbody>
        <thead className="text-left">
          <tr className="sticky h-10 border-t text-slate-900 *:whitespace-nowrap *:pr-4 *:font-medium">
            <th></th>
            <th>{translate('common.total')}</th>
            <th className="text-slate-300">{totalTop80ProductForType}</th>
            <th className="text-slate-300">{totalAwr4ForType.toFixed(2)}</th>
            <th className="flex h-10 items-center gap-0.5 text-slate-700">
              <p>{recoQtyPerType}</p>
              <div className="mb-0.5 size-5 ">
                <div className={cn('text-accents-green', recoQtyPerType !== totalQtyPerType && 'hidden')}>
                  <Icon icon="checkmark-small" compact />
                </div>
              </div>
            </th>
            <th className={cn(totalQtyPerType === 0 ? 'text-slate-500' : 'text-slate-900')}>
              {totalQtyPerType || '-'}
            </th>
            <th className={cn('min-w-20 text-right', totalValuePerType === 0 ? 'text-slate-500' : 'text-slate-900')}>
              {formatCompactCurrency(totalValuePerType)}
            </th>
          </tr>
        </thead>
      </table>
    </Accordion.Root>
  )
}

CheckoutTable.propTypes = {
  productData: node.isRequired,
  recoQtyPerType: number
}

export default CheckoutTable
