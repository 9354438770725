import omit from 'lodash/omit'
import pick from 'lodash/pick'
import startCase from 'lodash/startCase'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment-timezone'
import { denormalize } from 'normalizr'
import { mix } from 'polished'
import { createSelector } from 'reselect'

import { customerIdFromProps } from 'store/customers/selectors'

import { CALL_CUSTOMER_ATTRS } from 'utils/constants'
import { getAddressString } from 'utils/helpers'

import { black, calendarEventColors } from 'styles/colors'

import { customerCalls as customerCallsSchema } from './schema'

const fullState = (state) => state
const allCustomers = (state) => omit(state.customers, '_persist')
const callsFromState = (state) => omit(state.calls, '_persist')

export const allCustomerCalls = createSelector(callsFromState, allCustomers, (calls, customers) => {
  return Object.values(calls).map((call) => ({
    ...call,
    customer: customers[call.customerId]
  }))
})

export const urlCustomerWithCalls = createSelector(
  customerIdFromProps,
  allCustomers,
  fullState,
  (customerId, customers, state) => {
    if (!customerId) return null
    return denormalize(customers[customerId], customerCallsSchema, state)
  }
)

export const callLogsCustomer = createSelector(urlCustomerWithCalls, (customer) => {
  return pick(customer, CALL_CUSTOMER_ATTRS)
})

function getBorderColor(call) {
  // If a call is completed, and it is and adhoc call, the border should be pink
  if (call.callEnd && call.isAdhocCall) return calendarEventColors.adhoc
  if (!call.generatedCallId) return

  const callStart = call.callStart || call.scheduledStart
  if (!callStart || !call.generatedStart) return

  const laterStart = moment.max([moment(callStart), moment(call.generatedStart)])
  const earlierStart = moment.min([moment(callStart), moment(call.generatedStart)])
  if (moment(laterStart).diff(moment(earlierStart), 'minutes') >= 1) {
    return calendarEventColors.moved
  }
  return calendarEventColors.generatedCalls
}

function getColor(call, callType) {
  if (call.callEnd) {
    if (call.loggedBy === 'USER') {
      return calendarEventColors[`${callType}Past`]
    } else {
      return calendarEventColors[`${callType}Fulfilled`]
    }
  }
  if (call.scheduledEnd && moment().isAfter(call.scheduledEnd)) {
    return calendarEventColors.missed
  } else {
    if (call.generatedCallId) {
      return calendarEventColors.generatedCalls
    }
    return calendarEventColors.future
  }
}

export const calendarEventFromCall = (call) => {
  if (!call) return
  const callType = call.type || call.callType || call.scheduledType
  const color = getColor(call, callType)
  const borderColor = getBorderColor(call)
  const summary = call.customer?.name
    ? `${call.customer.name} (${call.customer.id}) ${getAddressString(call.customer.address, [
        'line1',
        'city',
        'state'
      ])}`
    : startCase(callType)

  const eventType = call.callEnd ? (call.loggedBy === 'USER' ? 'past-call' : 'completed-call') : 'scheduled-call'

  const startAt = call.callStart || call.scheduledStart
  const endAt = call.callEnd || call.scheduledEnd

  let overrideEndDate
  const dayDiff = moment(endAt).diff(moment(startAt), 'days')
  if (dayDiff) {
    overrideEndDate = moment(endAt).subtract(dayDiff, 'days').toISOString()
  }

  if (!startAt || !endAt || moment(endAt).isSameOrBefore(startAt)) return null

  const calendEvent = {
    id: call.id,
    start: startAt,
    end: dayDiff ? overrideEndDate : endAt,
    title: dayDiff ? `${summary} - Ends on ${moment(endAt).format('MMM DD')}` : summary,
    callCompleted: Boolean(call.callEnd),
    backgroundColor: color || 'blue',
    borderColor: borderColor || mix(0.3, black, color),
    eventType,
    eventDisplay: 'block',
    callDetails: call
  }

  return calendEvent
}

export const calendarCallsForCustomer = createSelector(urlCustomerWithCalls, (customer) => {
  if (!customer?.calls) return []
  return customer.calls.map(calendarEventFromCall).filter(Boolean)
})

export const allCalendarCalls = createSelector(allCustomerCalls, allCustomers, (calls, allCustomers) => {
  if (!calls?.length) return []
  const callsToDisplay = calls
    .filter((c) => {
      return c.callStart || c.scheduledStart
    })
    .filter((c) => {
      return c.callEnd || c.scheduledEnd
    })
    .map(({ customerId, ...call }) => {
      const customer = customerId && allCustomers[customerId]
      return calendarEventFromCall({
        ...call,
        customer: pick(customer, CALL_CUSTOMER_ATTRS)
      })
    })
    .filter(Boolean)
  return uniqBy(callsToDisplay, 'id')
})
