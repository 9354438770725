import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { getCustomerAssortment, getCustomerProductDrivers } from 'store/customers/actions'
import { customerProductDrivers, recommendedQtyByTypeForCustomer } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import EmptyState from 'components/EmptyState'
import CheckForValidScope from 'components/guards/CheckForValidScope'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import FMCOpportunityCard from './FMCOpportunityCard'
import TotalOpportunitiesCard from './TotalOpportunitiesCard'
import VAPEOpportunityCard from './VAPEOpportunityCard'

const OpportunitiesView = ({ getCustomerAssortment, getCustomerProductDrivers }) => {
  const { sectorId: customerId } = useParams()
  const { translate } = useContext(LangContext)

  const customerAssortmentDataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_ASSORTMENT, { customerId })
  const productDriversDataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_PRODUCT_DRIVERS, { customerId })

  const isLoadingCustomerAssortment = useSelector((state) => isDataKeyLoading(state, { customerAssortmentDataKey }))
  const isLoadingProductDrivers = useSelector((state) => isDataKeyLoading(state, { customerAssortmentDataKey }))
  const recommendedQtyByType = useSelector((state) => recommendedQtyByTypeForCustomer(state, { customerId }))

  const { fmcProductTypeDrivers, vapeProductTypeDrivers } = useSelector((state) =>
    customerProductDrivers(state, { customerId })
  )

  const [error, setError] = useState()

  const validScopes = [SECTOR_LEVELS.CUSTOMER]

  useEffect(() => {
    document.title = `${translate('common.close')} - ${translate('close.closeOpportunities')}`
  }, [])

  useEffect(() => {
    if (customerId) {
      setError()
      getCustomerAssortment({ customerId, customerAssortmentDataKey }).catch((error) => {
        setError(getErrorMessage(error))
      })
      getCustomerProductDrivers({ customerId, productDriversDataKey }).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [customerId])

  if (error) return <EmptyState title={getErrorMessage(error)} />

  if (isLoadingCustomerAssortment || isLoadingProductDrivers) return <WrappedSpinner icon="spinner" />

  return (
    <CheckForValidScope pageName={translate('close.closeOpportunities')} validScopes={validScopes}>
      <div className="grid w-full grid-cols-12 gap-5 max-md:pt-20">
        <FMCOpportunityCard
          span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}
          recommendedQtyByType={recommendedQtyByType.fmc}
          productTypeDrivers={fmcProductTypeDrivers}
        />
        <VAPEOpportunityCard
          span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}
          recommendedQtyByType={recommendedQtyByType.vape}
          productTypeDrivers={vapeProductTypeDrivers}
        />
        <TotalOpportunitiesCard
          span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}
          recommendedQtyByType={recommendedQtyByType}
        />
      </div>
    </CheckForValidScope>
  )
}

OpportunitiesView.propTypes = {
  getCustomerAssortment: func,
  getCustomerProductDrivers: func
}

export default connect(null, { getCustomerAssortment, getCustomerProductDrivers })(OpportunitiesView)
