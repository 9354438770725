/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import { func, object } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { fetchCustomerGoFunds } from 'store/customers/actions'
import { approveReceiptPayment, rejectReceiptPayment } from 'store/goFundPrograms/endpoints'
import { programFromUrl } from 'store/goFundPrograms/selectors'

import BasicAccordion from 'components/accordion/BasicAccordion'
import ImageAccordion from 'components/accordion/ImageAccordion'
import Button from 'components/button/Button'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import ListReceipts from 'components/Extrahub/ListReceipts'
import ReceiptModal from 'components/Extrahub/ReceiptModal'
import Fieldset from 'components/fieldset'
import FieldsetItem from 'components/fieldset/FieldsetItem'
import GlobalAlert from 'components/GlobalAlert'
import Label from 'components/Label'
import NumberInput from 'components/NumberInput'
import ProgramCard from 'components/ProgramCard'
import { WrappedSpinner } from 'components/Spinner'

import { FMC_CONVERSION, GO_FUND_PAYMENT_TYPES, GO_PROGRAM_RECEIPT_STATUS, SECTOR_LEVELS } from 'utils/constants'

import { deepBlue, goBlue, white } from 'styles/colors'

const Table = styled.table`
  width: 100%;

  td,
  th {
    border: 1px solid ${goBlue};
    padding: 8px;
    font-size: 12px;
    position: relative;
    background-color: ${white};

    @media print {
      padding: 3px;
      font-size: 8px;
    }
  }

  th {
    color: ${goBlue};
    text-align: left;
    -webkit-print-color-adjust: exact;
  }
`

const PrimaryButton = styled(Button)`
  color: ${deepBlue};
  border: 1px solid ${deepBlue};
  background-color: ${white};
`

const SecondaryButton = styled(Button)`
  color: ${white};
  background-color: ${deepBlue};
`

const ActionButtons = ({ currentUrl, program }) => {
  const { translate } = useContext(LangContext)
  const { actionButtons, activeTarget, isAiProgram } = program.programCardInfos

  if (isAiProgram && actionButtons.canPayGoFund) {
    return (
      <FieldsetItem>
        <Button full primary icon="document-scanner" to={`${currentUrl.pathname}/scan`} offlineDisabled>
          {translate('components.Extrahub.ExtraHubCard.payNowButton')}
        </Button>
      </FieldsetItem>
    )
  }

  return (
    <>
      {actionButtons.canPayGoFund && (
        <>
          <FieldsetItem half={actionButtons.canUpdateCartonSales}>
            <SecondaryButton full to={`${currentUrl.pathname}/payment`} offlineDisabled>
              Pay now
            </SecondaryButton>
          </FieldsetItem>
          {actionButtons.canUpdateCartonSales && (
            <FieldsetItem half>
              <SecondaryButton full to={`${currentUrl.pathname}/carton`} offlineDisabled>
                Update carton sales
              </SecondaryButton>
            </FieldsetItem>
          )}
        </>
      )}

      {actionButtons.canAddCartonsTarget ? (
        <FieldsetItem>
          <PrimaryButton to={`${currentUrl.pathname}/target`} full offlineDisabled>
            Add cartons target
          </PrimaryButton>
        </FieldsetItem>
      ) : activeTarget ? (
        <FieldsetItem>
          <PrimaryButton to={`${currentUrl.pathname.concat(`/target/${activeTarget.id}`)}`} full offlineDisabled>
            Update cartons target
          </PrimaryButton>
        </FieldsetItem>
      ) : null}

      {actionButtons.canRequestException && (
        <FieldsetItem>
          <PrimaryButton full to={`${currentUrl.pathname}/exception`} offlineDisabled>
            Request exception
          </PrimaryButton>
        </FieldsetItem>
      )}
    </>
  )
}

ActionButtons.propTypes = {
  currentUrl: object,
  program: object
}

const ViewGoProgram = ({ fetchCustomerGoFunds }) => {
  const { translate } = useContext(LangContext)
  const {
    currentSector: { customer }
  } = useContext(SectorContext)
  const currentUrl = useLocation()
  const { programId, sectorType, sectorId: customerId } = useParams()
  const navigate = useNavigate()

  const [isLoading, setLoadding] = useState(true)
  const [open, setOpen] = useState(false)
  const [receipt, setReceipt] = useState()
  const [quantityAdded, setQuantityAdded] = useState(0)
  const [errorApproveOrReject, setErrorApproveOrReject] = useState()

  const { isPending: isPendingApprove, mutate: approveReceipt } = useMutation({
    mutationFn: async () => {
      const payment = {
        quantityAdded
      }

      return approveReceiptPayment(receipt.goFundProgramId, receipt.id, payment)
    },
    onSuccess: async () => {
      closeModal()
      await fetchCustomerGoFunds(customerId)
    },
    onError: (e) => {
      setErrorApproveOrReject(e)
    }
  })

  const { isPending: isPendingReject, mutate: rejectReceipt } = useMutation({
    mutationFn: async () => {
      return rejectReceiptPayment(receipt.goFundProgramId, receipt.id)
    },
    onSuccess: async () => {
      closeModal()
      await fetchCustomerGoFunds(customerId)
    },
    onError: (e) => {
      setErrorApproveOrReject(e)
    }
  })

  useEffect(() => {
    fetchCustomerGoFunds(customerId).finally(() => setLoadding(false))
  }, [])

  const goFundProgram = useSelector((state) => programFromUrl(state, { programId }))

  const newPayeeSubject = useMemo(() => {
    if (customer?.language)
      return customer.language === 'FR'
        ? `Demande de création d’un nouvel employé Extra Hub`
        : 'New Extra Hub user request'
  }, [customer])

  const newPayeeEmailBody = useMemo(() => {
    if (customer?.language)
      return customer.language === 'FR'
        ? `S'il vous plaît, ajouter ce bénéficiaire aux contacts du magasin. \nMagasin: ${customer.name} \nTitre du contact: \nPrénom du contact: \nNom du contact: \nCourriel du contact: \nLangage du contact: \nRôle du contact: \n`
        : `Please add this payee to the store contacts. \nStore: ${customer.name} \nContact title: \nContact first name: \nContact last name: \nContact email: \nContact language: \nContact role: \n`
  }, [customer])

  console.log('sectorType', sectorType)

  if (sectorType !== SECTOR_LEVELS.CUSTOMER) navigate('..')

  if (isLoading) return <WrappedSpinner icon="spinner" />

  const { goFund, targets, programCardInfos } = goFundProgram

  const programExceptions = goFundProgram?.exceptions?.map((exception) => {
    const contact = customer.storeContacts.find((contact) => contact.id === exception.payeeId)
    return { ...exception, payee: contact }
  })
  const programPayments = goFundProgram?.payments?.map((payment) => {
    const contact = customer.storeContacts.find((contact) => contact.id === payment.payeeId)
    return { ...payment, payee: contact }
  })

  const sortedTargets = targets
    .filter((target) => target.completed)
    .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))

  const sortedImages = goFundProgram.images.sort((a, b) => moment(b.createdAt) - moment(a.createdAt))

  const validateFmcConversion = (goFund) => {
    return goFund && goFund.activity && goFund.activity === FMC_CONVERSION
  }

  function openModal(receipt) {
    const isFmcConversion = validateFmcConversion(goFund)
    if (receipt?.aiResult || canAcceptOrReject) {
      if (isFmcConversion) {
        setQuantityAdded(1)
      } else {
        const sum = sumBy(receipt.aiResult.validProducts, 'quantity')
        setQuantityAdded(sum)
      }
    }
    setReceipt(receipt)
    setOpen(true)
  }

  function closeModal() {
    setReceipt()
    setOpen(false)
    setQuantityAdded(0)
    setErrorApproveOrReject()
  }

  const budgetLeft = Math.round(programCardInfos.remainingBudget / goFundProgram.perCartonCost)
  const canAcceptOrReject = programCardInfos.isAiProgram && GO_PROGRAM_RECEIPT_STATUS.find((s) => s === receipt?.status)
  const isFmcConversion = validateFmcConversion(goFund)

  return (
    <>
      <Container>
        {!window.navigator.onLine && (
          <GlobalAlert warning>
            You are offline, the data you are seeing might not be up to date and you will not be able to submit payments
            or exceptions
          </GlobalAlert>
        )}

        <ProgramCard
          program={goFundProgram}
          isCompleted={goFundProgram.status === 'completed'}
          canRequestException={programCardInfos.actionButtons.canRequestGoFundException}
          canPayNow={programCardInfos.actionButtons.canPayGoFund}
        />
        {programCardInfos.isAiProgram && (
          <div>
            <ReceiptModal
              open={open}
              receipt={receipt}
              detail
              hideValidProducts={isFmcConversion}
              close={() => closeModal()}
            >
              {canAcceptOrReject && !programCardInfos.remainingBudget && (
                <div className="pt-4">
                  <GlobalAlert error>
                    <p className="py-2 text-sm text-red-800">
                      {translate(`components.Extrahub.Result.ErrorMessage.CannotAcceptOrReject`)}
                    </p>
                  </GlobalAlert>
                </div>
              )}
              {canAcceptOrReject && !!programCardInfos.remainingBudget && (
                <>
                  {receipt.status === 'Invalid' && (
                    <div className="my-4 rounded-md border-2 p-4">
                      <Label>{translate('components.Extrahub.ListReceipts.overrideQuantity')}</Label>
                      <NumberInput
                        disabled={isFmcConversion}
                        fieldWidth="full"
                        max={budgetLeft}
                        value={quantityAdded}
                        onChange={(e) => setQuantityAdded(e)}
                      />
                    </div>
                  )}
                  {errorApproveOrReject && (
                    <GlobalAlert error>
                      <p className="py-2 text-sm text-red-800">
                        {errorApproveOrReject.response?.status === 400
                          ? translate(`components.Extrahub.Result.ErrorMessage.InsufficientBudget`)
                          : translate(`components.Extrahub.Result.ErrorMessage.PaymentFailed`)}
                      </p>
                    </GlobalAlert>
                  )}
                  <div className="mt-4 flex flex-col gap-2 sm:flex-row">
                    <Button
                      full
                      destructive
                      onClick={rejectReceipt}
                      isLoading={isPendingReject}
                      disabled={isPendingApprove}
                    >
                      {translate('components.Extrahub.ListReceipts.rejectInvoice')}
                    </Button>
                    <Button
                      full
                      secondary
                      onClick={approveReceipt}
                      isLoading={isPendingApprove}
                      disabled={!quantityAdded || isPendingReject}
                    >
                      {translate('components.Extrahub.ListReceipts.acceptInvoice')}
                    </Button>
                  </div>
                </>
              )}
            </ReceiptModal>
            <ListReceipts receipts={sortedImages} openModal={(receipt) => openModal(receipt)} colored />
          </div>
        )}
        <div>
          <BasicAccordion title={'Payment history'}>
            {programPayments && programPayments.length ? (
              <Table>
                <tbody>
                  {programPayments.map((payment, index) => (
                    <tr key={payment.id.toString().concat(index)}>
                      <td>
                        {payment.payee
                          ? `${payment.payee.firstname} ${payment.payee.lastname} - ${payment.payee.role}`
                          : 'Unknown contact'}
                        <br />
                        {moment(payment.createdAt).format('MMM D/yy')}
                      </td>
                      <td style={{ textAlign: 'center' }}>${payment.paymentAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyState title="No data yet" subtitle={`No instant payments logged for this program`} />
            )}
          </BasicAccordion>
        </div>
        <div>
          {programExceptions && Boolean(programExceptions.length) && (
            <BasicAccordion title={'Exception history'}>
              <Table>
                <tbody>
                  {programExceptions.map((e, index) => (
                    <tr key={e.payee.firstname.concat(e.payee.lastname, index)}>
                      <td>
                        {e.payee.firstname} {e.payee.lastname} - {e.payee.role} <br />
                        {moment(e.createdAt).format('MMM D/yy')}
                      </td>
                      <td style={{ textAlign: 'center' }}>${e.requestedAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </BasicAccordion>
          )}
        </div>
        <div>
          <BasicAccordion title={'Store contacts'}>
            {customer.storeContacts && customer.storeContacts.length ? (
              <Table>
                <tbody>
                  {customer.storeContacts.map((contact, index) => (
                    <tr key={contact.firstname.concat(contact.lastname, index)}>
                      <td>
                        {contact.firstname} {contact.lastname} - {contact.role}
                        <br />
                        {contact.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyState
                title="No store contacts yet"
                subtitle={`To add new store contacts, use the 'Request new contact' button below.`}
              />
            )}
          </BasicAccordion>
        </div>

        {sortedTargets.length > 0 && (
          <div>
            <BasicAccordion title={'Target history'}>
              <Table>
                <tbody>
                  <tr>
                    <th>Target amount</th>
                    <th>Actual amount</th>
                    <th>Created at</th>
                  </tr>
                  {sortedTargets.map((target) => (
                    <tr key={target.id}>
                      <td>{target.cartonTargetAmount}</td>
                      <td>{target.cartonActualAmount}</td>
                      <td>{moment(target.createdAt).format('MMM DD YYYY HH:mm')}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </BasicAccordion>
          </div>
        )}

        {!programCardInfos.isAiProgram && sortedImages.length > 0 && (
          <ImageAccordion
            images={sortedImages.map((image) => ({
              ...image,
              text: `${moment(image.createdAt).format('MMMM DD YYYY')} at ${moment(image.createdAt).format('HH:MM')}`
            }))}
          />
        )}

        {goFund.paymentType === GO_FUND_PAYMENT_TYPES.instant && (
          <Fieldset padded>
            <FieldsetItem>
              <PrimaryButton
                full
                href={`mailto:${customer.primaryContact.email}?subject=${newPayeeSubject}&body=${encodeURIComponent(
                  newPayeeEmailBody
                )}`}
                target="_blank"
              >
                Request new contact
              </PrimaryButton>
            </FieldsetItem>

            {ActionButtons && (
              <ActionButtons
                currentUrl={currentUrl}
                program={goFundProgram}
                customer={customer}
                programId={programId}
              />
            )}
          </Fieldset>
        )}
      </Container>
    </>
  )
}

ViewGoProgram.propTypes = {
  fetchCustomerGoFunds: func
}

const mapActionCreators = {
  fetchCustomerGoFunds
}

export default connect(null, mapActionCreators)(ViewGoProgram)
