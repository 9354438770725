import React, { useContext } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import LangContext from 'context/LangContext'

import BasicAccordion from 'components/accordion/BasicAccordion'
import EmptyState from 'components/EmptyState'

const PaymentHistory = ({ payments }) => {
  const { translate } = useContext(LangContext)

  return (
    <BasicAccordion title={translate('components.Extrahub.PaymentHistory.titleAccordion')}>
      {payments?.length ? (
        <table className="w-full">
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.id}>
                <td>
                  {!payment.payee?.firstname || !payment.payee?.lastname
                    ? translate('components.Extrahub.PaymentHistory.unknownContact')
                    : `${payment.payee.firstname} ${payment.payee.lastname}`}
                  {` ${translate('common.on')} ${moment(payment.createdAt).format('YYYY-MM-DD')}`}
                </td>
                <td className="text-right">${payment.paymentAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <EmptyState
          title={translate('components.Extrahub.PaymentHistory.EmptyState.title')}
          subtitle={translate('components.Extrahub.PaymentHistory.EmptyState.subtitle')}
        />
      )}
    </BasicAccordion>
  )
}

PaymentHistory.propTypes = {
  payments: PropTypes.array
}

export default PaymentHistory
