import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import moment from 'moment-timezone'
import { func } from 'prop-types'

import LangContext from 'context/LangContext'

import { fetchCustomerSla } from 'store/customers/actions'
import { allCustomerSla, customerSlaLatestUpdate } from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'

import EmptyState from 'components/EmptyState'
import Spinner from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import { borderColor, greyDark, greyLight, white } from 'styles/colors'
import { tinyFontSize } from 'styles/typography'

const cellClass = `border p-2 text-xs border-${borderColor}`
const headerCellClass = `${cellClass} bg-opacity-50 text-left text-${greyDark} bg-${greyLight}`

const CustomerSlaContent = ({ fetchCustomerSla }) => {
  const { translate, lang } = useContext(LangContext)
  const { sectorId } = useParams()
  const [error, setError] = useState()

  useEffect(() => {
    document.title = 'Actions - Customer SLA'
  }, [])

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_SLA, { customerId: sectorId })

  const { customerSla, slaLatestUpdate, isLoading } = useSelector((state) => ({
    customerSla: allCustomerSla(state, { customerId: sectorId }),
    slaLatestUpdate: customerSlaLatestUpdate(state, { customerId: sectorId }),
    isLoading: isDataKeyLoading(state, { dataKey })
  }))

  useEffect(() => {
    let isMounted = true
    if (sectorId) {
      fetchCustomerSla(sectorId, dataKey).catch((err) => isMounted && setError(getErrorMessage(err)))
    }
    return () => {
      isMounted = false
    }
  }, [sectorId])

  if (isLoading) return <Spinner icon="spinner" />
  if (error) return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />
  if (!customerSla || customerSla.length === 0) {
    return <EmptyState title={translate('app.warn.noDataAvailable')} subtitle={translate('close.noCustomerSla')} />
  }

  return (
    <>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            {['app.brandFocus', 'common.program', 'common.details', 'common.startDate', 'common.endDate'].map((key) => (
              <th key={key} className={headerCellClass}>
                {translate(key)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {customerSla.map((sla, i) => (
            <tr key={i}>
              <td className={`${cellClass} bg-${white}`}>{sla.brandFocus}</td>
              <td className={`${cellClass} bg-${white}`}>{capitalize(sla.slaProgram)}</td>
              <td className={`${cellClass} bg-${white}`}>{sla.programDetails ? capitalize(sla.programDetails) : ''}</td>
              <td className={`${cellClass} bg-${white}`}>{moment.utc(sla.startDate).locale(lang).format('ll')}</td>
              <td className={`${cellClass} bg-${white}`}>{moment.utc(sla.endDate).locale(lang).format('ll')}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {slaLatestUpdate && (
        <footer className="flex justify-center pt-4">
          <small className="relative text-xs" style={{ color: greyLight, fontSize: tinyFontSize }}>
            {translate('close.customerSLALatestUpdate', { date: moment(slaLatestUpdate).locale(lang).format('ll') })}
          </small>
        </footer>
      )}
    </>
  )
}

CustomerSlaContent.propTypes = { fetchCustomerSla: func }
export default connect(null, { fetchCustomerSla })(CustomerSlaContent)
