import React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import config from 'config'

import Sidebar from 'components/Sidebar'

import { SECTOR_LEVELS } from 'utils/constants'

import OpportunitiesModal from '../views/Close/OpportunitiesView/OpportunitiesModal'

const MainLayout = () => {
  const { sectorType, sectorId: customerId } = useParams()
  const location = useLocation()

  const isCustomerLevel = sectorType === SECTOR_LEVELS.CUSTOMER

  const isPaceURL = location.pathname.includes('/pace/')

  const activeCloseOpportunitiesFeatureFlag = config.featureFlags.closeOpportunities

  const opportunitiesModalRelevant = isCustomerLevel && isPaceURL && activeCloseOpportunitiesFeatureFlag

  return (
    <div className="flex h-[100dvh] bg-slate-100 text-sm">
      <Sidebar />
      <Outlet />
      {opportunitiesModalRelevant && customerId && <OpportunitiesModal />}
    </div>
  )
}

export default MainLayout
