import React, { useContext } from 'react'

import LangContext from 'context/LangContext'

const SuccessContent = () => {
  const { translate } = useContext(LangContext)

  return (
    <div className="flex w-full flex-col gap-5 rounded-md  bg-sky-100 p-4 text-sm ring-1">
      <div className="flex flex-col gap-2 ">
        <h3 className="text-base font-medium text-sky-900">{translate('common.success')}</h3>
        <p className="text-sm text-sky-800">{translate(`components.Extrahub.Result.SuccessMessage.FmcConversion`)}</p>
      </div>
    </div>
  )
}

export default SuccessContent
