// import { createSelector } from 'store/selectors'
import countBy from 'lodash/countBy'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import sumBy from 'lodash/sumBy'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import { createSelector } from 'reselect'

import { isVFUser } from 'store/auth/selectors'
import { customerFromUrl } from 'store/customers/selectors'
import { currentTerritoryName, territoryCustomers, territoryProvinces } from 'store/territories/selectors'

const allPricing = (state) => omit(state.pricings, '_persist')
const upcFromUrl = (state, props) => props.upc
const sortFromProps = (state, props) => props.sortData

const captureOrderedPricing = createSelector(allPricing, (pricing) =>
  sortBy(Object.values(pricing), 'dashboardPosition')
)
const getProvincePricing = (pricing, prov, visibilityFieldToCheck) =>
  pricing.reduce((acc, { provinces, upc, displayName }) => {
    const provinceDetails = provinces.find(({ province }) => province === prov)
    return provinceDetails && !provinceDetails[visibilityFieldToCheck] ? acc : [...acc, { upc, displayName }]
  }, [])

export const captureOrderedPricingByProvince = createSelector(
  isVFUser,
  territoryProvinces,
  captureOrderedPricing,
  (isVFUser, provinces, pricing) => {
    const vapeChannelPricing = provinces.vape.reduce(
      (acc, prov) => ({
        ...acc,
        [`vape_channel_${prov}`]: getProvincePricing(pricing, prov, 'visibleInVapeChannel')
      }),
      {}
    )
    const visibilityFieldToCheck = isVFUser ? 'visibleForVirtualForce' : 'visibleForField'
    return provinces.general.reduce(
      (acc, prov) => ({
        ...acc,
        [prov]: getProvincePricing(pricing, prov, visibilityFieldToCheck)
      }),
      vapeChannelPricing
    )
  }
)

const relevantPricingVisibilityField = createSelector(currentTerritoryName, isVFUser, (territoryName, isVFUser) => {
  if (territoryName?.toLowerCase().includes('vape')) return 'visibleInVapeChannel'
  return isVFUser ? 'visibleForVirtualForce' : 'visibleForField'
})

const PRICING_STATUS = {
  NON_COMPLIANT: 'nonCompliant',
  NON_COMPETITIVE: 'notCompetitive',
  INVALID: 'invalid',
  VALID: 'valid',
  EXPIRED: 'expired',
  UPDATED: 'updated',
  OUTDATED: 'outdated',
  IN_PROGRESS: 'inProgress',
  NOT_REQUIRED: 'notRequired',
  NO_STATUS: 'noStatus'
}
function getPricingStatus(pricing, capture) {
  if (!capture || isEmpty(capture) || (!capture.price && !capture.outOfDistribution)) return PRICING_STATUS.NO_STATUS
  if (moment(capture.updatedAt).isBefore(moment(pricing.startDate))) {
    return PRICING_STATUS.EXPIRED
  }
  return PRICING_STATUS.VALID
}

const mergeCustomerPricing = (customer) => {
  if (!customer) return []
  const { retailer, employee, ...others } = groupBy(customer.latestPricings, 'source')

  const employeeCaptures = merge([], ...Object.values(others), employee)

  const employeePricingCaptures = keyBy(employeeCaptures, 'upc')

  // Prioritize base strategy
  const sortedPricingStrategies = customer.pricingStrategies?.sort((a, b) => b.isStrategyBase - a.isStrategyBase)
  const mergedPricings =
    sortedPricingStrategies?.map((pricing) => {
      delete pricing.updatedAt
      delete pricing.createdAt

      const employeeCapture = employeePricingCaptures[pricing.upc] || null
      const status = getPricingStatus(pricing, employeeCapture)
      return { ...pricing, ...employeeCapture, status }
    }) || []

  return uniqBy(mergedPricings, (pricing) => pricing.upc)
}

const mergeCustomerRetailerPricing = (customer) => {
  if (!customer) return []
  const { retailer, employee, ...others } = groupBy(customer.latestPricings, 'source')

  const retailerCaptures = merge([], ...Object.values(others), retailer)

  const retailerPricingCaptures = keyBy(retailerCaptures, 'upc')

  // Prioritize base strategy
  const sortedPricingStrategies = customer.pricingStrategies?.sort((a, b) => b.isStrategyBase - a.isStrategyBase)
  const mergedPricings =
    sortedPricingStrategies?.map((pricing) => {
      const retailerCapture = retailerPricingCaptures[pricing.upc] || null
      const status = getPricingStatus(pricing, retailerCapture)
      return { ...pricing, ...retailerCapture, status }
    }) || []

  return uniqBy(mergedPricings, (pricing) => pricing.upc)
}

export const customerPricing = createSelector(customerFromUrl, mergeCustomerPricing)

export const pendingCustomerPricing = createSelector(customerFromUrl, customerPricing, (customer, customerPricings) => {
  return (
    customerPricings.filter((pricing) => {
      const today = moment()
      const startDate = moment(pricing.startDate)
      const endDate = moment(pricing.endDate)
      const lastUpdate = moment(pricing.updatedAt)

      return !pricing.price || (today.isBetween(startDate, endDate, 'day', '[]') && lastUpdate.isBefore(startDate))
    }) || []
  )
})

export const customeRetailerPricing = createSelector(customerFromUrl, mergeCustomerRetailerPricing)

export const totalStoreProfitForWeek = createSelector(customerPricing, (pricing) => {
  return sumBy(pricing, ({ storeAwr }) => storeAwr || 0)
})

export const customerPricingAnalytics = createSelector(customerPricing, (pricing) => {
  const brandGroupedPricing = groupBy(pricing, 'shortBrand')
  return Object.entries(brandGroupedPricing).map(([brand, brandPricings]) => ({
    name: brand,
    onePack20s: brandPricings.find(({ packCount, packSize }) => !packCount && packSize === '20'),
    onePack25s: brandPricings.find(({ packCount, packSize }) => !packCount && packSize === '25'),
    twoPack20s: brandPricings.find(({ packCount, packSize }) => packCount === '2pk' && packSize === '20'),
    twoPack25s: brandPricings.find(({ packCount, packSize }) => packCount === '2pk' && packSize === '25')
  }))
})

export const captureSortedCustomerPricing = createSelector(
  customerPricing,
  sortFromProps,
  (pricings, sortFromProps = { column: 'shortBrand', order: 'desc' }) => {
    return orderBy(pricings, [(pricing) => (pricing[sortFromProps.column] || '').toLowerCase()], [sortFromProps.order])
  }
)
const captureIndexOfPricingFromUrl = createSelector(captureSortedCustomerPricing, upcFromUrl, (pricing, upc) =>
  pricing.findIndex((cp) => cp.upc === upc)
)

export const pricingFromUrl = createSelector(
  captureSortedCustomerPricing,
  captureIndexOfPricingFromUrl,
  (pricings, index) => pricings[index]
)
export const nextCaptureUpc = createSelector(
  captureSortedCustomerPricing,
  captureIndexOfPricingFromUrl,
  (pricings, currentIndex) => {
    const nextPricing =
      pricings[currentIndex + 1] ||
      pricings.find(
        ({ status, outOfDistribution }) => status === 'expired' || (!outOfDistribution && status === 'noStatus')
      )
    return nextPricing ? nextPricing.upc : null
  }
)

export const previousCaptureUpc = createSelector(
  captureSortedCustomerPricing,
  captureIndexOfPricingFromUrl,
  (pricings, currentIndex) => {
    if (!currentIndex || !pricings?.length) return ''
    return (pricings[currentIndex - 1] || {}).upc || ''
  }
)

export const capturesRemaining = createSelector(
  captureSortedCustomerPricing,
  captureIndexOfPricingFromUrl,
  (pricings, index) => Math.min(pricings.length - index - 1, 3)
)

const getCustomerPricingStatus = ({ pricingNotification, latestPricings }) => {
  const priceNotRequired = pricingNotification?.match(/(non.?requis|not.?required)/i)
  if (priceNotRequired || !latestPricings.length) return PRICING_STATUS.NOT_REQUIRED
  const { true: updated = 0, false: notUpdated = 0 } = countBy(
    latestPricings,
    ({ status }) =>
      status !== PRICING_STATUS.EXPIRED && status !== PRICING_STATUS.NO_STATUS && status !== PRICING_STATUS.INVALID
  )
  if (!updated) return PRICING_STATUS.OUTDATED
  if (!notUpdated) return PRICING_STATUS.UPDATED
  return PRICING_STATUS.IN_PROGRESS
}

export const territoryPricing = createSelector(
  territoryCustomers,
  allPricing,
  relevantPricingVisibilityField,
  (customers) => {
    const edlps = {}
    // const competitiveCounts = {}
    // const edlpQualifiedCount = 0
    const territoryCustomerPricings = customers
      ? Object.values(customers).map(
          ({ id, name, address, pricingNotification, latestPricings, pricingStrategies }) => {
            // if (!pricingStrategies) return null
            const mergedPricing = pricingStrategies
              ? mergeCustomerPricing({ address, latestPricings, pricingStrategies })
              : []
            const customerPricingStatus = getCustomerPricingStatus({
              pricingNotification,
              latestPricings: mergedPricing
            })

            return {
              id,
              name,
              address,
              pricingNotification,
              pricingStatus: customerPricingStatus,
              pricing: mergedPricing
            }
          }
        )
      : []

    const customerStatusCounts = countBy(territoryCustomerPricings, 'pricingStatus')
    return {
      customerStatusCounts,
      customers: territoryCustomerPricings,
      edlps
    }
  }
)
