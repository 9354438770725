import React, { useState } from 'react'
import { node } from 'prop-types'

import { SORTING_DIRECTION } from 'utils/constants'

const PriceCaptureContext = React.createContext()
export default PriceCaptureContext

const SORTABLE_COLUMNS = {
  SHORTBRAND: 'shortBrand',
  PRODUCT: 'productName',
  STATUS: 'status'
}

export function PriceCaptureProvider({ children }) {
  const [sortData, setSortData] = useState({
    column: SORTABLE_COLUMNS.SHORTBRAND,
    order: SORTING_DIRECTION.DESC
  })

  const value = {
    sortData,
    setSortData
  }

  return <PriceCaptureContext.Provider value={value}>{children}</PriceCaptureContext.Provider>
}

PriceCaptureProvider.propTypes = {
  children: node.isRequired
}
