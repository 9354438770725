import React, { useContext } from 'react'
import { object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import { PLACEHOLDERS } from 'utils/constants'
import { formatCurrency, formatNumber, formatPercent } from 'utils/formatters'

const PerformanceTable = ({ type, data }) => {
  const { translate } = useContext(LangContext)

  const tableContentforType = {
    sellin: {
      headers: [
        'close.performanceSoundbites.sellinTableColumn1',
        'close.performanceSoundbites.sellinTableColumn2',
        'close.performanceSoundbites.sellinTableColumn3',
        'close.performanceSoundbites.sellinTableColumn4',
        'close.performanceSoundbites.sellinTableColumn5'
      ],
      rows: (product) => [
        product.name,
        formatNumber(product.orderCompletionActuals, { forceDecimals: true }),
        formatNumber(product.awr13, { forceDecimals: true }),
        formatNumber(product.awr4, { forceDecimals: true }),
        formatNumber(product.selloutAWR4, { forceDecimals: true })
      ]
    },
    distro: {
      headers: ['close.performanceSoundbites.distroTableColumn1', 'close.performanceSoundbites.distroTableColumn2'],
      rows: (product) => [product.name, formatNumber(product.selloutL26, { forceDecimals: true })]
    },
    priceCapture: {
      headers: [
        'close.performanceSoundbites.priceCaptureTableColumn1',
        'close.performanceSoundbites.priceCaptureTableColumn2',
        'close.performanceSoundbites.priceCaptureTableColumn3',
        'close.performanceSoundbites.priceCaptureTableColumn4'
      ],
      rows: (product) => [
        product.name,
        formatCurrency(product.minPriceFSA),
        formatCurrency(product.avgPriceFSA),
        formatCurrency(product.price)
      ]
    },
    sellout: {
      topHeaders: [
        'close.performanceSoundbites.selloutTopTableColumn1',
        'close.performanceSoundbites.selloutTableColumn2',
        'close.performanceSoundbites.selloutTableColumn3',
        'close.performanceSoundbites.selloutTableColumn4'
      ],
      rows: (product) => [
        product.name,
        product.costDelta ? formatPercent(product.costDelta, { forceDecimals: true }) : PLACEHOLDERS.NO_VALUE,
        product.qtyDelta ? formatPercent(product.qtyDelta, { forceDecimals: true }) : PLACEHOLDERS.NO_VALUE,
        product.fsaDelta ? formatPercent(product.fsaDelta, { forceDecimals: true }) : PLACEHOLDERS.NO_VALUE
      ],
      bottomHeaders: [
        'close.performanceSoundbites.selloutBottomTableColumn1',
        'close.performanceSoundbites.selloutTableColumn2',
        'close.performanceSoundbites.selloutTableColumn3',
        'close.performanceSoundbites.selloutTableColumn4'
      ],
      additionalRows: (product) => [
        product.name,
        product.costDelta ? formatPercent(product.costDelta, { forceDecimals: true }) : PLACEHOLDERS.NO_VALUE,
        product.qtyDelta ? formatPercent(product.qtyDelta, { forceDecimals: true }) : PLACEHOLDERS.NO_VALUE,
        product.fsaDelta ? formatPercent(product.fsaDelta, { forceDecimals: true }) : PLACEHOLDERS.NO_VALUE
      ]
    }
  }

  const tableContent = tableContentforType[type]
  if (!tableContent) return null

  const doubleTableDisplay = !!(tableContent.topHeaders && tableContent.bottomHeaders)

  return (
    <div className="w-full overflow-x-auto">
      <table className="w-full rounded">
        {!doubleTableDisplay && (
          <>
            <thead>
              <tr className="border-b *:border-r *:px-1 *:pt-1 *:text-left *:text-2xs *:font-medium *:text-slate-500 first:*:pl-2 last:*:border-r-0 last:*:pr-2">
                {tableContent.headers.map((header) => (
                  <th key={header}>{translate(header)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((product) => (
                <tr
                  key={product.id}
                  className="border-b *:whitespace-nowrap *:border-r *:px-1 *:text-left *:text-2xs *:text-slate-900 first:*:pl-2 last:border-b-0 last:*:border-r-0 last:*:pb-1 last:*:pr-2"
                >
                  {tableContent.rows(product).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </>
        )}

        {doubleTableDisplay && (
          <>
            <thead>
              <tr className="border-b *:border-r *:px-1 *:pt-1 *:text-left *:text-2xs *:font-medium *:text-slate-500 first:*:pl-2 last:*:border-r-0 last:*:pr-2">
                {tableContent.topHeaders.map((header) => (
                  <th key={header}>{translate(header)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.top3?.map((product) => (
                <tr
                  key={product.id}
                  className="border-b *:whitespace-nowrap *:border-r *:px-1 *:text-left *:text-2xs *:text-slate-900 first:*:pl-2 last:border-b-0 last:*:border-r-0 last:*:pb-1 last:*:pr-2"
                >
                  {tableContent.additionalRows(product).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
            <thead>
              <tr className="border-b *:border-r *:px-1 *:pt-1 *:text-left *:text-2xs *:font-medium *:text-slate-500 first:*:pl-2 last:*:border-r-0 last:*:pr-2">
                {tableContent.bottomHeaders.map((header) => (
                  <th key={header}>{translate(header)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.bottom3?.map((product) => (
                <tr
                  key={product.id}
                  className="border-b *:whitespace-nowrap *:border-r *:px-1 *:text-left *:text-2xs *:text-slate-900 first:*:pl-2 last:border-b-0 last:*:border-r-0 last:*:pb-1 last:*:pr-2"
                >
                  {tableContent.additionalRows(product).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </>
        )}
      </table>
    </div>
  )
}

export default PerformanceTable

PerformanceTable.propTypes = {
  type: string.isRequired,
  data: object.isRequired
}
