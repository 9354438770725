import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import flatten from 'lodash/flatten'
import { func } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { fetchCustomerGoFunds } from 'store/customers/actions'
import { customerPrograms, lastCustomerGoSalesBooked } from 'store/customers/selectors'
import { fetchTerritoryGoFunds } from 'store/territories/actions'

import ProgramAccordion, { Title } from 'components/accordion/ProgramAccordion'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import ProgramCard from 'components/ProgramCard'
import Section from 'components/Section'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const ProgramList = ({ fetchCustomerGoFunds, fetchTerritoryGoFunds }) => {
  const { sectorId: customerId } = useParams()
  const { currentSector } = useContext(SectorContext)
  const territoryId = currentSector?.territory?.id
  const programs = useSelector((state) => customerPrograms(state, { customerId }))
  const lastGoSalesBookedDate = useSelector((state) => lastCustomerGoSalesBooked(state, { customerId }))

  const [isLoading, setLoadding] = useState(true)

  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_GO_FUND, { customerId })

  useEffect(() => {
    fetchCustomerGoFunds(customerId).finally(() => setLoadding(false))
    fetchTerritoryGoFunds(territoryId, dataKey)
  }, [])

  const noPrograms = !programs || !flatten(Object.values(programs)).length
  const { current, ended, completed } = programs

  if (isLoading) return <WrappedSpinner icon="spinner" />

  if (noPrograms) {
    return <EmptyState title="Nothing yet" subtitle="No programs have been added yet." />
  }

  return (
    <>
      <Container>
        <>
          {!!ended.length && (
            <Section>
              {ended.map((program, i) => (
                <ProgramCard
                  key={i}
                  program={program}
                  programsPath="."
                  isEnded
                  lastGoSalesBookedDate={lastGoSalesBookedDate}
                />
              ))}
            </Section>
          )}
          {!!current.length && (
            <Section>
              {!!ended.length && <Title>Current</Title>}
              {current.map((program, i) => (
                <ProgramCard
                  key={i}
                  program={program}
                  programsPath="."
                  isCurrent
                  lastGoSalesBookedDate={lastGoSalesBookedDate}
                />
              ))}
            </Section>
          )}
          {!!completed.length && (
            <Section>
              <ProgramAccordion title="Completed Programs">
                {completed.map((program, i) => (
                  <ProgramCard
                    key={i}
                    program={program}
                    programsPath="."
                    isCompleted
                    lastGoSalesBookedDate={lastGoSalesBookedDate}
                  />
                ))}
              </ProgramAccordion>
            </Section>
          )}
        </>
      </Container>
    </>
  )
}

ProgramList.propTypes = {
  fetchCustomerGoFunds: func,
  fetchTerritoryGoFunds: func
}

const mapActionCreators = {
  fetchCustomerGoFunds,
  fetchTerritoryGoFunds
}

export default connect(null, mapActionCreators)(ProgramList)
