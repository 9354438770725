import * as React from 'react'
import { cva } from 'class-variance-authority'
import { number, oneOf, string } from 'prop-types'

import { closeOpportunitiesActionsVariants, cn } from 'utils/styling'

const tableHeadVariants = cva('whitespace-nowrap border-b border-slate-900/10 overflow-hidden', {
  variants: {
    closeOpportunitiesActionsVariants
  }
})

export default function ActionCard({ title, completed, total, variant }) {
  return (
    <div className="rounded-md ring-1 ring-slate-900/10">
      <table className="w-full table-fixed gap-4 rounded-md">
        <thead>
          <tr className={cn(tableHeadVariants({ variant }))}>
            <th className="rounded-tl-md rounded-tr-md px-1 py-3 text-center font-medium">{title}</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-slate-900/10">
          <tr>
            <td className="px-4 py-3 text-center">
              {completed} / {total}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

ActionCard.propTypes = {
  title: string.isRequired,
  completed: number.isRequired,
  total: number.isRequired,
  variant: oneOf(['sellIn', 'priceCheck', 'priceCompliance', 'surveys', 'extraFunds'])
}
