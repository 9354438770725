import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { bool, func, string } from 'prop-types'

import Dropdown from 'components/Dropdown'

import { GEOGRAPHIES, SECTOR_LEVELS } from 'utils/constants'

export const displayOptTableLabels = (geography) => {
  const selectedOption = GEOGRAPHIES.find((geo) => geo.value === geography)
  if (selectedOption && selectedOption.label) return selectedOption.label
  return geography
}

const GeographyToggle = ({
  geography,
  setGeography,
  includeActivity = false,
  includeCampaign = false,
  includeBrand = false,
  includeChannel = false,
  includeSku = false,
  includeOwnership = false,
  includeBrandVariant = false,
  includeUser = false,
  includeFsa = false,
  includeCity = false
}) => {
  const { sectorType } = useParams()

  const geographyOptions = useMemo(() => {
    const excludedValues = new Set()

    if (sectorType === SECTOR_LEVELS.DISTRICT) {
      excludedValues.add('province').add('region')
    } else if (sectorType === SECTOR_LEVELS.TERRITORY) {
      excludedValues.add('province').add('region').add('district')
    } else if (sectorType === SECTOR_LEVELS.CUSTOMER) {
      ;[
        'headoffice',
        'channel',
        'region',
        'district',
        'territory',
        'subregion',
        'province',
        'ownership',
        'fsa',
        'city'
      ].forEach((val) => excludedValues.add(val))
    }

    !includeActivity && excludedValues.add('activity')
    !includeCampaign && excludedValues.add('campaign')
    !includeBrand && excludedValues.add('brand')
    !includeChannel && excludedValues.add('channel')
    !includeOwnership && excludedValues.add('ownership')
    !includeSku && excludedValues.add('sku')
    !includeBrandVariant && excludedValues.add('brand_variant')
    !includeUser && excludedValues.add('user')
    !includeFsa && excludedValues.add('fsa')
    !includeCity && excludedValues.add('city')

    return GEOGRAPHIES.filter((opt) => !excludedValues.has(opt.value))
  }, [includeActivity, includeBrand, includeBrandVariant, sectorType])

  useEffect(() => {
    if (!geographyOptions.some((opt) => opt.value === geography)) setGeography(geographyOptions[0].value)
  }, [geographyOptions])

  return (
    <Dropdown name="type" onChange={(e) => setGeography(e.target.value)} value={geography} options={geographyOptions} />
  )
}

GeographyToggle.propTypes = {
  geography: string,
  setGeography: func,
  includeActivity: bool,
  includeCampaign: bool,
  includeBrand: bool,
  includeChannel: bool,
  includeSku: bool,
  includeOwnership: bool,
  includeBrandVariant: bool,
  includeUser: bool,
  includeFsa: bool,
  includeCity: bool
}

export default GeographyToggle
