import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import * as api from 'store/priceCaptureCompliance/endpoints'

import Card from 'components/card'
import DataCombined from 'components/DataTable/DataCombined'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Pagination from 'components/Pagination'

import {
  DEFAULT_TABLE_PAGE_SIZE,
  GEOGRAPHY_VALUES,
  PLACEHOLDERS,
  SECTOR_LEVELS,
  SORTING_DIRECTION
} from 'utils/constants'
import { formatCurrency, formatPercent } from 'utils/formatters'
import { getGenericGeographyFilters } from 'utils/genericGeographyFilters'
import { getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

const defaultFilters = {
  region: [],
  district: [],
  territory: [],
  kaSubregion: [],
  headoffice: [],
  banner: [],
  province: [],
  tier: [],
  ownershipType: [],
  outletSubType: [],
  store: [],
  city: [],
  fsa: [],
  storesCompletion: 'all',
  productsCompletion: 'all'
}

const GeographyTable = ({ span }) => {
  const { translate } = useContext(LangContext)
  const { currentProductType: productType } = useContext(SectorContext)
  const { sectorType, sectorId } = useParams()

  const [error, setError] = useState(null)
  const [geography, setGeography] = useState(SECTOR_LEVELS.TERRITORY)
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'storesUpdated', order: SORTING_DIRECTION.DESC })
  const [filters, setFilters] = useState(defaultFilters)

  // Geography filter options
  const { data: geographyFiltersOptions, isLoading: areGeographyFiltersLoading } = useQuery({
    queryKey: ['priceCaptureGeographyFiltersOptions', sectorType, sectorId, productType],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const { data } = await api.getPriceCaptureGeographyFilters(sectorType, sectorId, productType)

        return data
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
  }

  const geographyFilters = useMemo(() => {
    const options = {
      ...geographyFiltersOptions
    }

    const changeEventHandlers = {
      regionHandler: (value) => handleFilterChange({ region: value }),
      districtHandler: (value) => handleFilterChange({ district: value }),
      territoryHandler: (value) => handleFilterChange({ territory: value }),
      kaSubregionHandler: (value) => handleFilterChange({ kaSubregion: value }),
      headofficeHandler: (value) => handleFilterChange({ headoffice: value }),
      bannerHandler: (value) => handleFilterChange({ banner: value }),
      provinceHandler: (value) => handleFilterChange({ province: value }),
      tierHandler: (value) => handleFilterChange({ tier: value }),
      ownershipTypeHandler: (value) => handleFilterChange({ ownershipType: value }),
      outletSubtypeHandler: (value) => handleFilterChange({ outletSubtype: value }),
      storeHandler: (value) => handleFilterChange({ store: value }),
      brandHandler: (value) => handleFilterChange({ brand: value }),
      fsaHandler: (value) => handleFilterChange({ fsa: value }),
      cityHandler: (value) => handleFilterChange({ city: value }),
      brandVariantHandler: (value) => handleFilterChange({ brandVariant: value }),
      lengthHandler: (value) => handleFilterChange({ length: value }),
      sizeHandler: (value) => handleFilterChange({ size: value }),
      productDescriptionHandler: (value) => handleFilterChange({ productDescription: value })
    }

    const genericGeographyFilters = getGenericGeographyFilters(
      filters,
      options,
      changeEventHandlers,
      translate,
      sectorType,
      productType
    )

    const storesCompletionFilter =
      geography === GEOGRAPHY_VALUES.CUSTOMER
        ? [
            {
              id: 'storesCompletion',
              label: translate('amplify.priceCapture.filters.storesCompletion'),
              searchPlaceholder: translate('app.placeholders.searchStoresCompletion'),
              options: [
                {
                  value: 'storesFullyUpdated',
                  label: translate('amplify.priceCapture.filters.storesCompletion.updated')
                },
                {
                  value: 'storesNotFullyUpdated',
                  label: translate('amplify.priceCapture.filters.storesCompletion.not.updated')
                }
              ],
              value: filters.storesCompletion,
              onChange: (value) => handleFilterChange({ storesCompletion: value })
            }
          ]
        : []

    const productsCompletionFilter =
      geography === GEOGRAPHY_VALUES.SKU
        ? [
            {
              id: 'productsCompletion',
              label: translate('amplify.priceCapture.filters.productsCompletion'),
              searchPlaceholder: translate('app.placeholders.searchProductsCompletion'),
              options: [
                {
                  value: 'productsFullyUpdated',
                  label: translate('amplify.priceCapture.filters.productsCompletion.updated')
                },
                {
                  value: 'productsNotFullyUpdated',
                  label: translate('amplify.priceCapture.filters.productsCompletion.not.updated')
                }
              ],
              value: filters.productsCompletion,
              onChange: (value) => handleFilterChange({ productsCompletion: value })
            }
          ]
        : []

    return [...genericGeographyFilters, ...storesCompletionFilter, ...productsCompletionFilter]
  }, [geography, areGeographyFiltersLoading, sectorType, productType, translate])

  const appliedFilters = Object.values(filters).filter(
    (value) => (typeof value === 'string' && value !== 'all') || (Array.isArray(value) && value.length !== 0)
  ).length

  // NOTE: Resets filters every time a new geography or a new scope is selected
  useEffect(() => {
    setFilters(defaultFilters)
  }, [sectorType, sectorId, productType])

  // NOTE: Resets storesCompletion and productsCompletion filters when geography changes (These options are only available for specifics geographies)
  useEffect(() => {
    setFilters({
      ...filters,
      storesCompletion: defaultFilters.storesCompletion,
      productsCompletion: defaultFilters.productsCompletion
    })
  }, [geography])

  // Geography data
  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const { data: geographyData, isLoading: isGeographyDataLoading } = useQuery({
    queryKey: ['priceCaptureGeographyData', sectorType, sectorId, geography, productType, offset, filters, sortBy],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const { data } = await api.getPriceCaptureGeography(
          sectorType,
          sectorId,
          geography,
          productType,
          offset,
          DEFAULT_TABLE_PAGE_SIZE,
          filters,
          sortBy.column,
          sortBy.order
        )

        return data
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  // Rows
  const makeRow = useCallback(
    ({
      id,
      name,
      storesUpdated,
      storesPercent,
      storesRemaining,
      storesCount,
      skusUpdated,
      skusPercent,
      skusRemaining,
      skusTotal,
      isCaptured,
      ownershipType,
      address,
      upc,
      price,
      packCount
    }) => {
      const shouldLinkTo =
        ['region', 'district', 'territory', 'customer'].includes(geography) ||
        (geography === 'sku' && sectorType === SECTOR_LEVELS.CUSTOMER)

      const link =
        geography === 'sku' && sectorType === SECTOR_LEVELS.CUSTOMER
          ? `/customer/${sectorId}/actions/pricing/${upc}`
          : `/${geography}/${id}/pace/amplify/price-capture`

      const packCountName = geography === 'sku' && packCount ? `${name} (${packCount}pk)` : name
      const compoundName = geography === 'sku' ? `${packCountName} - ${id}` : name

      const row = {
        name:
          geography === SECTOR_LEVELS.CUSTOMER ? (
            <StoreInfoTooltip
              displayName={name}
              customerName={name}
              erp={id}
              address={address}
              ownershipType={ownershipType}
              linkTo={link}
            />
          ) : (
            <GeoTableLineHeader name={compoundName} linkTo={shouldLinkTo ? link : null} />
          ),
        skusUpdated: (
          <DataCombined
            primary={skusUpdated ? (+skusUpdated).toLocaleString() : PLACEHOLDERS.NO_VALUE}
            secondary={`${
              skusPercent ? formatPercent(skusPercent, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT
            }`}
          />
        ),
        skusRemaining: skusRemaining ? (+skusRemaining).toLocaleString() : PLACEHOLDERS.NO_VALUE,
        skusTotal: skusTotal ? (+skusTotal).toLocaleString() : PLACEHOLDERS.NO_VALUE
      }

      if (sectorType === SECTOR_LEVELS.CUSTOMER) {
        row.captureStatus = isCaptured ? translate('app.updated') : translate('app.notUpdated')
        if (geography === 'sku') {
          row.price = formatCurrency(price)
        }
      }

      if (!(sectorType === SECTOR_LEVELS.CUSTOMER && geography === 'sku')) {
        row.storesUpdated = (
          <DataCombined
            primary={storesUpdated ? (+storesUpdated).toLocaleString() : PLACEHOLDERS.NO_VALUE}
            secondary={`${
              storesPercent ? formatPercent(storesPercent, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT
            }`}
          />
        )

        row.storesRemaining = storesRemaining ? (+storesRemaining).toLocaleString() : PLACEHOLDERS.NO_VALUE
        row.storesCount = storesCount ? (+storesCount).toLocaleString() : PLACEHOLDERS.NO_VALUE
      }

      return row
    },
    [geography, sectorType, sectorId]
  )

  const rows = useMemo(() => {
    if (!geographyData) return []

    return geographyData.map(makeRow)
  }, [geographyData, sectorType, sectorId, geography, productType, filters, isGeographyDataLoading, offset, sortBy])

  // Pagination
  const hasReachedLastPage = rows?.length < DEFAULT_TABLE_PAGE_SIZE

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(rows) || rows.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  // Columns
  const handleSorted = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const COLS = [
    {
      field: 'name',
      headerName: translate('app.geo.region')
    },
    {
      field: 'storesUpdated',
      headerName: translate('app.storesUpdated'),
      filter: 'footprint'
    },
    {
      field: 'storesRemaining',
      headerName: translate('app.storesRemaining'),
      filter: 'footprint'
    },
    {
      field: 'storesCount',
      headerName: translate('app.totalStores'),
      filter: 'footprint'
    },
    {
      field: 'skusUpdated',
      headerName: translate('app.skusUpdated'),
      filter: 'sku_completion'
    },
    {
      field: 'skusRemaining',
      headerName: translate('app.skusRemaining'),
      filter: 'sku_completion'
    },
    {
      field: 'skusTotal',
      headerName: translate('app.skusTotal'),
      filter: 'sku_completion'
    },
    {
      field: 'captureStatus',
      headerName: translate('common.status'),
      filter: 'sku_geography'
    },
    {
      field: 'price',
      headerName: translate('app.price'),
      filter: 'customer_sku_geography'
    }
  ]

  const columns = useMemo(() => {
    let filteredCols = [...COLS]
    filteredCols[0].headerName = translate(displayOptTableLabels(geography))

    if (sectorType !== SECTOR_LEVELS.CUSTOMER || geography !== 'sku') {
      filteredCols = filteredCols.filter((col) => col.filter !== 'customer_sku_geography')
    }

    if (sectorType !== SECTOR_LEVELS.CUSTOMER) {
      filteredCols = filteredCols.filter((col) => col.filter !== 'sku_geography')
    } else {
      filteredCols = filteredCols.filter((col) => col.filter !== 'footprint')
    }

    return filteredCols
  }, [geography, sectorType])

  // Geography
  const updateGeography = (newGeography) => {
    setGeography(newGeography)
    setPage(1)
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="price-capture-geo-toggle"
          includeChannel
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          includeFsa
          includeCity
          geography={geography}
          setGeography={updateGeography}
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          isLoading={areGeographyFiltersLoading}
          isDisabled={areGeographyFiltersLoading}
          filters={geographyFilters}
          appliedFilters={appliedFilters}
          onFiltersChange={(newFilters) => {
            // Merge with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFilters,
              ...newFilters
            }
            setFilters(updatedFilters)
            setPage(1)
          }}
        />
      ]}
      actions={
        !isGeographyDataLoading && (!hasReachedLastPage || page > 1)
          ? [
              <Pagination
                key="price-capture-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyDataLoading}
                disableNextButton={hasReachedLastPage}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyDataLoading}
        error={error}
        rows={rows}
        columns={columns}
        activeColumn={sortBy}
        onColumnClick={handleSorted}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object
}

export default GeographyTable
