import React, { useContext } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { bool, node, object, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Button from 'components/button/Button'
import IconButton from 'components/button/IconButton'
import Icon from 'components/Icon'

import { cn } from 'utils/styling'

import PerformanceTable from './PerformanceTable'

const AccordionItem = ({ category, summary, children, disabled }) => {
  return (
    <Accordion.Item value={category} className="divide-y divide-slate-900/10 overflow-hidden" disabled={disabled}>
      <Accordion.Trigger className="group flex w-full items-center justify-between gap-2 bg-slate-50 px-2 py-1 text-left font-medium text-slate-900 hover:bg-slate-100">
        <p className="text-slate-700">
          <span className="mr-1.5 font-semibold uppercase text-slate-900">{category}</span> {summary}
        </p>
        <div className="relative size-5 shrink-0">
          <div
            className={cn(
              'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-transform group-data-[state=open]:rotate-180',
              disabled && 'opacity-0'
            )}
          >
            <Icon icon="down-chevron" compact />
          </div>
        </div>
      </Accordion.Trigger>
      <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down ">
        {children}
      </Accordion.Content>
    </Accordion.Item>
  )
}

AccordionItem.propTypes = {
  category: string.isRequired,
  summary: string,
  children: node.isRequired,
  disabled: bool
}

const hasNoProducts = (data) => !data?.products
const hasNoTopOrBottomProducts = (data) => !data?.products?.top3?.length && !data?.products?.bottom3?.length
const hasEmptyProducts = (data) => !data?.products?.length

const hasNoDataForProductType = (data, type) => {
  if (!data) return true
  return data.type === 'sellout'
    ? hasNoProducts(data[type]) || hasNoTopOrBottomProducts(data[type])
    : hasNoProducts(data[type]) || hasEmptyProducts(data[type])
}

const SectionCard = ({ title, tip, data, amplifyLink }) => {
  const { translate } = useContext(LangContext)

  const noDataToDisplayFMC = hasNoDataForProductType(data, 'fmc')
  const noDataToDisplayVape = hasNoDataForProductType(data, 'vape')

  return (
    <div className="min-w-0 max-w-full rounded-md bg-white p-3 shadow-sm ring-1 ring-slate-900/10">
      <div className="flex w-full items-center justify-between gap-2">
        <h4 className="text-base font-medium text-slate-900 md:text-lg">{title}</h4>
        {amplifyLink && (
          <>
            <div className="max-md:hidden">
              <Button primary icon="three-stars" to={amplifyLink}>
                {translate('app.pace.amplify')}
              </Button>
            </div>
            <div className="md:hidden">
              <IconButton primary icon="three-stars" compact to={amplifyLink}>
                {translate('app.pace.amplify')}
              </IconButton>
            </div>
          </>
        )}
      </div>
      <hr className="my-2" />
      <div className="space-y-2">
        {tip && <p className="font-medium text-slate-900">{tip}</p>}

        <Accordion.Root
          type="single"
          collapsible
          className="divide-y divide-slate-900/10 rounded-md border border-slate-900/10"
        >
          <AccordionItem category="fmc" summary={data?.fmc?.summary} disabled={noDataToDisplayFMC}>
            {data?.fmc?.products && <PerformanceTable type={data.type} data={data.fmc.products} />}
          </AccordionItem>

          <AccordionItem category="vape" summary={data?.vape?.summary} disabled={noDataToDisplayVape}>
            {data?.vape?.products && <PerformanceTable type={data.type} data={data.vape.products} />}
          </AccordionItem>
        </Accordion.Root>
      </div>
    </div>
  )
}

export default SectionCard

SectionCard.propTypes = {
  title: string.isRequired,
  tip: string,
  data: object.isRequired,
  amplifyLink: string
}
