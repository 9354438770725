import { createAction } from 'redux-actions'

export const addSellinOrderCompletionToNation = createAction('Add Sell-in Order Completion to nation')
export const addSellinGeographyToNation = createAction('Add Sell-in Geography to nation')
export const addSellinRunRateToNation = createAction('Add Sell-in Run Rate to nation')
export const addDistroTrendedDataToNation = createAction('Add Distro Trended Data to nation')
export const addDistroBrandDataToNation = createAction('Add Distro Brand Data to nation')
export const addDistroMaterialDataToNation = createAction('Add Distro Material Data to nation')
export const addDistroGeographyDataToNation = createAction('Add Distro Geography Data to nation')
export const addDistroSummaryDataToNation = createAction('Add Distro Summary Data to nation')
export const addPlanningSellinValuesToNation = createAction('Add Planning Sell-in Values to nation')
export const addPlanningSelloutValuesToNation = createAction('Add Planning Sell-out Values to nation')
export const addPlanningHealthCheckValuesToNation = createAction('Add Planning Health Check Values to nation')
export const addPlanningPriceCheckValuesToNation = createAction('Add Planning Price Check Values to nation')
export const addPlanningEngagementValuesToNation = createAction('Add Planning Engagement Values to nation')
export const addPlanningCycleCampaignValuesToNation = createAction('Add Cycle Campaign Values to nation')

export const addAmplifySelloutValuesToNation = createAction('Add Amplify Sell-out Values to nation')
export const addAmplifySelloutFiltersValuesToNation = createAction('Add Amplify Sell-out Filters Values to nation')
export const addAmplifySelloutGeographyValuesToNation = createAction('Add Amplify Sell-out Geography Values to nation')
export const addAmplifyInventoryOosTrendingValuesToNation = createAction(
  'Add Amplify Inventory OOS Trending Values to nation'
)
export const addAmplifyInventoryOosBrandsValuesToNation = createAction(
  'Add Amplify Inventory OOS Brand Values to nation'
)
export const addAmplifyInventoryOosMaterialValuesToNation = createAction(
  'Add Amplify Inventory OOS Material Values to nation'
)
export const addAmplifyInventoryOosGeographyValuesToNation = createAction(
  'Add Amplify Inventory OOS Geography Values to nation'
)
export const addAmplifyInventoryOosFiltersValuesToNation = createAction(
  'Add Amplify Inventory OOS Filters Values to nation'
)

export const addAmplifyExtraHubTrendingValuesToNation = createAction('Add Amplify Extra Hub Trending Values to Nation')
export const addAmplifyExtraHubEngagementValuesToNation = createAction(
  'Add Amplify Extra Hub Engagement Values to Nation'
)
export const addAmplifyExtraHubGeographyValuesToNation = createAction(
  'Add Amplify Extra Hub Geography Values to Nation'
)
export const addAmplifyExtraHubFiltersValuesToNation = createAction('Add Amplify Extra Hub Filters Values to Nation')
export const addAmplifyExtraHubActivityValuesToNation = createAction('Add Amplify Extra Hub Activity Values to Nation')
export const addAmplifyPriceCaptureComplianceFootprintValuesToNation = createAction(
  'Add Amplify Price Capture Compliance Footprint Values to Nation'
)
export const addAmplifyPriceCaptureComplianceBrandCompletionValuesToNation = createAction(
  'Add Amplify Price Capture Compliance Brand Completion Values to Nation'
)
export const addAmplifyPriceCaptureComplianceFiltersValuesToNation = createAction(
  'Add Amplify Price Capture Compliance Filters Values to Nation'
)
export const addAmplifyPriceCaptureComplianceGeographyValuesToNation = createAction(
  'Add Amplify Price Capture Compliance Geography Values to Nation'
)
export const addAmplifyPriceComplianceStrategyComplianceValuesToNation = createAction(
  'Add Amplify Price Compliance Strategy Compliance Values to Nation'
)
export const addAmplifyPriceComplianceEdlpComplianceValuesToNation = createAction(
  'Add Amplify Price Compliance Edlp Compliance Values to Nation'
)
export const addAmplifyPriceComplianceGeographyComplianceValuesToNation = createAction(
  'Add Amplify Price Compliance Geography Compliance Values to Nation'
)
export const addAmplifyPriceComplianceGeographyFiltersValuesToNation = createAction(
  'Add Amplify Price Compliance Geography Filters Values to Nation'
)
