import React, { useContext } from 'react'

import LangContext from 'context/LangContext'

import Card from 'components/card'
import CheckForValidScope from 'components/guards/CheckForValidScope'

import { SECTOR_LEVELS } from 'utils/constants'

import ConsumerOfferContent from './ConsumerOfferContent'
import CustomerSlaContent from './CustomerSlaContent'

const ConsumerOfferView = () => {
  const { translate } = useContext(LangContext)

  const validScopes = [SECTOR_LEVELS.CUSTOMER]

  return (
    <CheckForValidScope pageName={translate('close.commercialOffers')} validScopes={validScopes}>
      <Card title={translate('close.customerSLA')} span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <CustomerSlaContent />
      </Card>
      <Card title={translate('close.consumerOffer')} span={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
        <ConsumerOfferContent />
      </Card>
    </CheckForValidScope>
  )
}

export default ConsumerOfferView
