import React, { useContext } from 'react'
import orderBy from 'lodash/orderBy'
import moment from 'moment'
import PropTypes from 'prop-types'

import LangContext from 'context/LangContext'

import BasicAccordion from 'components/accordion/BasicAccordion'
import IconButton from 'components/button/IconButton'
import EmptyState from 'components/EmptyState'

const ListReceipts = ({ receipts, openModal, colored = false }) => {
  const { translate } = useContext(LangContext)

  const fullName = (receipt) => {
    if (receipt?.uploadedByContact) return `${receipt.contact.firstname} ${receipt.contact.lastname}`
    if (receipt?.uploadedBy) return `${receipt.employee.firstName} ${receipt.employee.lastName}`

    return translate('components.Extrahub.ListReceipts.unknownContact')
  }

  const statusColor = (status) => {
    if (colored) {
      if (status === 'Invalid') return 'text-orange-500'
      if (status === 'Rejected') return 'text-red-500'
      if (status === 'Valid' || status === 'Approved') return 'text-green-500'
      if (status === 'InsufficientBudget' || status === 'PaymentFailed') return 'text-blue-500'
    }
    return ''
  }

  return (
    <>
      <BasicAccordion title={translate('components.Extrahub.ListReceipts.titleAccordion')}>
        {receipts?.length ? (
          <table className="w-full">
            <tbody>
              {orderBy(receipts, 'createdAt', 'desc').map((receipt) => (
                <tr key={receipt.id}>
                  <td>
                    {`${fullName(receipt)} ${translate('common.on')} ${moment(receipt.createdAt).format(
                      'YYYY-MM-DD'
                    )} `}
                    [<span className={statusColor(receipt.status)}>{receipt.status}</span>]
                  </td>

                  {openModal && (
                    <td className="text-right">
                      <IconButton ghost compact icon="document-scanner" onClick={() => openModal(receipt)} />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyState
            title={translate('components.Extrahub.ListReceipts.EmptyState.title')}
            subtitle={translate('components.Extrahub.ListReceipts.EmptyState.subtitle')}
          />
        )}
      </BasicAccordion>
    </>
  )
}

ListReceipts.propTypes = {
  receipts: PropTypes.array,
  openModal: PropTypes.func,
  colored: PropTypes.bool
}

export default ListReceipts
