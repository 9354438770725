import React, { useContext, useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import { Command } from 'cmdk'
import { array, bool, func, number, object, oneOfType, string } from 'prop-types'

import LangContext from 'context/LangContext'

import Hint from 'components/Hint'
import Icon from 'components/Icon'
import Label from 'components/Label'

import { getMeta } from 'utils/helpers'
import { cn } from 'utils/styling'

import { popoverContentAnimationClasses } from 'styles/global'

const itemSelectionClasses =
  "data-[disabled=true]:pointer-events-none data-[selected='true']:bg-brand-50 data-[selected=true]:text-brand-800 data-[disabled=true]:opacity-50 cursor-pointer"

function FilteredDropdown({
  options,
  searchPlaceholder,
  noResultsCustomMessage,
  label,
  secondary,
  disabled,
  hint,
  renderWithoutOptions,
  optionLabel = 'label',
  optionValue = 'value',
  shouldTranslate = true,
  shouldUpperCase = false,
  error,
  minW,
  onChange,
  onClose = () => null,
  allowMultiSelect = false,
  value,
  ...rest
}) {
  const { translate } = useContext(LangContext)

  const [open, setOpen] = useState(false)

  if ((!options || !options.length) && !renderWithoutOptions) return null

  const meta = getMeta(rest) || {}

  const hasNoSelectedOptions = value.length === 0

  const processOptionLabel = (optionLabel) => {
    let label = optionLabel

    if (shouldTranslate) {
      label = translate(optionLabel) ?? optionLabel
    }

    if (shouldUpperCase) {
      label = label.toUpperCase()
    }

    return label
  }

  const getDisplayText = () => {
    if (!allowMultiSelect) {
      return options?.find(({ ...opt }) => opt[optionValue] === value)?.label
    }

    const selectedValues = Array.isArray(value) ? value : []

    if (selectedValues.length === 0) return ''
    if (selectedValues.length === 1) {
      return options?.find(({ ...opt }) => opt[optionValue] === selectedValues[0])?.label
    }

    const count = selectedValues.length
    return count > 10 ? '10+ Selected' : `${count} Selected`
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <div className={minW ? 'min-w-min' : 'max-w-full'}>
        <div className={cn('notranslate', !minW && 'w-full')} translate="no">
          {label && (
            <Label>
              {translate(label)}
              {hint && <Hint hint={hint} />}
            </Label>
          )}

          <Popover.Trigger asChild>
            <button
              role="combobox"
              aria-expanded={open}
              disabled={disabled || !options?.length}
              className={cn(
                'flex h-9 w-full items-center justify-between rounded-md border-0 py-2 align-bottom text-sm font-medium text-slate-900 outline-0 transition-all focus-visible:bg-white focus-visible:shadow-md focus-visible:ring-2 focus-visible:ring-brand-500 disabled:opacity-60 data-[state=open]:bg-white data-[state=open]:shadow-md data-[state=open]:ring-2 data-[state=open]:ring-brand-500',
                secondary ? 'bg-slate-200' : 'bg-slate-500/5',
                error && 'ring-2 ring-red-500'
              )}
            >
              <span className="w-full truncate pl-3 pr-6 text-left">{getDisplayText()}</span>
              <div className="pr-2 text-gray-500">
                <Icon icon="down-chevron-form" compact />
              </div>
            </button>
          </Popover.Trigger>

          {meta.error && meta.touched && !meta.active && (
            <small className="mt-2 text-2xs text-red-500">{meta.error}</small>
          )}
        </div>
      </div>
      <Popover.Portal>
        <Popover.Content
          side="bottom"
          sideOffset={8}
          className={cn(
            'z-[300] min-w-[--radix-popover-trigger-width] bg-white shadow-md ring-1 ring-slate-900/10',
            popoverContentAnimationClasses
          )}
          onCloseAutoFocus={() => onClose()}
          asChild
        >
          <Command
            loop
            className="bg-popover text-popover-foreground flex h-full w-full max-w-[--radix-popover-trigger-width] flex-col overflow-hidden rounded-md"
          >
            <div className="flex items-center border-b border-b-slate-900/10">
              <Command.Input
                placeholder={(searchPlaceholder || translate('common.search')) + '...'}
                className="flex h-11 w-full border-0 bg-transparent py-3 text-sm placeholder:text-slate-500 focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50"
              />
              <div className="pr-2 text-slate-500">
                <Icon icon="search" compact />
              </div>
            </div>
            <Command.List className="max-h-[300px] overflow-x-hidden">
              <Command.Empty className="py-6 text-center text-sm text-slate-500">
                {(noResultsCustomMessage || translate('common.noResultsFound')) + '...'}
              </Command.Empty>

              {allowMultiSelect && (
                <Command.Group
                  heading={translate('common.selected')}
                  className="sticky top-0 z-10 border-b border-b-slate-900/10 bg-white p-1 [&>[cmdk-group-heading]]:my-1 [&>[cmdk-group-heading]]:pl-2 [&>[cmdk-group-heading]]:text-2xs [&>[cmdk-group-heading]]:font-medium [&>[cmdk-group-heading]]:text-slate-400"
                >
                  <div className="my-1 flex flex-wrap gap-1">
                    {hasNoSelectedOptions && (
                      <p className="h-6 w-full text-center text-xs text-slate-500">
                        {translate('app.noSelectedOptions')}
                      </p>
                    )}
                    {value.map((selectedValue) => {
                      const selectedOption = options?.find((opt) => opt[optionValue] === selectedValue)

                      if (!selectedOption) return null
                      return (
                        <div
                          key={selectedValue}
                          className="flex items-center gap-0.5 rounded-full bg-brand-50 pl-2 text-sm leading-[16px] text-brand-700"
                        >
                          <span className="max-w-32 select-none truncate">
                            {processOptionLabel(selectedOption[optionLabel])}
                          </span>
                          <button
                            type="button"
                            className="flex size-6 shrink-0 items-center justify-center p-1 pr-1.5 text-brand-900/30 transition-colors hover:text-brand-900"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              const newValues = value.filter((v) => v !== selectedValue)
                              onChange?.(newValues)
                            }}
                          >
                            <Icon icon="close" small />
                          </button>
                        </div>
                      )
                    })}
                  </div>
                </Command.Group>
              )}
              <Command.Group
                className="text-foreground overflow-y-auto p-1 [&>[cmdk-group-heading]]:my-1 [&>[cmdk-group-heading]]:pl-2 [&>[cmdk-group-heading]]:text-2xs [&>[cmdk-group-heading]]:font-medium [&>[cmdk-group-heading]]:text-slate-400"
                heading={allowMultiSelect ? translate('common.all') : undefined}
              >
                {options?.map(({ disabled = false, ...opt }, i) => {
                  const labelOfOption = opt[optionLabel]
                  const valueOfOption = opt[optionValue]

                  if (!labelOfOption || !valueOfOption) return null

                  return (
                    <Command.Item
                      disabled={disabled}
                      key={i}
                      keywords={[labelOfOption.toString(), valueOfOption.toString()]}
                      value={valueOfOption}
                      onSelect={() => {
                        if (allowMultiSelect) {
                          const currentValues = Array.isArray(value) ? value : []
                          const newValues = currentValues.includes(valueOfOption)
                            ? currentValues.filter((v) => v !== valueOfOption)
                            : [...currentValues, valueOfOption]
                          onChange?.(newValues)
                        } else {
                          setOpen(false)
                          onChange?.(valueOfOption)
                        }
                      }}
                      className={cn(
                        'relative flex select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none',
                        itemSelectionClasses
                      )}
                    >
                      <span
                        className={cn(
                          'w-full truncate',
                          allowMultiSelect
                            ? Array.isArray(value) && value.includes(valueOfOption)
                              ? 'text-brand-700'
                              : ''
                            : value === valueOfOption
                            ? 'text-brand-700'
                            : ''
                        )}
                      >
                        {processOptionLabel(labelOfOption)}
                      </span>
                      {(allowMultiSelect
                        ? Array.isArray(value) && value.includes(valueOfOption)
                        : value === valueOfOption) && (
                        <div className="ml-3 h-5 w-5 shrink-0 text-brand-700">
                          <Icon icon="checkmark-small" compact />
                        </div>
                      )}
                    </Command.Item>
                  )
                })}
              </Command.Group>
            </Command.List>
          </Command>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

FilteredDropdown.propTypes = {
  options: array.isRequired,
  onChange: func.isRequired,
  onClose: func,
  label: string,
  searchPlaceholder: string,
  noResultsCustomMessage: string,
  value: oneOfType([string, number, array]),
  secondary: bool,
  tertiary: bool,
  input: object,
  meta: object,
  disabled: bool,
  hint: string,
  renderWithoutOptions: bool,
  optionLabel: string,
  optionValue: string,
  shouldTranslate: bool,
  shouldUpperCase: bool,
  error: bool,
  minW: bool,
  allowMultiSelect: bool
}

export default FilteredDropdown
