import queryString from 'query-string'

import { api } from 'store/api'

export const fetchDistroTrended = (details) => api.get(`/distro/trended`, { params: details })
export const fetchDistroBrand = (details) => api.get(`/distro/brand`, { params: details })
export const fetchDistroMaterial = (details) => api.get(`/distro/material`, { params: details })
export const fetchDistroSummary = (details) => api.get(`/distro/summary`, { params: details })
export const fetchDistroGeography = ({ sectorType, sectorId, ...queryStrings }) =>
  api.get(`/distro/${sectorType}/${sectorId}/geography?${queryString.stringify(queryStrings)}`)
export const fetchDistroFilterOptions = ({ sectorType, sectorId, ...queryStrings }) =>
  api.get(`/distro/${sectorType}/${sectorId}/filters?${queryString.stringify(queryStrings)}`)
