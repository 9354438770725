export const trendingReducer = (state, { payload: { id, trending, productType, vapeCategory } }) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        trending: {
          ...state[id]?.inventory?.trending,
          [`${productType}-${vapeCategory}`]: trending
        }
      }
    }
  })
}

export const brandReducer = (
  state,
  { payload: { id, productType, timeframe, brands, vapeCategory, sortBy, direction } }
) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        brands: {
          ...state[id]?.inventory?.brands,
          [`${productType}-${vapeCategory}-${timeframe}-${sortBy}-${direction}`]: brands
        }
      }
    }
  })
}

export const materialReducer = (
  state,
  { payload: { id, productType, timeframe, materials, vapeCategory, sortBy, direction } }
) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        materials: {
          ...state[id]?.inventory?.materials,
          [`${productType}-${vapeCategory}-${timeframe}-${sortBy}-${direction}`]: materials
        }
      }
    }
  })
}

export const geographyReducer = (
  state,
  { payload: { id, geographies, geography, filters, productType, timeframe, vapeCategory, offset, sortBy, direction } }
) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        geographies: {
          ...state[id]?.inventory?.geographies,
          [`${geography}-${Object.values(filters).join(
            '-'
          )}-${productType}-${vapeCategory}-${timeframe}-${offset}-${sortBy}-${direction}`]: geographies
        }
      }
    }
  })
}

export const filtersReducer = (state, { payload: { id, filters, productType, vapeCategory } }) => {
  return Object.assign({}, state, {
    [id]: {
      ...state[id],
      inventory: {
        ...state[id]?.inventory,
        filters: {
          ...state[id]?.inventory?.filters,
          [`${productType}-${vapeCategory}`]: filters
        }
      }
    }
  })
}
