import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import LangContext from 'context/LangContext'

import {
  closePerformanceSessionActivated,
  closePerformanceSessionInitialActions,
  customerPrograms,
  incompleteCustomerSellInPrograms,
  incompleteCustomerSurveys
} from 'store/customers/selectors'
import { getNonComplianceForCustomer } from 'store/priceCaptureCompliance/selectors'
import { pendingCustomerPricing } from 'store/pricings/selectors'

import ActionTable from 'components/evaluate/ActionTable'

const Actions = () => {
  const { sectorId: customerId } = useParams()
  const { translate } = useContext(LangContext)

  const closePerformanceSessionIsActive = useSelector((state) =>
    closePerformanceSessionActivated(state, { customerId })
  )

  const initialActions = useSelector((state) => closePerformanceSessionInitialActions(state, { customerId }))

  const activeSellInProgramsQty = useSelector((state) => incompleteCustomerSellInPrograms(state, { customerId })).length
  const pendingPriceCapturesQty = useSelector((state) => pendingCustomerPricing(state, { customerId })).length
  const nonComplianceForCustomerQty = useSelector((state) => getNonComplianceForCustomer(state)).length
  const activeSurveysQty = useSelector((state) => incompleteCustomerSurveys(state, { customerId })).length
  const activeGoFundPogramsQty = useSelector((state) => customerPrograms(state, { customerId })).current.length

  if (!closePerformanceSessionIsActive) return translate('evaluate.objectives.noActions')

  const objectives = {
    sellIn: {
      title: translate('app.sellIn'),
      completed: initialActions.sellin.length - activeSellInProgramsQty,
      total: initialActions.sellin.length
    },
    priceCheck: {
      title: translate('app.priceCheck'),
      completed: initialActions.priceCheck.length - pendingPriceCapturesQty,
      total: initialActions.priceCheck.length
    },
    priceCompliance: {
      title: translate('app.priceCompliance').slice(0, 10),
      completed: initialActions.priceCompliance.length - nonComplianceForCustomerQty,
      total: initialActions.priceCompliance.length
    },
    surveys: {
      title: translate('app.surveys'),
      completed: initialActions.surveys.length - activeSurveysQty,
      total: initialActions.surveys.length
    },
    extraFunds: {
      title: translate('app.extraFunds'),
      completed: initialActions.extraFunds.length - activeGoFundPogramsQty,
      total: initialActions.extraFunds.length
    }
  }

  const noObjectives = !Object.values(objectives).some((objective) => objective.total > 0)

  return (
    <div className="flex flex-col gap-6">
      <h3 className="text-lg font-medium leading-snug">{translate('evaluate.objectives.todaysVisit')}</h3>
      <div className="space-between flex gap-4">
        {noObjectives
          ? translate('close.objectives.actions.noActions')
          : Object.entries(objectives).reduce(([key, objective], acc) => {
              if (objective.total > 0) return acc
              return (
                <div key={key} className="flex items-center justify-between">
                  <ActionTable
                    key={key}
                    variant={key}
                    title={objective.title}
                    completed={objective.completed}
                    total={objective.total}
                  />
                </div>
              )
            }, [])}
      </div>
    </div>
  )
}

export default Actions
