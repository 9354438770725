import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import moment from 'moment'

import { STATUS } from 'utils/constants'
import { parseNumberString } from 'utils/helpers'

const DEFAULT_DECIMAL_PLACES = 2

export const formatPhone = (phone) => {
  if (!phone) return ''

  const onlyNumbers = phone.replace(/\D/, '')
  const last4 = onlyNumbers.slice(-4)
  const first3 = onlyNumbers.slice(-7, -4)
  const areaCode = onlyNumbers.slice(-10, -7)
  const countryCode = onlyNumbers.slice(-11, -10) || '1'

  return `+${countryCode}-${areaCode}-${first3}-${last4}`
}

export const composeGraphData = (performanceToDate, preferredLanguage) => {
  const labels = performanceToDate.series.map(({ name }) => name[preferredLanguage])

  return {
    labels,
    data: performanceToDate.weeks.map((week, weekIndex) =>
      performanceToDate.series.reduce((acc, { values }, i) => ({ ...acc, [`${labels[i]}`]: values[weekIndex] }), {
        week: moment(week).format('DD-MMM')
      })
    )
  }
}

export const formatIntelGroupTitle = (grouping) => {
  const [category, manufacturer, brand, type] = grouping.split('|')

  return `${category}: ${manufacturer} / ${brand} - ${type.split(' ').join('\xa0')}` // nbsp in whitespaces of type to keep it on one line
}

export const getTotalProfitPerWeek = (priceObj) => {
  if (!priceObj || !priceObj.price || !priceObj.netCustomerPrice || !priceObj.storeAwr) return ''

  return '$' + ((priceObj.price - priceObj.netCustomerPrice) * priceObj.storeAwr).toFixed(2)
}

export const secondsToMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60)
    .toFixed()
    .padStart(2, 0)
  const leftoverSeconds = Math.floor(seconds % 60)
    .toFixed()
    .padStart(2, 0)

  return `${minutes}:${leftoverSeconds}`
}

export const getExceptionType = (record) => {
  if (!record) return ''

  const termsException = Boolean(record.questions) && 'Terms/Tier'
  const skusException = Boolean(record.skus) && 'SKU min/max'
  const statusException = Boolean(record.requestedStatus || record.type === STATUS) && 'Status'

  return [statusException, termsException, skusException].filter(Boolean).join(' & ')
}

/**
 * Formats the inputed number to a standardized decimal format
 * @param {number} val The value to format
 * @param {object} opts
 * @param {number} opts.decimalPlaces How many decimals we want. Defaults to 2
 * @param {boolean} opts.convertDecimal Whether or not we multiply by a factor of 100
 * @param {boolean} opts.forceDecimal Force decimals when return value is 0
 * @param {boolean} opts.ignorePercentSign Whether or not we should forgo appending '%' sign. Defaults to true
 */
export function formatPercent(value, opts = {}) {
  const {
    convertDecimal,
    decimalPlaces = DEFAULT_DECIMAL_PLACES,
    ignorePercentSign = false,
    forceDecimal,
    nullDisplay
  } = opts

  if (isNaN(value)) return null
  if (isNull(value)) return nullDisplay || null

  const factor = convertDecimal ? 100 : 1
  const computedValue = value * factor

  const formatted = computedValue !== 0 || forceDecimal ? computedValue.toFixed(decimalPlaces) : 0

  if (ignorePercentSign) return formatted
  return `${formatted}%`
}

export function formatCurrency(val, opts = null) {
  if (isNaN(val)) return null
  if (!isUndefined(opts?.nullDisplay) && isNull(val)) return opts.nullDisplay
  const decimalPlaces = opts?.decimalPlaces !== null ? opts?.decimalPlaces : DEFAULT_DECIMAL_PLACES
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: decimalPlaces,
    ...opts
  }).format(val)
}

export function formatCompactCurrency(val, opts = null) {
  if (isNaN(val)) return null
  if (!isUndefined(opts?.nullDisplay) && isNull(val)) return opts.nullDisplay
  const decimalPlaces = val === 0 && !opts?.forceDecimal ? 0 : opts?.decimalPlaces || DEFAULT_DECIMAL_PLACES
  const trailingZeroDisplay = opts?.shouldStripIfInteger ? 'stripIfInteger' : 'auto'

  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
    notation: 'compact',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    trailingZeroDisplay,
    ...opts
  }).format(val)
}

export function formatCompactNumber(val, opts = null) {
  if (isNaN(val)) return null
  if (!isUndefined(opts?.nullDisplay) && isNull(val)) return opts.nullDisplay
  const decimalPlaces = val !== 0 && !opts?.forceDecimal ? 0 : opts?.decimalPlaces || DEFAULT_DECIMAL_PLACES
  const trailingZeroDisplay = opts?.shouldStripIfInteger ? 'stripIfInteger' : 'auto'

  return new Intl.NumberFormat('en-CA', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    trailingZeroDisplay,
    ...opts
  }).format(val)
}

export function formatNumber(val, opts = null) {
  if (isNaN(val)) return null
  if (!isUndefined(opts?.nullDisplay) && isNull(val)) return opts.nullDisplay
  return new Intl.NumberFormat('en-CA', {
    minimumFractionDigits: opts?.forceDecimals ? 2 : 1,
    maximumFractionDigits: 2,
    trailingZeroDisplay: opts?.forceDecimals ? undefined : 'stripIfInteger'
  }).format(val)
}

export function formatGap(gap, formatterCallback = null) {
  const formatter = formatterCallback ?? parseNumberString

  return `${gap > 0 ? '+' : ''}${!isNaN(gap) ? formatter(gap) : '-'}`
}
