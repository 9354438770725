import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import LangContext from 'context/LangContext'

import { closePerformanceSessionActivated } from 'store/customers/selectors'

import ItemWithRating from 'components/evaluate/ItemWithRating'

const PerformanceReview = () => {
  const { sectorId: customerId } = useParams()
  const { translate } = useContext(LangContext)

  const closePerformanceSessionIsActive = useSelector((state) =>
    closePerformanceSessionActivated(state, { customerId })
  )

  const initializeRatings = () => {
    const labels = [
      'Race to Cycle Target',
      'Spotting Hidden Opportunities',
      'Unlock The Sell Out Surge',
      'Fine Tune Pricing Opportunities'
    ]
    return labels.map((label) => ({ label, rating: null }))
  }

  const [performance, setPerformance] = useState(initializeRatings())

  if (!closePerformanceSessionIsActive) return

  const handleRating = (label, newRating) => {
    setPerformance((prevPerformance) =>
      prevPerformance.map((item) =>
        item.label === label ? { ...item, rating: item.rating === newRating ? null : newRating } : item
      )
    )
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <h3 className="text-lg font-medium leading-snug">{translate('evaluate.objectives.performance')}</h3>
        <div className="flex flex-col gap-3 pl-4">
          {performance.map((item) => (
            <ItemWithRating
              key={item.label}
              item={item.label}
              rating={item.rating}
              handleRating={(newRating) => handleRating(item.label, newRating)}
            />
          ))}
        </div>
      </div>

      <hr className="border-slate-200" />
    </>
  )
}

export default PerformanceReview
