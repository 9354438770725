import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { number, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import * as api from 'store/distro/endpoints'

import Card from 'components/card'
import DataCompare from 'components/DataTable/DataCompare'
import DataGraph from 'components/DataTable/DataGraph'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Pagination from 'components/Pagination'

import { DEFAULT_TABLE_PAGE_SIZE, GEOGRAPHY_VALUES, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatNumber, formatPercent } from 'utils/formatters'
import { GEOGRAPHY_FILTER_CATEGORIES, getGenericGeographyFilters } from 'utils/genericGeographyFilters'
import { getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

import DistroContext from './DistroContext'

function toFixed(value) {
  return Math.round((value || 0) * 100) / 100
}

const defaultFilters = {
  region: [],
  district: [],
  territory: [],
  kaSubregion: [],
  headoffice: [],
  banner: [],
  province: [],
  tier: [],
  ownershipType: [],
  outletSubType: [],
  store: [],
  fsa: [],
  city: [],
  brand: [],
  brandVariant: [],
  length: [],
  size: [],
  productDescription: [],
  storesDistro: 'all',
  productsDistro: 'all'
}

const AmplifyDistroTableCard = ({ span, vapeCategory = 'all' }) => {
  const { translate } = useContext(LangContext)
  const { currentProductType } = useContext(SectorContext)
  const { vapeChannel, period } = useContext(DistroContext)
  const { sectorType, sectorId } = useParams()

  const [filters, setFilters] = useState(defaultFilters)
  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'weighteddistro', order: SORTING_DIRECTION.DESC })

  // Pagination
  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  useEffect(() => {
    setPage(1)
  }, [sectorId, sectorType, currentProductType, geography, vapeCategory, vapeChannel, sortBy])

  // Columns
  const columns = [
    {
      field: 'geo',
      headerName: translate('app.geo.region')
    },
    sectorType !== SECTOR_LEVELS.CUSTOMER && {
      field: 'stores',
      headerName: translate('app.storesNotInDistro')
    },
    sectorType !== SECTOR_LEVELS.CUSTOMER && {
      field: 'storesTotal',
      headerName: translate('app.totalStores')
    },
    sectorType !== SECTOR_LEVELS.CUSTOMER && {
      field: 'storedistro',
      headerName: translate('app.storeDistro')
    },
    {
      field: 'skus',
      headerName: translate('app.SKUNotInDistro')
    },
    {
      field: 'skusTotal',
      headerName: translate('app.totalSKUs')
    },
    {
      field: 'skudistro',
      headerName: translate('app.SKUDistro')
    },
    ![SECTOR_LEVELS.CUSTOMER, SECTOR_LEVELS.TERRITORY].includes(sectorType) && {
      field: 'weighteddistro',
      headerName: translate('app.wdistro')
    },
    sectorType !== SECTOR_LEVELS.CUSTOMER && {
      field: 'salescontribution',
      headerName: translate('app.salesContribution')
    },
    geography !== SECTOR_LEVELS.CUSTOMER &&
      sectorType !== SECTOR_LEVELS.CUSTOMER && {
        field: 'weightedL13',
        headerName: translate('app.weightedDistroTrend'),
        isLarge: true
      },
    geography !== SECTOR_LEVELS.CUSTOMER &&
      sectorType === SECTOR_LEVELS.CUSTOMER && {
        field: 'skuL13',
        headerName: translate('app.SKUDistroTrend'),
        isLarge: true
      }
  ].filter(Boolean)

  columns[0].headerName = translate(displayOptTableLabels(geography))

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  // Geography filters
  const { data: geographyFiltersOptions, isLoading: areGeographyFiltersLoading } = useQuery({
    queryKey: ['distroGeographyFiltersOptions', sectorType, sectorId, currentProductType, vapeCategory, vapeChannel],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const { data } = await api.fetchDistroFilterOptions({
          sectorType,
          sectorId,
          productType: currentProductType,
          vapeCategory,
          vapeChannel
        })

        return data
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
  }

  const geographyFilters = useMemo(() => {
    const { storesDistro, productsDistro, ...values } = filters

    const options = {
      ...geographyFiltersOptions
    }

    const changeEventHandlers = {
      regionHandler: (value) => handleFilterChange({ region: value }),
      districtHandler: (value) => handleFilterChange({ district: value }),
      territoryHandler: (value) => handleFilterChange({ territory: value }),
      kaSubregionHandler: (value) => handleFilterChange({ kaSubregion: value }),
      headofficeHandler: (value) => handleFilterChange({ headoffice: value }),
      bannerHandler: (value) => handleFilterChange({ banner: value }),
      provinceHandler: (value) => handleFilterChange({ province: value }),
      tierHandler: (value) => handleFilterChange({ tier: value }),
      ownershipTypeHandler: (value) => handleFilterChange({ ownershipType: value }),
      outletSubtypeHandler: (value) => handleFilterChange({ outletSubtype: value }),
      storeHandler: (value) => handleFilterChange({ store: value }),
      fsaHandler: (value) => handleFilterChange({ fsa: value }),
      cityHandler: (value) => handleFilterChange({ city: value }),
      brandHandler: (value) => handleFilterChange({ brand: value }),
      brandVariantHandler: (value) => handleFilterChange({ brandVariant: value }),
      lengthHandler: (value) => handleFilterChange({ length: value }),
      sizeHandler: (value) => handleFilterChange({ size: value }),
      productDescriptionHandler: (value) => handleFilterChange({ productDescription: value })
    }

    const genericGeographyFilters = getGenericGeographyFilters(
      values,
      options,
      changeEventHandlers,
      translate,
      sectorType,
      currentProductType
    )

    const storesDistroFilter =
      geography === GEOGRAPHY_VALUES.CUSTOMER
        ? [
            {
              id: 'storesDistro',
              label: translate('amplify.distro.filters.storesDistro'),
              searchPlaceholder: translate('app.placeholders.searchStoresDistro'),
              options: [
                {
                  value: 'storesFullyInDistro',
                  label: translate('amplify.distro.filters.storesDistro.full')
                },
                {
                  value: 'storesNotFullyInDistro',
                  label: translate('amplify.distro.filters.storesDistro.not.full')
                }
              ],
              value: storesDistro,
              category: GEOGRAPHY_FILTER_CATEGORIES.SINGLE,
              onChange: (value) => handleFilterChange({ storesDistro: value })
            }
          ]
        : []

    const productsDistroFilter =
      geography === GEOGRAPHY_VALUES.SKU
        ? [
            {
              id: 'productsDistro',
              label: translate('amplify.distro.filters.productsDistro'),
              searchPlaceholder: translate('app.placeholders.searchProductsDistro'),
              options: [
                {
                  value: 'productsFullyInDistro',
                  label: translate('amplify.distro.filters.productsDistro.full')
                },
                {
                  value: 'productsNotFullyInDistro',
                  label: translate('amplify.distro.filters.productsDistro.not.full')
                }
              ],
              value: productsDistro,
              category: GEOGRAPHY_FILTER_CATEGORIES.SINGLE,
              onChange: (value) => handleFilterChange({ productsDistro: value })
            }
          ]
        : []

    return [...genericGeographyFilters, ...storesDistroFilter, ...productsDistroFilter]
  }, [geography, areGeographyFiltersLoading, sectorType, currentProductType, translate])

  const appliedFilters = Object.values(filters).filter((value) => value.length !== 0).length

  // NOTE: Resets filters every time a new product type or a new scope is selected
  useEffect(() => {
    setFilters(defaultFilters)
  }, [sectorType, sectorId, currentProductType])

  // NOTE: Resets storesDistro and productsDistro filter when geography changes (These options are only available for specifics geographies)
  useEffect(() => {
    setFilters({
      ...filters,
      storesDistro: defaultFilters.storesDistro,
      productsDistro: defaultFilters.productsDistro
    })
  }, [geography])

  // Geography data
  const { data: geographyData, isLoading: isGeographyDataLoading } = useQuery({
    queryKey: [
      'distroGeographyData',
      sectorType,
      sectorId,
      geography,
      currentProductType,
      vapeCategory,
      vapeChannel,
      offset,
      filters,
      sortBy,
      period
    ],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const {
          data: { result }
        } = await api.fetchDistroGeography({
          sectorId,
          sectorType,
          geography,
          productType: currentProductType,
          vapeCategory,
          vapeChannel,
          offset,
          ...filters,
          limit: DEFAULT_TABLE_PAGE_SIZE,
          sortBy: sortBy.column,
          direction: sortBy.order,
          period
        })

        const processedData = result.map((item) => {
          let linkTo
          if (
            [SECTOR_LEVELS.REGION, SECTOR_LEVELS.DISTRICT, SECTOR_LEVELS.TERRITORY, SECTOR_LEVELS.CUSTOMER].includes(
              geography
            )
          ) {
            linkTo = `/${geography}/${item.id}/pace/amplify/distro`
          }
          return {
            ...item,
            linkTo
          }
        })
        return processedData
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  // Rows
  const rows = useMemo(() => {
    if (!geographyData) return []

    return geographyData.map(
      ({
        id,
        linkTo,
        name,
        customerName,
        erp,
        address,
        ownershipType,
        nbStoresNotInDistro,
        nbStoresTotal,
        storeDistro,
        storeDistroDiff,
        nbSkusTotal,
        nbSkusNotInDistro,
        skuDistro,
        skuDistroDiff,
        salesDistro,
        salesDistroDiff,
        salesContribution,
        salesContributionDiff,
        weightedL13,
        skuL13
      }) => {
        const displayName = geography === 'sku' ? `${name} - ${id}` : name

        return {
          geo:
            geography === SECTOR_LEVELS.CUSTOMER ? (
              <StoreInfoTooltip
                displayName={displayName}
                customerName={customerName}
                erp={erp}
                address={address}
                ownershipType={ownershipType}
                linkTo={linkTo}
              />
            ) : (
              <GeoTableLineHeader name={displayName} linkTo={linkTo} />
            ),
          stores: formatNumber(nbStoresNotInDistro),
          storesTotal: formatNumber(nbStoresTotal),
          storedistro: <DataCompare last={toFixed(storeDistro)} variation={storeDistroDiff} isPercent />,
          skus: formatNumber(nbSkusNotInDistro),
          skusTotal: formatNumber(nbSkusTotal),
          skudistro: <DataCompare last={toFixed(skuDistro)} variation={skuDistroDiff} isPercent />,
          weighteddistro: <DataCompare last={toFixed(salesDistro)} variation={salesDistroDiff} isPercent />,
          salescontribution: (
            <DataCompare last={toFixed(salesContribution)} variation={salesContributionDiff} isPercent />
          ),
          weightedL13: (
            <DataGraph
              color="#53CCF8"
              data={weightedL13}
              dataFormatter={(v) => formatPercent(v, { convertDecimal: false })}
            />
          ),
          skuL13: (
            <DataGraph
              color="#53CCF8"
              data={skuL13}
              dataFormatter={(v) => formatPercent(v, { convertDecimal: false })}
            />
          )
        }
      }
    )
  }, [geographyData])

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="geography-toggle"
          includeBrand
          includeOwnership
          includeSku
          includeBrandVariant
          includeFsa
          includeCity
          geography={geography}
          setGeography={setGeography}
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          isDisabled={areGeographyFiltersLoading}
          isLoading={areGeographyFiltersLoading}
          filters={geographyFilters}
          appliedFilters={appliedFilters}
          onFiltersChange={(newFilters) => {
            // Merges with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFilters,
              ...newFilters
            }
            setFilters(updatedFilters)
            setPage(1)
          }}
        />
      ]}
      actions={
        !isGeographyDataLoading && (rows.length >= DEFAULT_TABLE_PAGE_SIZE || page > 1)
          ? [
              <Pagination
                key="pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyDataLoading}
                disableNextButton={rows.length < DEFAULT_TABLE_PAGE_SIZE}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyDataLoading}
        error={error}
        rows={rows}
        onColumnClick={handleSort}
        activeColumn={sortBy}
        unClickableColumns={['weightedL13', 'skuL13']}
        columns={columns}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

AmplifyDistroTableCard.propTypes = {
  span: number,
  vapeCategory: string
}

export default AmplifyDistroTableCard
