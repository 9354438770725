import orderBy from 'lodash/orderBy'

import { CHANNEL_VALUES, PRODUCT_TYPE_VALUES, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'

export const GEOGRAPHY_FILTER_CATEGORIES = {
  SINGLE: 'single',
  MULTI: 'multi'
}

export const GEOGRAPHY_FILTER_HIERARCHY_TYPES = {
  CUSTOMER: 'customer',
  PRODUCT: 'product'
}

export function getCustomersGenericGeographyFilters(
  values,
  options,
  changeEventHandlers,
  translate,
  sectorType,
  includeOutletSubTypeFilters = true
) {
  const isTerritoryScope = sectorType === SECTOR_LEVELS.TERRITORY
  const isDistrictScope = sectorType === SECTOR_LEVELS.DISTRICT
  const isRegionScope = sectorType === SECTOR_LEVELS.REGION
  const isNationalScope = sectorType === SECTOR_LEVELS.NATIONAL

  const nationalFilters = isNationalScope
    ? [
        {
          id: 'region',
          label: translate('app.region'),
          options: options.regionOptions,
          value: values.region,
          category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
          hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
          hierarchy: 0,
          onChange: changeEventHandlers.regionHandler
        }
      ]
    : []

  const regionNationalFilters =
    isRegionScope || isNationalScope
      ? [
          {
            id: 'district',
            label: translate('app.district'),
            options: options.districtOptions,
            value: values.district,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 2,
            onChange: changeEventHandlers.districtHandler
          }
        ]
      : []

  const districtRegionNationalFilters =
    isDistrictScope || isRegionScope || isNationalScope
      ? [
          {
            id: 'territory',
            label: translate('app.territory'),
            options: options.territoryOptions,
            value: values.territory,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 3,
            onChange: changeEventHandlers.territoryHandler
          }
        ]
      : []

  const territoryDistrictRegionNationalFilters =
    isTerritoryScope || isDistrictScope || isRegionScope || isNationalScope
      ? [
          {
            id: 'headoffice',
            label: translate('app.headOffice'),
            options: options.headofficeOptions,
            value: values.headoffice,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 4,
            onChange: changeEventHandlers.headofficeHandler
          },
          {
            id: 'banner',
            label: translate('app.banner'),
            options: options.bannerOptions,
            value: values.banner,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 4,
            onChange: changeEventHandlers.bannerHandler
          },
          {
            id: 'province',
            label: translate('app.province'),
            options: options.provinceOptions,
            value: values.province,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 1,
            onChange: changeEventHandlers.provinceHandler
          },
          {
            id: 'kaSubregion',
            label: translate('app.kaSubregion'),
            options: options.kaSubregionOptions,
            value: values.kaSubregion,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 4,
            onChange: changeEventHandlers.kaSubregionHandler
          },
          {
            id: 'tier',
            label: translate('app.tier'),
            options: options.tierOptions,
            value: values.tier,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 4,
            onChange: changeEventHandlers.tierHandler
          },
          {
            id: 'ownershipType',
            label: translate('app.ownership'),
            options: options.ownershipTypeOptions,
            value: values.ownershipType,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 4,
            onChange: changeEventHandlers.ownershipTypeHandler
          },
          {
            id: 'store',
            label: translate('app.store'),
            options: options.storeOptions,
            value: values.store,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 5,
            onChange: changeEventHandlers.cityHandler
          },
          {
            id: 'fsa',
            label: translate('common.fsa'),
            options: options.fsaOptions,
            value: values.fsa,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 4,
            onChange: changeEventHandlers.fsaHandler
          },
          {
            id: 'city',
            label: translate('common.city'),
            options: options.cityOptions,
            value: values.city,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 4,
            onChange: changeEventHandlers.cityHandler
          }
        ]
      : []

  const outletSubTypeFilters =
    includeOutletSubTypeFilters && (isTerritoryScope || isDistrictScope || isRegionScope || isNationalScope)
      ? [
          {
            id: 'outletSubtype',
            label: translate('app.outletSubtype'),
            options: options.outletSubTypeOptions,
            value: values.outletSubType,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER,
            hierarchy: 4,
            onChange: changeEventHandlers.outletSubtypeHandler
          }
        ]
      : []

  const mergedFilters = [
    ...nationalFilters,
    ...regionNationalFilters,
    ...districtRegionNationalFilters,
    ...territoryDistrictRegionNationalFilters,
    ...outletSubTypeFilters
  ]

  return orderBy(mergedFilters, ['hierarchyType', 'hierarchy'], [SORTING_DIRECTION.ASC, SORTING_DIRECTION.ASC])
}

export function getGenericGeographyFilters(
  values,
  options,
  changeEventHandlers,
  translate,
  sectorType,
  productType,
  channel = CHANNEL_VALUES.POS
) {
  const baseFilters = [
    {
      id: 'brand',
      label: translate('app.brand'),
      options: options.brandOptions,
      value: values.brand,
      category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
      hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.PRODUCT,
      hierarchy: 0,
      onChange: changeEventHandlers.brandHandler
    },
    {
      id: 'brandVariant',
      label: translate('app.brandVariant'),
      options: options.brandVariantOptions,
      value: values.brandVariant,
      category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
      hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.PRODUCT,
      hierarchy: 1,
      onChange: changeEventHandlers.brandVariantHandler
    }
  ]

  const isVapeProductType = productType === PRODUCT_TYPE_VALUES.VAPE
  const isFmcProductType = productType === PRODUCT_TYPE_VALUES.FMC
  const isPosChannel = channel === CHANNEL_VALUES.POS

  const fmcFilters =
    isFmcProductType && isPosChannel
      ? [
          {
            id: 'size',
            label: translate('app.size'),
            options: options.sizeOptions,
            value: values.size,
            category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
            hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.PRODUCT,
            hierarchy: 2,
            onChange: changeEventHandlers.sizeHandler
          }
        ]
      : []

  const posFilters = isPosChannel
    ? [
        {
          id: 'length',
          label: translate('app.length'),
          options: options.lengthOptions,
          value: values.length,
          category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
          hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.PRODUCT,
          hierarchy: 2,
          onChange: changeEventHandlers.lengthHandler
        },
        {
          id: 'productDescription',
          label: translate('app.productDescription'),
          options: options.productDescriptionOptions,
          value: values.productDescription,
          category: GEOGRAPHY_FILTER_CATEGORIES.MULTI,
          hierarchyType: GEOGRAPHY_FILTER_HIERARCHY_TYPES.PRODUCT,
          hierarchy: 2,
          onChange: changeEventHandlers.productDescriptionHandler
        }
      ]
    : []

  const customersGenericGeographyFilters = getCustomersGenericGeographyFilters(
    values,
    options,
    changeEventHandlers,
    translate,
    sectorType,
    isVapeProductType
  )

  const mergedFilters = [...baseFilters, ...fmcFilters, ...posFilters, ...customersGenericGeographyFilters]

  return orderBy(mergedFilters, ['hierarchyType', 'hierarchy'], [SORTING_DIRECTION.ASC, SORTING_DIRECTION.ASC])
}

export const getHighestHierarchies = (filters) => {
  return filters.reduce(
    (acc, filter) => {
      const { hierarchyType, hierarchy } = filter
      const previousHierarchy = acc[hierarchyType]

      return {
        ...acc,
        [hierarchyType]: hierarchy > previousHierarchy ? hierarchy : previousHierarchy
      }
    },
    {
      [GEOGRAPHY_FILTER_HIERARCHY_TYPES.CUSTOMER]: 0,
      [GEOGRAPHY_FILTER_HIERARCHY_TYPES.PRODUCT]: 0
    }
  )
}
