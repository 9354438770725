import React, { useContext, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { decodeJwt } from 'jose'

import LangContext from 'context/LangContext'

import { setToken } from 'store/api'

import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import GlobalAlert from 'components/GlobalAlert'
import Spinner from 'components/Spinner'

const LoginCallback = () => {
  const navigate = useNavigate()
  const { translate } = useContext(LangContext)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  useEffect(() => {
    try {
      const payload = decodeJwt(token)
      const { customerId, contactId, locale, isBackdoor } = payload
      const redirectUrl = `/extrahub/extrafund`
      const cookieData = {
        token,
        customerId,
        contactId,
        locale,
        isBackdoor
      }

      localStorage.setItem('extrahub-auth', JSON.stringify(cookieData), { expires: 1 })
      setToken(token)

      return navigate(redirectUrl)
    } catch (e) {
      console.log('JWT Missing or Invalid')
    }
  }, [])

  if (!token) {
    return <EmptyState title={translate('app.warn.errorOccured')} subtitle={translate('extrahub.noToken')} />
  }

  return (
    <Container padded>
      <GlobalAlert info icon="info">
        CALLBACK, you will be redirected. Please wait...
      </GlobalAlert>
      <div className="mt-4 flex h-full w-full items-center justify-center">
        <Spinner icon="spinner" />
      </div>
    </Container>
  )
}

export default LoginCallback
