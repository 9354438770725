import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { number, string } from 'prop-types'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import * as api from 'store/Sellin/endpoints'

import Card from 'components/card'
import DataGraph from 'components/DataTable/DataGraph'
import DataVariation from 'components/DataTable/DataVariation'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Dropdown from 'components/Dropdown'
import Pagination from 'components/Pagination'
import SegmentControl from 'components/SegmentControl'
import Tooltip from 'components/Tooltip'

import {
  CURRENT_COMPARISONS_OPTIONS,
  DEFAULT_TABLE_PAGE_SIZE,
  DISABLE_TOGO_PERIOD,
  GEOGRAPHY_VALUES,
  PERIOD_FILTERS,
  PLACEHOLDERS,
  SECTOR_LEVELS
} from 'utils/constants'
import { formatCompactNumber, formatGap, formatNumber, formatPercent } from 'utils/formatters'
import { GEOGRAPHY_FILTER_CATEGORIES, getGenericGeographyFilters } from 'utils/genericGeographyFilters'
import { getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

const AmplifySellinTableCard = ({ span, vapeCategory, unitOfMeasure }) => {
  const { translate } = useContext(LangContext)
  const { sectorId, sectorType } = useParams()
  const { currentProductType } = useContext(SectorContext)

  const translatedPeriods = Object.values(PERIOD_FILTERS).map((p) => ({
    value: p,
    label: translate(`period.${p}`)
  }))

  const defaultFilters = {
    region: [],
    district: [],
    territory: [],
    kaSubregion: [],
    headoffice: [],
    banner: [],
    province: [],
    tier: [],
    ownershipType: [],
    outletSubType: [],
    store: [],
    fsa: [],
    city: [],
    brand: [],
    brandVariant: [],
    length: [],
    size: [],
    productDescription: [],
    orderCompletion: []
  }

  const [filters, setFilters] = useState(defaultFilters)
  const [range, setRange] = useState(CURRENT_COMPARISONS_OPTIONS[0].value)
  const [period, setPeriod] = useState(translatedPeriods[0].value)
  const [geography, setGeography] = useState('region')
  const [error, setError] = useState()
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState({ column: 'contribution', order: 'desc' })

  // Pagination
  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }
  const nextPage = () => {
    if (isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE) return
    setPage(page + 1)
  }

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const updateGeography = (newGeography) => {
    setGeography(newGeography)
    setPage(1)
  }

  const noTargets =
    geography === 'sku' || ((geography === 'brand' || geography === 'brand_variant') && currentProductType !== 'vape')

  const COLS = [
    {
      field: 'name',
      headerName: 'Geo'
    },
    {
      field: 'contribution',
      headerName: translate('sellIn.contribution')
    },
    {
      field: 'booked',
      headerName: translate('sellIn.booked')
    },
    {
      field: 'gap',
      headerName: translate('sellIn.gap'),
      hideColumn: noTargets
    },
    {
      field: 'vstarget',
      headerName: translate('sellIn.vsTarget'),
      hideColumn: noTargets
    },
    {
      field: 'vssply',
      headerName: translate('sellIn.vsSPLY')
    },
    {
      field: 'awr4',
      headerName: translate('app.awr4')
    },
    {
      field: 'awr13',
      headerName: translate('app.awr13')
    },
    {
      field: 'bookedtrend',
      headerName: translate('sellIn.bookedL13'),
      isLarge: true
    }
  ]

  COLS[0].headerName = translate(displayOptTableLabels(geography))

  // Geography filters options
  const { data: geographyFiltersOptions, isLoading: areGeographyFiltersLoading } = useQuery({
    queryKey: ['sellinGeographyFiltersOptions', sectorType, sectorId, currentProductType, vapeCategory, period],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const { data } = await api.fetchAmplifySellinFiltersOptions(sectorType, sectorId, currentProductType, period)

        return data
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
  }

  const geographyFilters = useMemo(() => {
    const { orderCompletion, ...values } = filters

    const options = {
      ...geographyFiltersOptions
    }

    const changeEventHandlers = {
      regionHandler: (value) => handleFilterChange({ region: value }),
      districtHandler: (value) => handleFilterChange({ district: value }),
      territoryHandler: (value) => handleFilterChange({ territory: value }),
      kaSubregionHandler: (value) => handleFilterChange({ kaSubregion: value }),
      headofficeHandler: (value) => handleFilterChange({ headoffice: value }),
      bannerHandler: (value) => handleFilterChange({ banner: value }),
      provinceHandler: (value) => handleFilterChange({ province: value }),
      cityHandler: (value) => handleFilterChange({ city: value }),
      fsaHandler: (value) => handleFilterChange({ fsa: value }),
      tierHandler: (value) => handleFilterChange({ tier: value }),
      ownershipTypeHandler: (value) => handleFilterChange({ ownershipType: value }),
      outletSubtypeHandler: (value) => handleFilterChange({ outletSubtype: value }),
      storeHandler: (value) => handleFilterChange({ store: value }),
      brandHandler: (value) => handleFilterChange({ brand: value }),
      brandVariantHandler: (value) => handleFilterChange({ brandVariant: value }),
      lengthHandler: (value) => handleFilterChange({ length: value }),
      sizeHandler: (value) => handleFilterChange({ size: value }),
      productDescriptionHandler: (value) => handleFilterChange({ productDescription: value })
    }

    const genericGeographyFilters = getGenericGeographyFilters(
      values,
      options,
      changeEventHandlers,
      translate,
      sectorType,
      currentProductType
    )

    const orderCompletionFilter =
      geography === GEOGRAPHY_VALUES.CUSTOMER
        ? [
            {
              id: 'orderCompletion',
              label: translate('amplify.sellin.filters.orderCompletion'),
              searchPlaceholder: translate('app.placeholders.searchOrderCompletion'),
              options: [
                {
                  value: 'orderReceivedScheduled',
                  label: translate('amplify.sellin.filters.orderReceivedScheduled')
                },
                {
                  value: 'orderReceivedOut',
                  label: translate('amplify.sellin.filters.orderReceivedOut')
                },
                {
                  value: 'noOrderScheduled',
                  label: translate('amplify.sellin.filters.noOrderScheduled')
                },
                {
                  value: 'noOrderNotExpected',
                  label: translate('amplify.sellin.filters.noOrderNotExpected')
                }
              ],
              value: orderCompletion,
              category: GEOGRAPHY_FILTER_CATEGORIES.SINGLE,
              onChange: (value) => handleFilterChange({ orderCompletion: value })
            }
          ]
        : []

    return [...orderCompletionFilter, ...genericGeographyFilters]
  }, [geography, areGeographyFiltersLoading, sectorType, currentProductType, translate])

  const appliedFilters = Object.values(filters).filter(
    (value) => (typeof value === 'string' && value !== 'all') || (Array.isArray(value) && value.length !== 0)
  ).length

  // NOTE: Resets filters every time a new geography or a new scope is selected
  useEffect(() => {
    setFilters(defaultFilters)
  }, [sectorType, sectorId, currentProductType])

  // NOTE: Resets orderCompletionFilter filter when geography changes (These options are only available for specifics geographies)
  useEffect(() => {
    setFilters({
      ...filters,
      orderCompletion: defaultFilters.orderCompletion
    })
  }, [geography])

  // Geography data
  const { data: geographyData, isLoading: isGeographyLoading } = useQuery({
    queryKey: [
      'sellinGeographyData',
      sectorType,
      sectorId,
      geography,
      period,
      currentProductType,
      vapeCategory,
      unitOfMeasure,
      range,
      offset,
      filters,
      sortBy?.column,
      sortBy?.order
    ],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const { data } = await api.fetchSellinGeography({
          sectorType,
          sectorId,
          geography,
          period,
          currentProductType,
          vapeCategory,
          unitOfMeasure,
          range,
          offset,
          ...filters,
          limit: DEFAULT_TABLE_PAGE_SIZE,
          sortBy: sortBy?.column,
          sortDirection: sortBy?.order
        })
        const processedData = data?.result.map((item) => {
          let linkTo
          if (
            [SECTOR_LEVELS.REGION, SECTOR_LEVELS.DISTRICT, SECTOR_LEVELS.TERRITORY, SECTOR_LEVELS.CUSTOMER].includes(
              geography
            )
          ) {
            linkTo = `/${geography}/${item.id}/pace/amplify/sell-in`
          }
          return {
            ...item,
            linkTo
          }
        })
        return processedData
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const tableData = useMemo(() => {
    if (isGeographyLoading) return []

    if (Object.values(geographyData)?.length) {
      return Object.values(geographyData).map(
        ({
          id,
          linkTo,
          name,
          contribution,
          vsTarget,
          booked,
          customerName,
          erp,
          address,
          ownershipType,
          gap,
          vsLastYear,
          orderCompletion,
          orderCompletionActuals,
          orderCompletionTargets,
          trended,
          awr4,
          awr13
        }) => {
          const displayName = geography === 'sku' ? `${name} - ${id}` : name || '-'

          return {
            name:
              geography === SECTOR_LEVELS.CUSTOMER ? (
                <StoreInfoTooltip
                  displayName={displayName}
                  customerName={customerName}
                  erp={erp}
                  address={address}
                  ownershipType={ownershipType}
                  linkTo={linkTo}
                />
              ) : (
                <GeoTableLineHeader name={displayName} linkTo={linkTo} />
              ),
            contribution: contribution
              ? formatPercent(contribution, { convertDecimal: true })
              : PLACEHOLDERS.NO_VALUE_PERCENT,
            booked: (
              <Tooltip isNumber hint={booked}>
                {booked ? formatCompactNumber(booked) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            ),
            gap: (
              <Tooltip isNumber hint={gap}>
                {!isNaN(gap) ? formatGap(gap, formatCompactNumber) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            ),
            vstarget: (
              <Tooltip isNumber hint={+vsTarget * 100}>
                {+vsTarget ? formatPercent(+vsTarget, { convertDecimal: true }) : PLACEHOLDERS.NO_VALUE_PERCENT}
              </Tooltip>
            ),
            vssply: <DataVariation peer variation={+vsLastYear ? +vsLastYear * 100 : 0} variationBefore isPercent />,
            bookedtrend: <DataGraph color="#53CCF8" data={trended} />,
            orderCompletion: (
              <OrderCompletionBar
                orderCompletion={orderCompletion}
                completedOrders={orderCompletionActuals}
                completedGap={orderCompletionTargets}
              />
            ),
            awr4: (
              <Tooltip isNumber hint={formatNumber(awr4)}>
                {!isNaN(awr4) ? formatGap(awr4, formatCompactNumber) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            ),
            awr13: (
              <Tooltip isNumber hint={formatNumber(awr13)}>
                {!isNaN(awr13) ? formatGap(awr13, formatCompactNumber) : PLACEHOLDERS.NO_VALUE}
              </Tooltip>
            )
          }
        }
      )
    }

    return []
  }, [geographyData, geography, isGeographyLoading])

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <Dropdown
          key="period"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          options={translatedPeriods}
        />,
        <SegmentControl
          key="range"
          name="range"
          onChange={(e) => setRange(e.target.value)}
          value={range}
          options={CURRENT_COMPARISONS_OPTIONS}
          disabled={DISABLE_TOGO_PERIOD.includes(period)}
        />,
        <GeographyToggle
          key="geography-toggle"
          geography={geography}
          setGeography={updateGeography}
          includeBrandVariant
          includeBrand
          includeOwnership
          includeSku
          includeFsa
          includeCity
        />,
        <GeographyFilterBuilder
          key="sellin-geography-filter-builder"
          isDisabled={areGeographyFiltersLoading}
          isLoading={areGeographyFiltersLoading}
          filters={geographyFilters}
          appliedFilters={appliedFilters}
          error={error}
          onFiltersChange={(newFilters) => {
            // Merges with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFilters,
              ...newFilters
            }
            setFilters(updatedFilters)
            setPage(1)
          }}
        />
      ]}
      actions={
        !isGeographyLoading && (tableData.length >= DEFAULT_TABLE_PAGE_SIZE || page > 1)
          ? [
              <Pagination
                key="pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyLoading}
                disableNextButton={tableData.length < DEFAULT_TABLE_PAGE_SIZE}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyLoading}
        error={error}
        rows={tableData}
        onColumnClick={handleSort}
        activeColumn={sortBy}
        unClickableColumns={['bookedtrend']}
        columns={COLS.filter(({ hideColumn }) => !hideColumn)}
        fillContainer
        stickyFirstColumn
        stickyHeaders
      />
    </Card>
  )
}

AmplifySellinTableCard.propTypes = {
  span: number,
  vapeCategory: string,
  unitOfMeasure: string
}

export default AmplifySellinTableCard

const OrderCompletionBar = ({ orderCompletion, completedOrders, completedGap }) => {
  const completedPercent = Math.min(orderCompletion * 100, 100)

  return (
    <div className="flex items-center">
      <div className="h-4 w-32 rounded bg-slate-100">
        <div className="h-full rounded-md bg-brand" style={{ width: `${completedPercent}%` }} />
      </div>
      <div className="pl-3">
        {completedOrders} / {completedGap || 0}
      </div>
    </div>
  )
}

OrderCompletionBar.propTypes = {
  orderCompletion: number,
  completedOrders: number,
  completedGap: number
}
