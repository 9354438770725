import React, { useContext } from 'react'
import { sumBy } from 'lodash'
import isNumber from 'lodash/isNumber'
import { mix, stripUnit } from 'polished'
import { array, func, string } from 'prop-types'
import styled from 'styled-components'

import LangContext from 'context/LangContext'

import Icon from 'components/Icon'
import Pill from 'components/Pill'
import SmallCaps from 'components/SmallCaps'
import Status from 'components/Status'

import { borderColor, greyLight, offWhite, white } from 'styles/colors'
import { animationCurve, animationTime, borderRadius } from 'styles/global'
import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { fontRegular, smallFontSize } from 'styles/typography'

const Container = styled.div`
  width: 100%;
  margin: ${spacing.medium} 0;

  ${media.breakpoint`
    margin: ${spacing.large} 0;
  `};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  ${media.breakpoint`
    border: 1px solid ${borderColor};
    border-radius: ${borderRadius};

    tr:first-child th:first-child {
      border-top-left-radius: ${borderRadius};
    }

    tr:first-child th:last-child {
      border-top-right-radius: ${borderRadius};
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: ${borderRadius};
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: ${borderRadius};
    }

    tr:last-child td {
      border-bottom: 0;
    }

  `};

  th,
  td {
    padding: ${stripUnit(spacing.small) * 1.5 + 'px'} ${spacing.medium};
    border-bottom: 1px solid ${borderColor};
    background-color: ${white};
    transition: background-color ${animationTime} ${animationCurve};

    &:last-child {
      text-align: right;
    }
  }

  th {
    background-color: ${mix(0.5, white, offWhite)};
    font-weight: ${fontRegular};
    font-size: ${smallFontSize};
    color: ${greyLight};
    text-align: left;
    border-top: 1px solid ${borderColor};

    ${media.breakpoint`
      border-top: 0;
    `};
  }

  td {
    cursor: pointer;
  }

  tr:hover td {
    background-color: ${mix(0.4, offWhite, white)};
  }
`

const Heading = styled(SmallCaps)`
  display: block;
  padding: 0 ${spacing.medium};
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};

  ${media.breakpoint`
    padding: 0;
  `};
`

const SAQTable = ({ title, orders, linkToDetails }) => {
  const { translate } = useContext(LangContext)

  return (
    <Container>
      {title && <Heading>{title}</Heading>}
      <Table>
        <thead>
          <tr>
            <th>{translate('common.date')}</th>
            <th>{translate('common.qty')}</th>
            <th>{translate('common.status')}</th>
            {linkToDetails ? <th>&nbsp;</th> : null}
          </tr>
        </thead>
        <tbody>
          {orders?.map((order, i) => {
            let quantity
            if (order.type === 'po98') {
              quantity = order.entries.reduce((acc, entry) => {
                if (entry.qty !== entry.po98RevisedQty && isNumber(entry.po98RevisedQty)) acc += entry.po98RevisedQty
                else acc += entry.qty
                return acc
              }, 0)
            } else {
              quantity = order.totalQuantity || sumBy(order.entries, 'qty')
            }
            return (
              <tr key={order.id} onClick={() => (linkToDetails ? linkToDetails(order.id) : null)}>
                <td>{new Date(order.createdAt).toDateString()}</td>
                <td>{quantity}</td>
                <td>
                  <Status status={order.status} />
                  <br />
                  <Pill>{(order.type || 'saq').toUpperCase()}</Pill>
                </td>
                {linkToDetails ? (
                  <td>
                    <Icon icon="more" light />
                  </td>
                ) : null}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

SAQTable.propTypes = {
  title: string,
  orders: array,
  linkToDetails: func
}

export default SAQTable
