import countBy from 'lodash/countBy'
import groupBy from 'lodash/groupBy'
import maxBy from 'lodash/maxBy'
import omit from 'lodash/omit'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { createSelector } from 'reselect'

import { getInboxErrorMessage } from 'utils/goProgramErrors'

const auth = (state) => omit(state.auth, '_persist')
const allGoFundPrograms = (state) => omit(state.goFundPrograms, '_persist')
const allCustomers = (state) => omit(state.customers, '_persist')
const user = createSelector(auth, ({ user }) => user)
export const currentTerritoryId = createSelector(auth, ({ currentTerritoryId }) => currentTerritoryId)

export const preferredLanguage = createSelector(user, (user) => user?.preferredLanguage || 'english')
export const isVFUser = createSelector(user, (user) =>
  ['telesalesRepresentative', 'customerService', 'teamLead'].includes(user?.groupCode)
)

export const isNonAdminCustomerService = createSelector(
  auth,
  ({ user }) => user.groupCode === 'customerService' && !user.isMobileAdmin
)

export const vfAssignmentOptions = createSelector(
  isVFUser,
  user,
  currentTerritoryId,
  (isVFUser, user, currentTerritoryId) => {
    if (isVFUser || !user?.vfOptions) return []
    if (!user.vfOptions[0].territoryIds) return [{ options: user.vfOptions }]
    const groupedVFOptions = groupBy(user.vfOptions, ({ territoryIds }) =>
      territoryIds.includes(currentTerritoryId) ? 'assigned' : 'unassigned'
    )
    return sortBy(
      Object.entries(groupedVFOptions).map(([label, options]) => ({ label, options })),
      'label'
    ).map(({ options }) => ({ options }))
  }
)

export const populateSyncErrors = createSelector(
  auth,
  allGoFundPrograms,
  allCustomers,
  ({ syncErrors }, goFundPrograms, customers) => {
    if (!syncErrors.length) return []
    return syncErrors.map((err) => {
      const { name } = customers[err.customerId]
      return {
        ...err,
        error: getInboxErrorMessage(err),
        goFundProgram: goFundPrograms[`${err.id}_${err.goFundId}`],
        customerName: name
      }
    })
  }
)

export const getSyncErrorCount = createSelector(auth, ({ syncErrors }) => syncErrors.length)

export const lastSyncs = createSelector(user, (user) => (user || {}).lastSyncs)

const customersArray = createSelector(allCustomers, (customers) => Object.values(customers))
export const getLatestComments = createSelector(customersArray, user, (customers, user) => {
  const latestComments = customers
    .reduce((acc, { messages }) => {
      if (!messages?.length) return acc
      const lastComment = maxBy(messages, 'createdAt')
      const assignmentExpired = lastComment.assignmentExpiry && moment().isAfter(lastComment.assignmentExpiry)
      if (!lastComment.actionRequiredBy) {
        lastComment.status = 'complete'
      } else if (!assignmentExpired && lastComment.assignedEmployeeId) {
        lastComment.status = 'reserved'
      } else {
        lastComment.status = `actionRequiredBy${lastComment.actionRequiredBy}`
      }
      return [...acc, lastComment]
    }, [])
    .filter(Boolean)

  return latestComments
})

export const actionableCommentsCount = createSelector(getLatestComments, user, (comments, user) => {
  const isTM = !['telesalesRepresentative', 'customerService'].includes(user.groupCode)
  const { actionRequiredByTM = [], actionRequiredByVF = [], reserved = [] } = groupBy(comments, 'status')
  if (isTM) return actionRequiredByTM.length
  return (
    actionRequiredByVF.length +
    (countBy(reserved, ({ assignedEmployeeId }) => assignedEmployeeId === user.id).true || 0)
  )
})

const customerFromProps = (state, props) => props.customer || null
export const canUserStartCall = createSelector(user, isVFUser, customerFromProps, (employee, isVFUser, customer) => {
  const isNationalMobilePermitted = !!employee.nationalMobilePermission
  if (isVFUser || isNationalMobilePermitted) return true
  if (!customer) return false
  // This basically allows anyone to start a call if the primary territory of the customer is not set... Do we really want this?
  if (!customer.primaryTerritory) return true // this is for safety to avoid disabling a call starting just because the primary territory is missing.
  return (
    customer?.primaryTerritory && employee.territories && employee.territories.includes(customer.primaryTerritory?.id)
  )
})

export const getExtraDashboardToken = createSelector(auth, ({ extraDashboard }) => extraDashboard.token)
