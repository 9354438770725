import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import { object } from 'prop-types'

import LangContext from 'context/LangContext'

import * as api from 'store/extraHub/endpoints'

import Card from 'components/card'
import DataCombined from 'components/DataTable/DataCombined'
import GeoTableLineHeader from 'components/DataTable/GeoTableLineHeader'
import Pagination from 'components/Pagination'

import { DEFAULT_TABLE_PAGE_SIZE, GEOGRAPHY_VALUES, SECTOR_LEVELS, SORTING_DIRECTION } from 'utils/constants'
import { formatPercent } from 'utils/formatters'
import { GEOGRAPHY_FILTER_CATEGORIES, getCustomersGenericGeographyFilters } from 'utils/genericGeographyFilters'
import { getErrorMessage, sortDataTableBy } from 'utils/helpers'

import GeographyDataTable from '../GeographyDataTable'
import GeographyFilterBuilder from '../GeographyFilterBuilder'
import GeographyToggle, { displayOptTableLabels } from '../GeographyToggle'
import StoreInfoTooltip from '../StoreInfoTooltip'

import GeographyContactDetails from './GeographyContactDetails'

const defaultFilters = {
  region: [],
  district: [],
  territory: [],
  kaSubregion: [],
  headoffice: [],
  banner: [],
  province: [],
  tier: [],
  ownershipType: [],
  outletSubType: [],
  store: [],
  fsa: [],
  city: [],
  activity: 'all',
  storesCompletion: 'all'
}

const defaultSortBy = { column: 'name', order: SORTING_DIRECTION.DESC }

const GeographyTable = ({ span }) => {
  const { translate } = useContext(LangContext)
  const { sectorType, sectorId } = useParams()

  const [geography, setGeography] = useState(SECTOR_LEVELS.REGION)
  const [error, setError] = useState()
  const [rows, setRows] = useState([])
  const [filters, setFilters] = useState(defaultFilters)
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState(defaultSortBy)

  const handleSort = (columnClicked) => {
    setSortBy(sortDataTableBy(columnClicked, sortBy))
    setPage(1)
  }

  const handleSetGeography = (newGeography) => {
    // NOTE: Columns are different for User level, so we force a reset to a common column
    const isUserGeographySelected = geography === GEOGRAPHY_VALUES.USER || newGeography === GEOGRAPHY_VALUES.USER
    if (isUserGeographySelected) setSortBy(defaultSortBy)

    setGeography(newGeography)
    setPage(1)
  }

  // Columns
  const LOCATION_COLUMNS = [
    {
      field: 'name',
      headerName: translate('common.name')
    },
    {
      field: 'erps',
      headerName: translate('app.storeCompleted')
    },
    {
      field: 'storeCompletion',
      headerName: translate('app.storeCompletionRate')
    },
    {
      field: 'users',
      headerName: translate('app.userCompleted')
    },
    {
      field: 'userCompletion',
      headerName: translate('app.userCompletionRate')
    },
    {
      field: 'activeCustomers',
      headerName: translate('app.userEngaged')
    }
  ]

  const USER_COLUMNS = [
    {
      field: 'name',
      headerName: translate('common.name')
    },
    {
      field: 'engaged',
      headerName: translate('app.userEngaged')
    },
    {
      field: 'completedActivities',
      headerName: translate('amplify.extra.activityCompleted')
    },
    {
      field: 'totalActivities',
      headerName: translate('amplify.extra.totalActivities')
    },
    {
      field: 'activityCompletion',
      headerName: translate('app.completionRate')
    },
    { field: 'details', headerName: translate('common.details') }
  ]

  const COLS = geography === GEOGRAPHY_VALUES.USER ? USER_COLUMNS : LOCATION_COLUMNS
  COLS[0].headerName = capitalize(geography)

  // Geography filters
  const { data: geographyFiltersOptions, isLoading: areGeographyFiltersLoading } = useQuery({
    queryKey: ['extraHubGeographyFiltersOptions', sectorType, sectorId],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const { data } = await api.getFilters({
          sectorType,
          sectorId
        })

        return data
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  const handleFilterChange = (updates) => {
    setFilters({ ...filters, ...updates })
  }

  const geographyFilters = useMemo(() => {
    const { activity, storesCompletion, ...values } = filters

    const options = {
      ...geographyFiltersOptions
    }

    const changeEventHandlers = {
      regionHandler: (value) => handleFilterChange({ region: value }),
      districtHandler: (value) => handleFilterChange({ district: value }),
      territoryHandler: (value) => handleFilterChange({ territory: value }),
      kaSubregionHandler: (value) => handleFilterChange({ kaSubregion: value }),
      headofficeHandler: (value) => handleFilterChange({ headoffice: value }),
      bannerHandler: (value) => handleFilterChange({ banner: value }),
      provinceHandler: (value) => handleFilterChange({ province: value }),
      fsaHandler: (value) => handleFilterChange({ fsa: value }),
      cityHandler: (value) => handleFilterChange({ city: value }),
      tierHandler: (value) => handleFilterChange({ tier: value }),
      ownershipTypeHandler: (value) => handleFilterChange({ ownershipType: value }),
      outletSubtypeHandler: (value) => handleFilterChange({ outletSubtype: value }),
      storeHandler: (value) => handleFilterChange({ store: value })
    }

    const customersGenericGeographyFilters = getCustomersGenericGeographyFilters(
      values,
      options,
      changeEventHandlers,
      translate,
      sectorType
    )

    const activityFilter = [
      {
        id: 'activity',
        label: translate('amplify.extraHubActivity.filters.activity'),
        searchPlaceholder: translate('app.placeholders.searchActivity'),
        options: [{ label: 'All Campaigns', value: 'all' }, ...(geographyFiltersOptions?.activityOptions || [])],
        value: activity,
        category: GEOGRAPHY_FILTER_CATEGORIES.SINGLE,
        onChange: (value) => handleFilterChange({ activity: value })
      }
    ]

    const storesCompletionFilter =
      geography === GEOGRAPHY_VALUES.CUSTOMER
        ? [
            {
              id: 'storesCompletion',
              label: translate('amplify.extraHubActivity.filters.storesCompletion'),
              searchPlaceholder: translate('app.placeholders.searchStoresCompletion'),
              options: [
                {
                  value: 'storesFullyUpdated',
                  label: translate('amplify.extraHubActivity.filters.storesCompletion.updated')
                },
                {
                  value: 'storesNotFullyUpdated',
                  label: translate('amplify.extraHubActivity.filters.storesCompletion.not.updated')
                }
              ],
              value: storesCompletion,
              category: GEOGRAPHY_FILTER_CATEGORIES.SINGLE,
              onChange: (value) => handleFilterChange({ storesCompletion: value })
            }
          ]
        : []

    return [...customersGenericGeographyFilters, ...activityFilter, ...storesCompletionFilter]
  }, [geography, areGeographyFiltersLoading, sectorType, translate])

  const appliedFilters = Object.values(filters).filter(
    (value) => (typeof value === 'string' && value !== 'all') || (Array.isArray(value) && value.length !== 0)
  ).length

  // NOTE: Resets filters every time a new scope is selected
  useEffect(() => {
    setFilters(defaultFilters)
  }, [sectorType, sectorId])

  // NOTE: Resets storesCompletion filter when geography changes (These options are only available for specifics geographies)
  useEffect(() => {
    setFilters({
      ...filters,
      storesCompletion: defaultFilters.storesCompletion
    })
  }, [geography])

  // Geography data
  const offset = useMemo(() => {
    return page * DEFAULT_TABLE_PAGE_SIZE - DEFAULT_TABLE_PAGE_SIZE
  }, [page])

  const { data: geographyData, isLoading: isGeographyDataLoading } = useQuery({
    queryKey: ['extraHubGeographyData', sectorType, sectorId, geography, filters, offset, sortBy],
    queryFn: async () => {
      setError(null)
      if (sectorType && sectorId) {
        const {
          data: { geographies }
        } = await api.getGeography({
          sectorType,
          sectorId,
          geography,
          filters,
          offset,
          sortBy: sortBy.column,
          sortDirection: sortBy.order
        })

        return geographies
      }

      return []
    },
    onError: (error) => setError(getErrorMessage(error))
  })

  // Rows
  const formatLocationGeoData = (row) => {
    const { id, name } = row
    const shouldLinkTo =
      ['region', 'district', 'territory', 'customer'].includes(geography) ||
      (geography === 'sku' && sectorType === SECTOR_LEVELS.CUSTOMER)
    const link = shouldLinkTo ? `/${geography}/${id}/pace/amplify/extra-hub` : null

    return {
      name:
        geography === SECTOR_LEVELS.CUSTOMER ? (
          <StoreInfoTooltip
            displayName={name}
            customerName={name}
            erp={id}
            address={row.address}
            ownershipType={row.ownership_type}
            linkTo={link}
          />
        ) : (
          <GeoTableLineHeader name={name} linkTo={link} />
        ),
      erps: (
        <DataCombined
          primary={row.totalCompletedStores.toLocaleString()}
          secondary={`of ${row.totalStores.toLocaleString()}`}
        />
      ),
      storeCompletion: formatPercent(row.storeCompletion),
      users: (
        <DataCombined
          primary={row.totalCompletedUsers.toLocaleString()}
          secondary={`of ${row.activeContacts.toLocaleString()}`}
        />
      ),
      userCompletion: formatPercent(row.userCompletion),
      activeCustomers: row.engagedContacts.toLocaleString()
    }
  }

  const formatUserGeoData = (row) => {
    return {
      name: <GeoTableLineHeader name={row.name} />,
      engaged: row.engaged ? 'YES' : 'NO',
      completedActivities: row.completedActivities,
      totalActivities: row.totalActivities,
      activityCompletion: formatPercent(row.activityCompletion),
      details: <GeographyContactDetails contact={row} />
    }
  }

  useEffect(() => {
    if (isGeographyDataLoading) return

    const formatRowData = geography === GEOGRAPHY_VALUES.USER ? formatUserGeoData : formatLocationGeoData
    const newRows = geographyData.map(formatRowData)

    setRows(newRows)
  }, [geographyData, geography, isGeographyDataLoading, offset])

  // Pagination
  const hasReachedLastPage = isEmpty(geographyData) || geographyData.length < DEFAULT_TABLE_PAGE_SIZE

  const prevPage = () => {
    if (page > 1) setPage(page - 1)
  }

  const nextPage = () => {
    if (hasReachedLastPage) return
    setPage(page + 1)
  }

  return (
    <Card
      title={`${translate(displayOptTableLabels(geography))} Performance`}
      span={span}
      displayAmplify={false}
      headerActions={[
        <GeographyToggle
          key="geography-toggle"
          geography={geography}
          setGeography={handleSetGeography}
          includeActivity
          includeCampaign
          includeOwnership
          includeUser={sectorType === SECTOR_LEVELS.CUSTOMER}
          includeFsa
          includeCity
        />,
        <GeographyFilterBuilder
          key="geography-filter-builder"
          isDisabled={areGeographyFiltersLoading}
          isLoading={areGeographyFiltersLoading}
          filters={geographyFilters}
          appliedFilters={appliedFilters}
          onFiltersChange={(newFilters) => {
            // Merges with default filters to ensure all keys exist
            const updatedFilters = {
              ...defaultFilters,
              ...newFilters
            }
            setFilters(updatedFilters)
            setPage(1)
          }}
        />
      ]}
      actions={
        !isGeographyDataLoading && (!hasReachedLastPage || page > 1)
          ? [
              <Pagination
                key="extrahub-pagination"
                currentPage={page}
                onClickPrev={prevPage}
                onClickNext={nextPage}
                disabled={isGeographyDataLoading}
                disableNextButton={hasReachedLastPage}
              />
            ]
          : null
      }
    >
      <GeographyDataTable
        isLoading={isGeographyDataLoading}
        error={error}
        rows={rows}
        columns={COLS}
        onColumnClick={handleSort}
        activeColumn={sortBy}
        unClickableColumns={['details']}
        fillContainer
        stickyHeaders
        stickyFirstColumn
      />
    </Card>
  )
}

GeographyTable.propTypes = {
  span: object
}

export default GeographyTable
