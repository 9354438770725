import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Webcam from 'react-webcam'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'
import first from 'lodash/first'

import ExtrahubContext from 'context/ExtrahubContext'
import LangContext from 'context/LangContext'

import { uploadImagesGetKeys } from 'store/api'
import { listCustomerGoFunds } from 'store/customers/endpoints'
import { uploadScanReceiptImageRecognition } from 'store/goFundPrograms/endpoints'

import Button from 'components/button/Button'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import ErrorContent from 'components/Extrahub/ErrorContent'
import FmcConversionSuccess from 'components/Extrahub/FmcConversionSuccess'
import ScanningOverlay from 'components/Extrahub/ScanningOverlay'
import SuccessContent from 'components/Extrahub/SuccessContent'
import GlobalAlert from 'components/GlobalAlert'
import Icon from 'components/Icon'
import Spinner from 'components/Spinner'

import { FMC_CONVERSION } from 'utils/constants'
import { cn } from 'utils/styling'

import { grey } from 'styles/colors'

const ImageRecognitionScan = () => {
  const navigate = useNavigate()
  const [hasCameraAccess, setHasCameraAccess] = useState(false)
  const { programId } = useParams()
  const { translate } = useContext(LangContext)
  const { customerId, contactId } = useContext(ExtrahubContext)

  useEffect(() => {
    navigator.permissions.query({ name: 'camera' }).then((result) => {
      console.log({ result })
      setHasCameraAccess(result.state === 'granted')
      console.log({ hasCameraAccess })
    })
  }, [navigator?.permissions])

  const {
    isLoading,
    error: errorGet,
    data: program
  } = useQuery({
    queryKey: ['imageRecognition', customerId],
    queryFn: async () => {
      const { data } = await listCustomerGoFunds(customerId)
      return data || []
    },
    select: (data) => data.find((p) => p.id === Number(programId))
  })

  const client = useQueryClient()
  const {
    data,
    error: errorUpload,
    isPending: scanning,
    mutate: uploadReceipt,
    reset: resetMutation
  } = useMutation({
    mutationFn: async () => {
      const blob = await fetch(capturedImage).then((res) => res.blob())
      const receipt = new File([blob], `${customerId}_${new Date().getMilliseconds()}_receipt.png`, {
        type: 'image/png'
      })

      const imageKey = await uploadImagesGetKeys([receipt])
      const key = imageKey.length ? imageKey.map(({ key }) => ({ key })) : null
      return uploadScanReceiptImageRecognition(
        receipt, // File
        { goFundProgramId: programId, contactId, key: first(key).key } // params
      )
    },
    onSuccess: async () => {
      client.invalidateQueries({ queryKey: ['imageRecognition'] })
    }
  })

  const webcamRef = useRef()
  const [mediaVisible, setMediaVisible] = useState(false)

  const [captureFlash, setCaptureFlash] = useState(false)
  const displayFlash = () => {
    setCaptureFlash(true)
    setTimeout(() => {
      setCaptureFlash(false)
    }, 700)
  }

  const [capturedImage, setCapturedImage] = useState(null)
  const resetCamState = () => {
    setCapturedImage()
    if (webcamRef.current) webcamRef.current.reset()
    if (fileInputRef.current) fileInputRef.current.value = null
  }

  const handleCapture = () => {
    const image = webcamRef.current.getScreenshot()
    if (!image) return

    setCapturedImage(image)
    displayFlash()
  }

  const fileInputRef = useRef()
  const handleLibrary = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onload = (e) => {
      setCapturedImage(e.target.result)
      displayFlash()
    }
    reader.readAsDataURL(file)
  }

  const getActionsButton = () => {
    if (capturedImage) {
      return (
        <>
          <button
            className="size-16 rounded-full bg-brand p-4 text-white shadow-lg ring-1 ring-slate-900/10 transition-colors hover:bg-brand-700 disabled:pointer-events-none disabled:opacity-60"
            onClick={uploadReceipt}
            disabled={scanning}
          >
            <Icon icon="checkmark" medium />
          </button>
          <button
            className="size-12 p-2 disabled:pointer-events-none disabled:opacity-60"
            onClick={() => resetCamState()}
          >
            <Icon icon="refresh" medium />
          </button>
        </>
      )
    }

    return (
      <>
        <button
          className="size-16 rounded-full bg-white p-4 shadow-lg ring-1 ring-slate-900/10 transition-colors hover:bg-slate-50 disabled:pointer-events-none disabled:opacity-60"
          onClick={handleCapture}
          disabled={capturedImage}
        >
          <Icon icon="camera" medium />
        </button>
        <button className="size-12 p-2 disabled:pointer-events-none disabled:opacity-60" disabled={true}>
          <Icon icon="flash-off" medium color={grey} />
        </button>
      </>
    )
  }

  const resetState = () => {
    resetCamState()
    resetMutation()
  }

  if (errorGet) return <EmptyState title={translate('app.warn.errorOccured')} subtitle={errorGet} />

  if (isLoading) {
    return (
      <Container padded>
        <div className="mt-4 flex h-full w-full items-center justify-center">
          <Spinner icon="spinner" />
        </div>
      </Container>
    )
  }

  const getUploadFeedback = (data, error) => {
    const result = data?.data
    const issueWithInvoice = error || result?.uploadResult?.errors

    if (!error && program.activity === FMC_CONVERSION) return <FmcConversionSuccess />
    if (issueWithInvoice) return <ErrorContent error={error} result={result} />
    return <SuccessContent program={program} result={result} />
  }

  if (data || errorUpload) {
    return (
      <div className="p-2">
        <div className="relative my-6 block w-full rounded-lg border border-slate-200 bg-white p-4 shadow-sm hover:border-slate-300 hover:shadow-md">
          {getUploadFeedback(data, errorUpload)}
          <div className="flex flex-col gap-2 pt-4">
            <Button full primary icon="document-scanner" onClick={() => resetState()}>
              {translate('components.Extrahub.ExtraHubCard.payNowButton')}
            </Button>
            <Button full icon="left-arrow" secondary onClick={() => navigate('..')}>
              {translate('common.close')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="py-2">
      {!hasCameraAccess && (
        <GlobalAlert info icon="info">
          {translate('app.warn.noCameraAccess')}
        </GlobalAlert>
      )}
      <div className="p-2">
        <div className="relative my-6 block w-full rounded-lg border border-slate-200 bg-white p-4 shadow-sm hover:border-slate-300 hover:shadow-md">
          <div className="relative my-6 block w-full rounded-lg border border-slate-200 bg-white p-4 shadow-sm hover:border-slate-300 hover:shadow-md">
            <div className="mt-100 mx-auto flex w-full max-w-[500px] flex-col items-center justify-center gap-8 px-4 py-6 ">
              <div className="relative w-full pb-[133.33%]">
                <div className="absolute inset-0 rounded-xl">
                  <div className="absolute inset-0 rounded-xl bg-black" />
                  {capturedImage ? (
                    <img
                      src={capturedImage}
                      alt="Captured"
                      className="absolute inset-0 h-full w-full rounded-xl object-cover"
                    />
                  ) : (
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      className={cn(
                        'absolute inset-0 h-full w-full rounded-xl object-cover opacity-0 ring-1 ring-slate-900/60 transition-opacity duration-700',
                        mediaVisible && 'opacity-100'
                      )}
                      height={720}
                      width={1280}
                      videoConstraints={{
                        width: 1280,
                        height: 720,
                        facingMode: 'environment'
                      }}
                      onUserMedia={() => setMediaVisible(true)}
                    />
                  )}
                  {captureFlash && (
                    <motion.div
                      initial={{ opacity: 0.8 }}
                      animate={{ opacity: 0 }}
                      transition={{ duration: 0.7 }}
                      className="absolute inset-0 rounded-xl bg-white"
                    />
                  )}
                  {scanning && (
                    <AnimatePresence>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                        className="absolute inset-0 overflow-hidden rounded-xl"
                      >
                        <ScanningOverlay />
                      </motion.div>
                    </AnimatePresence>
                  )}
                </div>
              </div>
              <div className="flex w-full shrink-0 items-center justify-between gap-2 px-4 pb-2">
                <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" className="hidden" />

                <button
                  className="size-12 p-2 disabled:pointer-events-none disabled:opacity-60"
                  onClick={handleLibrary}
                >
                  <Icon icon="photo-library" medium />
                </button>

                {getActionsButton()}
              </div>
            </div>
          </div>
          <Button full secondary onClick={() => navigate('..')}>
            {translate('common.return')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ImageRecognitionScan
