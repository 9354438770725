import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { func } from 'prop-types'

import SectorContext from 'context/SectorContext'

import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchAmplifySellout } from 'store/Sellout/actions'

import PaceSelloutL13TrendCard from 'components/PaceSelloutL13TrendCard'

import { CHANNEL_FILTERS, DATAKEY_TYPES } from 'utils/constants'
import { createDataKey } from 'utils/helpers'

const PlanningSelloutCard = ({ fetchAmplifySellout, ...props }) => {
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])
  const { currentProductType, currentSector, selectedLevel } = useContext(SectorContext)
  const [currentChannel, setChannel] = useState(CHANNEL_FILTERS[0].value)
  const dataType = useMemo(() => {
    return currentProductType === 'fmc' ? 'qty' : 'cost'
  }, [currentProductType])

  const dataKey = useMemo(
    () =>
      createDataKey(DATAKEY_TYPES.AMPLIFY.SELL_OUT.MAIN, {
        sectorType: selectedLevel,
        sectorId: currentSector[selectedLevel]?.id,
        productType: currentProductType,
        channel: currentChannel,
        dataType,
        unitOfMeasure: 'ceq'
      }),
    [currentSector, selectedLevel, currentProductType, currentChannel, dataType]
  )

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))

  return (
    <PaceSelloutL13TrendCard
      {...props}
      currentTimeDisplay="rolling"
      currentMetric="volume"
      currentChannel={currentChannel}
      setChannel={setChannel}
      currentProportion="share"
      dataKey={dataKey}
      dataType={dataType}
      selloutDataLoading={isLoading}
      currentVapeCategory="allConsumables"
      unitOfMeasure="ceq"
    />
  )
}

const mapActionCreators = {
  fetchAmplifySellout
}

PlanningSelloutCard.propTypes = {
  fetchAmplifySellout: func
}

export default connect(null, mapActionCreators)(PlanningSelloutCard)
