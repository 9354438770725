import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { bool, func, shape, string } from 'prop-types'
import styled from 'styled-components'
import tw from 'twin.macro'

import LangContext from 'context/LangContext'

import Icon from 'components/Icon'

const Tile = styled(Link)((props) => [
  tw`rounded-md p-6 flex flex-col gap-2 justify-between bg-white shadow-slate-900/5 w-full hover:cursor-pointer h-60 xs:h-80 transition-all`,
  props.comingSoon
    ? tw`opacity-60 pointer-events-none`
    : tw`shadow-md ring-1 ring-slate-900/10 active:bg-brand-50 active:shadow-sm sm:hover:bg-brand-50`
])

const ClickableTile = ({ content, comingSoon, onClick }) => {
  const { translate } = useContext(LangContext)

  const tileLinkProps = content.href
    ? { referrerPolicy: 'no-referrer-when-downgrade', target: content.target || '_self' }
    : null

  return (
    <Tile
      order={content.order}
      comingSoon={comingSoon || content.disabled}
      to={content.to || content.href}
      onClick={onClick}
      {...tileLinkProps}
    >
      <div className="flex h-full flex-col justify-between gap-3">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-5">
            {content.icon && (
              <div className="h-14 w-14">
                <img src={content.icon.source} alt={content.icon.alt} className="h-full w-full object-contain" />
              </div>
            )}
            <h2 className="flex h-14 items-center text-xl font-bold text-slate-800">{content.title}</h2>
          </div>
          {content.description && (
            <div className="text-sm text-slate-600">
              {content.description} <b>{content.descriptionEnd || ''}</b>
            </div>
          )}
        </div>
        {!comingSoon ? (
          <div className="flex items-center justify-end font-medium text-brand">
            {translate('common.view')}
            <Icon icon="right-chevron" />
          </div>
        ) : (
          <div className="flex items-center justify-end font-medium text-brand">{translate('common.comingSoon')}</div>
        )}
      </div>
    </Tile>
  )
}

ClickableTile.propTypes = {
  content: shape({
    title: string.isRequired,
    description: string.isRequired,
    descriptionEnd: string,
    to: string,
    href: string,
    target: string,
    icon: shape({
      source: string.isRequired,
      alt: string.isRequired
    }).isRequired
  }).isRequired,
  comingSoon: bool,
  onClick: func
}

export default ClickableTile
