import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router'
import { useNavigate } from 'react-router-dom'
import useIsNewTab from 'hooks/useIsNewTab'

import ExtrahubContext from 'context/ExtrahubContext'
import LangContext from 'context/LangContext'

import * as customers from 'store/customers/endpoints'

import Button from 'components/button/Button'
import Container from 'components/Container'
import GlobalAlert from 'components/GlobalAlert'
import Spinner from 'components/Spinner'

const ExtrahubLayout = () => {
  const navigate = useNavigate()
  const { isNewTab } = useIsNewTab()
  const { setLang, translate } = useContext(LangContext)

  const [contactId, setContactId] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const [token, setToken] = useState(null)

  function setExtrahubContext({ contactId, customerId, token }) {
    setContactId(contactId)
    setCustomerId(customerId)
    setToken(token)
  }

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [isBackdoor, setIsBackdoor] = useState(null)

  useEffect(() => {
    const checkCustomerAndContact = async () => {
      try {
        setError()
        setLoading(true)
        const cookie = localStorage.getItem('extrahub-auth')
        if (!cookie) {
          return navigate('/extrahub/login/callback')
        }
        const { locale, customerId, contactId, token: tokenCookie, isBackdoor: isBackdoorCookie } = JSON.parse(cookie)
        setIsBackdoor(isBackdoorCookie)
        await customers.getCustomerStoreContact(customerId, contactId)
        setExtrahubContext({ customerId, contactId, token: tokenCookie })
        setLang(locale?.substring(0, 2) || 'en')
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    checkCustomerAndContact()
  }, [])

  const getBackLink = () => {
    if (isNewTab && !isBackdoor) {
      return <Button onClick={() => window?.close()}>{translate('extrahub.goBackToExtrahub')}</Button>
    }
  }

  if (loading) {
    return (
      <Container padded>
        <div className="mt-4 flex h-full w-full items-center justify-center">
          <Spinner icon="spinner" />
        </div>
      </Container>
    )
  }

  if (error) {
    return (
      <Container padded>
        {getBackLink()}
        <GlobalAlert error icon="warning-filled">
          {translate('extrahub.invalidCustomerContact')}
        </GlobalAlert>
      </Container>
    )
  }

  return (
    <Container padded>
      {isBackdoor && (
        <GlobalAlert warning icon="info">
          {translate('extrahub.backdoor')}
        </GlobalAlert>
      )}
      {getBackLink()}
      <ExtrahubContext.Provider value={{ token, contactId, customerId }}>
        <Outlet />
      </ExtrahubContext.Provider>
    </Container>
  )
}

export default ExtrahubLayout
